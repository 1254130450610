import React from 'react'
import Header from 'components/header'
import Sidebar from 'components/sidebar'
import { Box } from '@mui/material'
import RecordsTable from 'components/Records/RecordsTable'
import { useLocation } from 'react-router-dom';

const Records = () => {
  const location = useLocation();
  const { activeTab } = location.state || { activeTab: 0 };  // Get the active tab from the state, default to 0

  return (
    <Box sx={{ display: 'flex' }}>
      <Sidebar />
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Header />
        <RecordsTable activeTab={activeTab} />  {/* Pass activeTab as prop */}
      </Box>
    </Box>
  )
}

export default Records;

import React, { useEffect, useState } from 'react';
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Box, Button } from '@mui/material';
import IssueInventoryAPIs from 'utilities/api/StudentIssueInventory';
import nodatafoundImage from '../../../utilities/dummy_assets/nodatafound.jpg';
import { useNavigate } from 'react-router-dom';

const ItemsBorrowedTable = ({user}) => {
    const [itemsBorrowedData, setItemsBorrowedData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await IssueInventoryAPIs.IssuedInventoryGet();
                const filteredData = data.filter(item => item.issued_by === user.id);
                setItemsBorrowedData(filteredData);
            } catch (error) {
                console.error('Error fetching borrowed items:', error);
            }
        };
    
        fetchData();
    }, [user]);    

    const getStatus = (item) => {
        if (item.returned_ === 'Yes') {
            return 'Returned';
        } else if (item.pickup_ === 'Yes') {
            return 'Borrowed';
        } else {
            return 'Pending';
        }
    };

    return (
        <Paper style={{
            borderRadius: '24px',
            backgroundColor: '#f9fafe',
            borderColor: '#d3d3d3',
            width: '100%',
            justifyContent: 'space-between',
            padding: '6px 4px',
            alignItems: 'center',
            borderWidth: '1px',
            borderStyle: 'solid',
            minHeight:'415px',
            maxHeight:'415px',
        }}>
            <Typography variant="h6" component="div" gutterBottom style={{ fontSize: '25px', padding: '8px', textAlign: 'center' }}>
                Items Borrowed
            </Typography>
            {itemsBorrowedData.length > 0 ? (
                <TableContainer sx={{ borderRadius: '24px' }}>
                    <Table>
                        <TableHead style={{ backgroundColor: '#aeff2d', height: '56px', alignItems: 'center' }}>
                            <TableRow>
                                <TableCell style={{ borderBottom: '1px solid #5a5a5a' }}>Name of item</TableCell>
                                <TableCell style={{ borderBottom: '1px solid #5a5a5a' }}>Status</TableCell>
                                <TableCell style={{ borderBottom: '1px solid #5a5a5a' }}>Due Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {itemsBorrowedData.map((item, index) => (
                                <TableRow key={index} style={{ borderBottom: '1px solid #5a5a5a' }}>
                                    <TableCell style={{ padding: '18px', textDecoration: 'underline' }}>{item.name}</TableCell>
                                    <TableCell style={{ padding: '18px', textDecoration: 'underline' }}>{getStatus(item)}</TableCell>
                                    <TableCell style={{ padding: '18px', textAlign: 'center' }}>{item.issued_till}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
                    <img src={nodatafoundImage} alt="No Data Found" style={{ width: '250px', marginBottom: '16px' }} />
                    <Typography variant="h6" color="textSecondary">
                        No items borrowed
                    </Typography>
                    <Button onClick={()=>{navigate('/explore')}} variant="contained" color="warning" style={{ marginTop: '16px', backgroundColor: '#e79a2b', color: '#fff', borderRadius: '8px', borderColor: '#8c5000', padding: '8px 16px' }}>Reserve Now</Button>
                </Box>
            )}
        </Paper>
    );
};

export default ItemsBorrowedTable;

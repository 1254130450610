import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Typography, Button, Avatar, AvatarGroup, Divider, Tooltip } from '@mui/material';
import ProjectAPIs from 'utilities/api/StudentProjectAPI';
import StudentGetUser from 'utilities/api/StudentAllUserAPIs';
import { useNavigate } from 'react-router-dom';
import ImageBaseURL from 'utilities/api/axios';
import nodatafoundImage from '../../../utilities/dummy_assets/nodatafound.jpg'; // Adjust the path accordingly

const statusColors = {
    1: { background: '#FFE5E5', color: '#C90505' }, // Archived
    2: { background: '#FEFFD2', color: '#D0A300' }, // In Progress
    3: { background: '#E5FFF1', color: '#057D35' }, // Completed
};

const ProjectCard = ({ title, description, type, teammates, progress_status, student }) => {
    const statusColor = statusColors[progress_status];

    return (
        <Card sx={{ width: 250, margin: 1, height: 'auto', borderRadius: '1rem', padding: '0.5rem', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', border: '1px solid #bec4ec', boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)' }}>
            <CardContent sx={{ flex: '1 0 auto', padding: '16px' }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1, backgroundColor: statusColor.background, borderRadius: '50px', width: 'fit-content', padding: '0 8px' }}>
                        <Box sx={{ height: 5, width: 5, borderRadius: '50%', backgroundColor: statusColor.color, marginRight: 0.5, marginLeft: 1 }} />
                        <Typography variant="body2" style={{ color: statusColor.color, marginLeft: 1 }}>
                            {progress_status === 1 ? 'Archived' : progress_status === 2 ? 'In Progress' : 'Completed'}
                        </Typography>
                    </Box>
                </Box>
                <Typography style={{ fontWeight: 600, fontSize: '200%', color: '#E79A2B', marginTop: '8px' }}>{title}</Typography>
                <Typography style={{ fontWeight: 500, fontSize: '110%', marginTop: '8px' }}>Description</Typography>
                <Typography variant="body2" color="textSecondary" sx={{
                    display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1, textOverflow: 'ellipsis', marginTop: '8px'
                }}>{description}</Typography>
                <Typography variant="body2" style={{ marginTop: '8px' }}>Type: {type === '1' ? 'Academic' : type === '2' ? 'Research' : type === '3' ? 'Personal' : 'Other'}</Typography>
            </CardContent>
            <Divider style={{ marginTop: '8px', borderColor: '#e9e9e9' }} />
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', padding: '8px 0' }}>
                <AvatarGroup max={15} sx={{ '& .MuiAvatar-root': { width: 24, height: 24, fontSize: 12 } }}>
                    {teammates.map((member, index) => (
                        <Tooltip key={index} title={member.name}>
                            <Avatar src={member.profilePic} alt={member.name} />
                        </Tooltip>
                    ))}
                    <Tooltip title={student.name}>
                        <Avatar src={student.profilePic} alt={student.name} sx={{ border: '2px solid rgb(231, 154, 43)' }} />
                    </Tooltip>
                </AvatarGroup>
            </Box>
        </Card>
    );
};

const YourProjects = ({ user }) => {
    const [projects, setProjects] = useState([]); // State to hold fetched projects
    const [loading, setLoading] = useState(true); // State to track loading status
    const [allUsers, setAllUsers] = useState([]); // State to hold all users data
    const navigate = useNavigate();

    useEffect(() => {
        const fetchAllUsers = async () => {
            try {
                const users = await StudentGetUser.AllUserListGet(); // Fetch all users from API
                setAllUsers(users);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        const fetchProjects = async () => {
            try {
                const response = await ProjectAPIs.ProjectsGet(); // Fetch projects from API
                const filteredProjects = response.filter(project => 
                    project.student === user.id || project.teammates.includes(user.id)
                );
        
                const projectData = await Promise.all(filteredProjects.map(async (project) => {
                    const student = allUsers.find(u => u.id === project.student) || {};
                    const teammates = await Promise.all(project.teammates.map(teammateId => {
                        const user = allUsers.find(u => u.id === teammateId) || {};
                        return {
                            name: user.username || `Member ${teammateId}`,
                            profilePic: user.profile_pic ? `${ImageBaseURL.defaults.baseURL}${user.profile_pic}` : ''
                        };
                    }));
                    return {
                        title: project.title,
                        description: project.description,
                        type: project.type,
                        progress_status: project.progress_status,
                        student: {
                            name: student.username || `Student ${project.student}`,
                            profilePic: student.profile_pic ? `${ImageBaseURL.defaults.baseURL}${student.profile_pic}` : ''
                        },
                        teammates,
                    };
                }));
        
                setProjects(projectData); // Set the projects state with fetched data
            } catch (error) {
                console.error('Error fetching projects:', error);
            } finally {
                setLoading(false); // Set loading to false after fetching
            }
        };
        
        fetchAllUsers(); // Fetch all users first
        fetchProjects(); // Call the fetch function
    }, [user]);

    if (loading) {
        return <Typography align="center">Loading projects...</Typography>; // Show loading state
    }

    return (
        <Box style={{ padding: '12px 16px', borderRadius: '24px', backgroundColor: '#f9fafe', borderColor: '#d3d3d3', borderWidth: '1px', borderStyle: 'solid' }}>
            <Typography variant="h6" align="center" style={{ fontSize: '24px', fontWeight: '700', color: '#000', textAlign: 'center', marginBottom: '0px' }}>Your Projects</Typography>
            {projects.length > 0 ? (
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
                    <Box style={{
                        display: 'flex',
                        overflowX: 'auto', // Enable horizontal scrolling
                        padding: '8px 0',
                        maxWidth: '100%', // Ensure the container doesn't exceed the parent width
                        whiteSpace: 'nowrap', // Prevent wrapping to maintain a single line
                    }}>
                        {projects.map((project, index) => (
                            <Box key={index} style={{ display: 'inline-block' }}> {/* Use inline-block to respect card width */}
                                <ProjectCard {...project} />
                            </Box>
                        ))}
                    </Box>
                    <Button onClick={() => { navigate("/projects") }} variant="contained" color="warning" style={{ display: 'block', margin: '5px auto', backgroundColor: '#e79a2b', color: '#fff', borderRadius: '8px', borderColor: '#8c5000' }}>
                        Explore Now
                    </Button>
                </Box>
            ) : (
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
                    <img src={nodatafoundImage} alt="No Data Found" style={{ width: '260px', marginBottom: '16px' }} />
                    <Typography variant="h6" color="textSecondary">
                        No projects available
                    </Typography>
                    <Button onClick={() => { navigate("/projects") }} variant="contained" color="warning" style={{ display: 'block', margin: '16px auto', padding: '8px 24px', backgroundColor: '#e79a2b', color: '#fff', borderRadius: '8px', borderColor: '#8c5000' }}>
                        Explore Now
                    </Button>
                </Box>
            )}
        </Box>
    );
};

export default YourProjects;

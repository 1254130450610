import React, { useEffect, useState } from 'react';
import { Card, CardContent, Box, Typography, Button, Menu, MenuItem, Avatar, AvatarGroup, Divider, Tooltip } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import VisibilityIcon from '@mui/icons-material/Visibility';
import StudentGetUser from 'utilities/api/StudentAllUserAPIs';
import ImageBaseURL from 'utilities/api/axios';

const statusColors = {
  1: { background: '#FFE5E5', color: '#C90505' }, // Archived
  2: { background: '#FEFFD2', color: '#D0A300' }, // In Progress
  3: { background: '#E5FFF1', color: '#057D35' }, // Completed
};

const ProjectCard = ({ project, onEdit, onView, user, showVisibility, allUsers }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [teammatesData, setTeammatesData] = useState([]);
  const open = Boolean(anchorEl);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const status = project.progress_status;
  const statusColor = statusColors[status];

  const getProfilePicUrl = (profilePicPath) => {
    return profilePicPath ? `${ImageBaseURL.defaults.baseURL}${profilePicPath}` : '';
  };

  // Fetch user data for each teammate by username
  useEffect(() => {
    const fetchTeammatesData = async () => {
      const fetchedData = await Promise.all(
        project.teammates_names.map(async (username) => {
          try {
            const userData = await StudentGetUser.UserDetailsGet(username);
            return userData[0]; // Assuming the API returns an array and we take the first item
          } catch (error) {
            console.error(`Error fetching data for ${username}:`, error);
            return null;
          }
        })
      );
      setTeammatesData(fetchedData.filter(data => data !== null)); // Filter out any failed fetches
    };

    fetchTeammatesData();
  }, [project.teammates_names]);

  // Check if the current user is one of the teammates
  const isTeammate = project.teammates.includes(user.id);

  return (
    <Card sx={{ width: 250, margin: 1, height: 'auto', borderRadius: '1rem', padding: '0.5rem', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
      <CardContent sx={{ flex: '1 0 auto' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1, backgroundColor: statusColor.background, borderRadius: '50px', width: 'fit-content', padding: '0 8px' }}>
            <Box sx={{ height: 5, width: 5, borderRadius: '50%', backgroundColor: statusColor.color, marginRight: 0.5, marginLeft: 1 }} />
            <Typography variant="body2" style={{ color: statusColor.color, marginLeft: 1 }}>
              {status === 1 ? 'Archived' : status === 2 ? 'In Progress' : 'Completed'}
            </Typography>
          </Box>
          {(showVisibility) ? (
            <Button
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={() => onView(project)}
            >
              <VisibilityIcon style={{ color: 'grey' }} />
            </Button>
          ) : (
            (user.id === project.student || isTeammate) && (
              <Button
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleMenuOpen}
              >
                <MoreHorizIcon style={{ color: 'grey' }} />
              </Button>
            )
          )}
          <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose} MenuListProps={{ 'aria-labelledby': 'basic-button' }}>
            <MenuItem onClick={() => { handleMenuClose(); onEdit(project); }}>Edit Project</MenuItem>
          </Menu>
        </Box>
        <Typography style={{ fontWeight: 600, fontSize: '200%', color: '#E79A2B' }}>{project.title}</Typography>
        <Typography style={{ fontWeight: 500, fontSize: '110%' }}>Description</Typography>
        <Typography variant="body2" color="textSecondary" sx={{
          display: '-webkit-box', overflow: 'hidden', WebkitBoxOrient: 'vertical', WebkitLineClamp: 1, textOverflow: 'ellipsis'
        }}>{project.description}</Typography>
        <Typography variant="body2">Type: {project.type === '1' ? 'Academic' : project.type === '2' ? 'Research' : project.type === '3' ? 'Personal' : 'Other'}</Typography>
      </CardContent>
      <Box sx={{ flexGrow: 1 }} />
      <Divider />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', padding: '8px 16px' }}>
        <Typography variant="body2" style={{ color: '#5C5C5C' }}>{project.date}</Typography>
        <AvatarGroup max={15} sx={{ '& .MuiAvatar-root': { width: 24, height: 24, fontSize: 12 } }}>
          {teammatesData.map((member, index) => (
            <Tooltip key={index} title={member?.username || ''}>
              <Avatar src={getProfilePicUrl(member?.profile_pic)} alt={member?.name} />
            </Tooltip>
          ))}
          <Tooltip title={user.username}>
            <Avatar src={getProfilePicUrl(user.profile_pic)} alt={user.username} style={{ border: '2px solid rgb(231, 154, 43)' }} />
          </Tooltip>
        </AvatarGroup>
      </Box>
    </Card>
  );
};

export default ProjectCard;

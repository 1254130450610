import { axiosApiService } from "./axios";

const StudentGetUser = {
    UserDetailsGet: async function (query) {
        const response = await axiosApiService.get(`/training/search_user/?q=${query}`);
        console.log((response));
        return response.data
    },
    AllUserListGet: async function(){
        const response = await axiosApiService.get(`/auth/users/`);
        return response.data;
      },
}
export default StudentGetUser;
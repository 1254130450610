import React, { useState } from 'react';
import { Box } from '@mui/material';
import BookMachineDialog from '../popups/BookMachine';
import CardListViewComponent from './machine/CardListViewComponent';
import CardComponent from './machine/CardComponent';
import EquipmentCardView from './equipments/CardComponent';
import EquipmentTableView from './equipments/EquipmentsTableView';
import InventoryTableView from './Inventory/InventoryTableView';

const Content = ({ data, activeCategory, activeView, searchQuery, setSearch}) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedMachineId, setSelectedMachineId] = useState(null);

    const handleOpenDialog = (id) => {
        setSelectedMachineId(id);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleSearchQueryChange = (query) => {
        setSearch(query);
    };

    const renderTable = () => {
        switch (activeCategory) {
            case 0:
            case 1:
                return <EquipmentTableView data={data} setOpenDialog={setOpenDialog} searchQuery={searchQuery} />;
            case 2:
                return <InventoryTableView data={data} setOpenDialog={setOpenDialog} searchQuery={searchQuery} />;
            default:
                return null;
        }
    };

    const renderComponent = (item) => {
        switch (activeCategory) {
            case 0:
                return !activeView ? (
                    <CardComponent key={item.id} item={item} setOpenDialog={() => handleOpenDialog(item.id)} searchQuery={searchQuery} />
                ) : (
                    <CardListViewComponent key={item.id} item={item} setOpenDialog={() => handleOpenDialog(item.id)} searchQuery={searchQuery} />
                );
            case 1:
                return !activeView ? (
                    <EquipmentCardView key={item.id} item={item} setOpenDialog={() => handleOpenDialog(item.id)} searchQuery={searchQuery} />
                ) : (
                    <EquipmentTableView key={item.id} data={item} setOpenDialog={() => handleOpenDialog(item.id)} searchQuery={searchQuery} />
                );
            default:
                return null;
        }
    };

    const isCardLayout = (activeCategory === 0 || (activeCategory === 1 && !activeView));
    const gridTemplateColumns = isCardLayout 
        ? (activeCategory === 1 ? 'repeat(4, 24.5%)' : (activeView ? 'repeat(1, 1fr)' : 'repeat(3, 35.5%)')) 
        : 'none';

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <Box
                sx={{
                    display: isCardLayout ? 'grid' : 'block',
                    gridTemplateColumns: isCardLayout ? gridTemplateColumns : 'none',
                    gap: isCardLayout ? '20px' : 'none',
                }}
            >
                {isCardLayout
                    ? data.map((item, index) => renderComponent(item, index))
                    : renderTable()}
            </Box>
            <BookMachineDialog open={openDialog} onClose={handleCloseDialog} machineId={selectedMachineId}/>
        </Box>
    );
};

export default Content;











// import React, { useState } from 'react';
// import { Box, TextField } from '@mui/material';
// import BookMachineDialog from '../popups/BookMachine';
// import CardListViewComponent from './machine/CardListViewComponent';
// import CardComponent from './machine/CardComponent';
// import EquipmentCardView from './equipments/CardComponent';
// import EquipmentTableView from './equipments/EquipmentsTableView';
// import InventoryTableView from './inventory/InventoryTableView';

// const Content = ({ data, activeCategory, activeView , searchQuery, setSearch}) => {
//     // const [searchQuery, setSearchQuery] = useState('');
//     const [openDialog, setOpenDialog] = useState(false);
//     const [selectedMachineId, setSelectedMachineId] = useState(null);

//     const handleOpenDialog = (id) => {
//         setSelectedMachineId(id);
//         setOpenDialog(true);
//     };

//     const handleCloseDialog = () => {
//         setOpenDialog(false);
//     };

//     // const handleSearchQueryChange = (query) => {
//     //     setSearchQuery(query);
//     // };

//     // const handleSearchQueryChange = (query) => {
//     //   setSearch(query);
//     // };

//     const renderTable = () => {
//           switch (activeCategory) {
//             case 0:  
//             case 1:
//               return <EquipmentTableView data={data} setOpenDialog={setOpenDialog} searchQuery={searchQuery} />;
//             case 2:
//               return <InventoryTableView data={data} setOpenDialog={setOpenDialog} searchQuery={searchQuery}/>;
//             default:
//               return null;
//           }
//        };

//     const renderComponent = (item) => {
//       const margin = activeView ? '10px 5px' : '10px 0px 0px 10px';
//       const width = activeView ? '100%' : '19%';
//         // Determine the appropriate component based on activeCategory and activeView
//         switch (activeCategory) {
//             case 0:
//                 return !activeView ? (
//                     <CardComponent key={item.id} item={item} setOpenDialog={() => handleOpenDialog(item.id)}  searchQuery={searchQuery}/>
//                 ) : (
//                     <CardListViewComponent key={item.id} item={item} setOpenDialog={() => handleOpenDialog(item.id)} searchQuery={searchQuery} />
//                 );
//             case 1:
//                 return !activeView ? (
//                     <EquipmentCardView key={item.id} item={item} setOpenDialog={() => handleOpenDialog(item.id)}  searchQuery={searchQuery}/>
//                 ) : (
//                     <EquipmentTableView key={item.id} data={item} setOpenDialog={() => handleOpenDialog(item.id)} searchQuery={searchQuery} />
//                 );
//             default:
//                 return null;
//         }
//     };

//     return (
//       <Box sx={{ display: 'flex', flexDirection: 'column' }}>
//           <Box sx={{ marginBottom: '1rem', width: '100%' }}> {/* Wrapping the search bar in its own Box */}
//               {/* <TextField
//                   label="Search"
//                   variant="outlined"
//                   value={searchQuery}
//                   onChange={(e) => handleSearchQueryChange(e.target.value)}
//                   sx={{ width: '20%', margin:'10px' }}
//               /> */}
//           </Box>
//           <Box sx={{ display: 'flex', flexWrap: 'wrap', width: '100%' }}> {/* Wrapping other content in another Box */}
//             {activeCategory === 0 || (!activeView && activeCategory === 1) ? (
//               data.map((item, index) => renderComponent(item, index))  
//               ) : (
//                 renderTable()  
//               )}
//           </Box>
//           <BookMachineDialog open={openDialog} onClose={handleCloseDialog} machineId={selectedMachineId} />
//         </Box>
//   );
// };

// export default Content;











// import React, { useState } from 'react';
// import { Box } from '@mui/material';
// import BookMachineDialog from '../popups/BookMachine';
// import CardListViewComponent from './machine/CardListViewComponent';
// import CardComponent from './machine/CardComponent';
// import EquipmentCardView from './equipments/CardComponent';
// import EquipmentTableView from './equipments/EquipmentsTableView';
// import InventoryTableView from './inventory/InventoryTableView';

// const Content = ({ data, activeCategory, activeView }) => {
//  console.log("Received data in Content component:", data);  

//  const [openDialog, setOpenDialog] = useState(false);
//  const [selectedMachineId, setSelectedMachineId] = useState(null);

//  const handleOpenDialog = (id) => {
//     setSelectedMachineId(id);  
//     setOpenDialog(true);
//  };

//  const handleCloseDialog = () => {
//     setOpenDialog(false);  
//  };

//  const renderTable = () => {
//     switch (activeCategory) {
//       case 0:  
//       case 1:
//         return <EquipmentTableView data={data} setOpenDialog={setOpenDialog} />;
//       case 2:
//         return <InventoryTableView data={data} setOpenDialog={setOpenDialog} />;
//       default:
//         return null;
//     }
//  };

// //  const renderComponent = (item, index) => {
// //     switch (activeCategory) {
// //       case 0:  
// //         console.log("Case 0")
// //         return !activeView ? (
// //           <Box key={item.id} sx={{ width: '25%', margin: '10px 80px 10px 30px' }}>  
// //             <CardComponent item={item} setOpenDialog={() => handleOpenDialog(item.id)} />
// //           </Box>
// //         ) : (
// //           <Box key={item.id} sx={{ padding: '10px 5px', width: '100%' }}>  
// //             <CardListViewComponent item={item} setOpenDialog={() => handleOpenDialog(item.id)} />
// //           </Box>
// //         );
// //       case 1:
// //         console.log("Case 1")
// //         return !activeView ? (
// //           <Box key={item.id} sx={{ width: '25%', margin: '10px 80px 10px 30px' }}>  
// //             <EquipmentCardView item={item} setOpenDialog={() => handleOpenDialog(item.id)} />
// //           </Box>
// //         ) : (
// //           <Box key={item.id} sx={{ padding: '10px 5px', width: '100%' }}>  
// //             <EquipmentTableView item={item} setOpenDialog={() => handleOpenDialog(item.id)} />
// //           </Box>
// //         );
// //       default:
// //         return null;
// //     }
// //  };

// const renderComponent = (item) => {
//   const margin = activeView ? '10px 5px' : '10px 0px 0px 10px';  
//   const width = activeView ? '100%' : '19%';

//   switch (activeCategory) {
//     case 0:  
//       return !activeView ? (
//         <Box key={item.id} sx={{ width, margin }}>  
//           <CardComponent item={item} setOpenDialog={() => handleOpenDialog(item.id)} />
//         </Box>
//       ) : (
//         <Box key={item.id} sx={{ padding: '10px 5px', width }}>  
//           <CardListViewComponent item={item} setOpenDialog={() => handleOpenDialog(item.id)} />
//         </Box>
//       );
//     case 1:
//       return !activeView ? (
//         <Box key={item.id} sx={{ width, margin }}>  
//           <EquipmentCardView item={item} setOpenDialog={() => handleOpenDialog(item.id)} />
//         </Box>
//       ) : (
//         <Box key={item.id} sx={{ padding: '10px 5px', width }}>  
//           <EquipmentTableView item={item} setOpenDialog={() => handleOpenDialog(item.id)} />
//         </Box>
//       );
//     default:
//       return null;
//   }
// };


//  return (
//     <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>  
//       {activeCategory === 0 || (!activeView && activeCategory === 1) ? (
//         data.map((item, index) => renderComponent(item, index))  
//       ) : (
//         renderTable()  
//       )}

//       <BookMachineDialog open={openDialog} onClose={handleCloseDialog} machineId={selectedMachineId} />
//     </Box>
//  );
// };

// export default Content;






// import React, { useState } from 'react';
// import { Box } from '@mui/material';
// import BookMachineDialog from '../popups/BookMachine';
// import CardListViewComponent from './machine/CardListViewComponent';
// import CardComponent from './machine/CardComponent';
// import EquipmentCardView from './equipments/CardComponent';
// import EquipmentTableView from './equipments/EquipmentsTableView';
// import InventoryTableView from './inventory/InventoryTableView';
// import InventoryDialog from 'components/popups/InventoryDialog';

// const Content = ({ data, activeCategory, activeView }) => {
//   console.log("Received data in Content component:", data);  

//   const [openDialog, setOpenDialog] = useState(false);
//   const [selectedMachineId, setSelectedMachineId] = useState(null);

//   const handleOpenDialog = (id) => {
//     setSelectedMachineId(id);  
//     setOpenDialog(true);
//   };

//   // const handleCloseDialog = () => {
//   //   setOpenDialog(false);  
//   // };

//   const handleCloseDialog = () => {
//     setOpenDialog(false);  
//     setSelectedMachineId(null); // Reset selectedMachineId when closing the dialog
//   };
  

//   const renderTable = () => {
//     switch (activeCategory) {
//       case 0:  
//       case 1:
//         return <EquipmentTableView data={data} setOpenDialog={setOpenDialog} />;
//       case 2:
//         return <InventoryTableView data={data} setOpenDialog={setOpenDialog} />;
//       default:
//         return null;
//     }
//   };

//   const renderComponent = (item) => {
//     const margin = activeView ? '10px 5px' : '10px 0px 0px 10px';  
//     const width = activeView ? '100%' : '19%';

//     switch (activeCategory) {
//       case 0:  
//         return !activeView ? (
//           <Box key={item.id} sx={{ width, margin }}>  
//             <CardComponent item={item} setOpenDialog={() => handleOpenDialog(item.id)} />
//           </Box>
//         ) : (
//           <Box key={item.id} sx={{ padding: '10px 5px', width }}>  
//             <CardListViewComponent item={item} setOpenDialog={() => handleOpenDialog(item.id)} />
//           </Box>
//         );
//       case 1:
//         return !activeView ? (
//           <Box key={item.id} sx={{ width, margin }}>  
//             <EquipmentCardView item={item} setOpenDialog={() => handleOpenDialog(item.id)} />
//           </Box>
//         ) : (
//           <Box key={item.id} sx={{ padding: '10px 5px', width }}>  
//             <EquipmentTableView item={item} setOpenDialog={() => handleOpenDialog(item.id)} />
//           </Box>
//         );
//       default:
//         return null;
//     }
//   };

//   return (
//     <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>  
//       {activeCategory === 2 ? (
//         renderTable()  
//       ) : (
//         data.map((item) => renderComponent(item))  
//       )}
//       {activeCategory === 0 && (
//         <BookMachineDialog open={openDialog} onClose={handleCloseDialog} machineId={selectedMachineId}/>  
//       )}
//       {activeCategory === 2 && (
//         <InventoryDialog open={openDialog} onClose={handleCloseDialog} machineId={selectedMachineId} allowedTabs={['Issue', 'Purchased']} />  
//       )}
//     </Box>
//   );
// };

// export default Content;






// // NEW CODE FOR CASES PART TO RESOLVE MISSING UNIQUE KEY ERROR

// import React, { useState } from 'react';
// import { Box } from '@mui/material';
// import BookMachineDialog from '../popups/BookMachine';
// import CardListViewComponent from './machine/CardListViewComponent';
// import CardComponent from './machine/CardComponent';
// import EquipmentCardView from './equipments/CardComponent';
// import EquipmentTableView from './equipments/EquipmentsTableView';
// import InventoryTableView from './inventory/InventoryTableView';

// const Content = ({ data, activeCategory, activeView }) => {

//   console.log("Received data in Content component:", data);
//   // console.log("Active category:", activeCategory);
//   // console.log("Active view:", activeView);
  


//   const [openDialog, setOpenDialog] = useState(false);

//   const handleOpenDialog = () => {
//     setOpenDialog(true);
//   };

//   const handleCloseDialog = () => {
//     setOpenDialog(false);
//   };

//   const renderTable = () => {
//     switch (activeCategory) {

//       case 0: // Added Later
//         return <EquipmentTableView data={data} setOpenDialog={setOpenDialog} />;
        
//       case 1:
//         return <EquipmentTableView data={data} setOpenDialog={setOpenDialog} />;
//       case 2:
//         return <InventoryTableView data={data} setOpenDialog={setOpenDialog} />;
//       default:
//         return null;
//     }
//   };

// // Proper USE of key value
//   const renderComponent = (item, index) => {
//     switch (activeCategory) {
//        case 0:
//          console.log("Case 0")
//          return !activeView ? (
//            <Box key={item.id} sx={{ width: '25%', margin: '10px 80px 10px 30px' }}>
//              <CardComponent item={item} setOpenDialog={setOpenDialog} />
//            </Box>
//          ) : (
//            <Box key={item.id} sx={{ padding: '10px 5px', width: '100%' }}>
//              <CardListViewComponent item={item} setOpenDialog={setOpenDialog} />
//            </Box>
//          );
//        case 1:
//          console.log("Case 1")
//          return !activeView ? (
//            <Box key={item.id} sx={{ width: '25%', margin: '10px 80px 10px 30px' }}>
//              <EquipmentCardView item={item} setOpenDialog={setOpenDialog} />
//            </Box>
//          ) : (
//            <Box key={item.id} sx={{ padding: '10px 5px', width: '100%' }}>
//              <EquipmentTableView item={item} setOpenDialog={setOpenDialog} />
//            </Box>
//          );
//        default:
//          return null;
//     }
//    };

//   // const renderComponent = (item, index) => {
//   //   // console.log("item:", item);
//   //   // console.log("index:", index);

//   //   switch (activeCategory) {
//   //     case 0:
//   //       console.log("Case 0")
//   //       return !activeView ? (
//   //         <Box key={index} sx={{ width: '25%', margin: '10px 80px 10px 30px' }}>
//   //           <CardComponent item={item} setOpenDialog={setOpenDialog} />
//   //         </Box>
//   //       ) : (
//   //         <Box key={index} sx={{ padding: '10px 5px', width: '100%' }}>
//   //           <CardListViewComponent item={item} setOpenDialog={setOpenDialog} />
//   //         </Box>
//   //       );
//   //     case 1:
//   //       console.log("Case 1")
//   //       return !activeView ? (
//   //         <Box key={index} sx={{ width: '25%', margin: '10px 80px 10px 30px' }}>
//   //           <EquipmentCardView item={item} setOpenDialog={setOpenDialog} />
//   //         </Box>
//   //       ) : (
//   //         <Box key={index} sx={{ padding: '10px 5px', width: '100%' }}>
//   //           <EquipmentTableView item={item} setOpenDialog={setOpenDialog} />
//   //         </Box>
//   //       );
//   //     default:
//   //       return null;
//   //   }
//   // };

//   return (
//     <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
//       {activeCategory === 0 ? (
//         data.map((item, index) => renderComponent(item, index))
//       ) : !activeView && activeCategory === 1 ? (
//         data.map((item, index) => renderComponent(item, index))
//       ) : (
//         renderTable()
//       )}

//       <BookMachineDialog open={openDialog} onClose={handleCloseDialog} />
//     </Box>
//   );
// };

// export default Content;







// import React, { useState } from 'react';
// import { Button, Typography, IconButton, Dialog, DialogActions, DialogContent, Box } from '@mui/material';

// import BookMachineDialog from '../popups/BookMachine'
// import CardListViewComponent from './machine/CardListViewComponent';
// import CardComponent from './machine/CardComponent';
// import EquipmentCardView from './equipments/CardComponent';
// import EquipmentTableView from './equipments/EquipmentsTableView'
// import InventoryTableView from './inventory/InventoryTableView';
// const getStatusClass = (status) => {
//   switch (status) {
//     case "Issuable":
//       return "status-issuable";
//     case "Purchasable":
//       return "status-purchasable";
//     default:
//       return "status-non-issuable";
//   }
// };

// const Content = ({ data, activeCategory, activeView }) => {
//   const [openDialog, setOpenDialog] = useState(false);

//   const handleOpenDialog = () => {
//     setOpenDialog(true);
//   };

//   const handleCloseDialog = () => {
//     setOpenDialog(false);
//   };
//   //console.log('28', activeCategory)
//   const renderTable = () => {
//     switch (activeCategory){
//       case 1:
//         return (
//           <EquipmentTableView data={data} setOpenDialog={setOpenDialog}/>
//         )
//       case 2:
//         return(
//           <InventoryTableView data={data} setOpenDialog={setOpenDialog}/>
//         )
//     }
    
//   }
//   const renderComponent = (item) => {
//     switch (activeCategory) {
//       case 0:
//         return (
//           !activeView ? (
//               <Box sx={{width:'25%', margin:'10px 80px 10px 30px'}}>
//                 <CardComponent item={item} setOpenDialog={setOpenDialog}/>
//               </Box>
//               ) : (
//                 <Box sx={{padding:'10px 5px', width:'100%'}}>
//                   <CardListViewComponent item={item} setOpenDialog={setOpenDialog}/>
//                 </Box>
//               )
//       );
//       case 1:
//         return (
//           !activeView ? (
//               <Box sx={{width:'25%', margin:'10px 80px 10px 30px'}}>
//                 <EquipmentCardView item={item} setOpenDialog={setOpenDialog}/>
//               </Box>
//               ) : (
//                 <Box sx={{padding:'10px 5px', width:'100%'}}>
//                   <EquipmentTableView item={item} setOpenDialog={setOpenDialog}/>
//                 </Box>
//               )
//       );
//       case 2:
//         return (
//           <InventoryTableView data={data} setOpenDialog={setOpenDialog}/>
//       );
      
//       default:
//         return null;
//     }
//   };
//   return (
//     <Box sx={{display:'flex', flexWrap:'wrap'}}>
//       {activeCategory===0?(data.map((item) => renderComponent(item))):(
//         !activeView && activeCategory===1 ? (data.map((item) => renderComponent(item))):(renderTable())
//       )}
      
//       <BookMachineDialog open={openDialog} onClose={handleCloseDialog} />
//     </Box>
//   );
// };

// export default Content;

import { axiosApiService, axiosAuthService } from "./axios";
const Auth_API = {

    studentRegister: async function  (values) {
                const response = await axiosAuthService.post(`student_register/`, values);
                return response;
        },

    studentLogin: async function (values) {
            
            const response = await axiosAuthService.post(`student_login/`, values);
            console.log("R:",response);
            return response;
    }
};

export default Auth_API;


// import { axiosApiService, axiosAuthService } from "./axios";
// const Auth_API = {

//     studentRegister: async function  ({ name, email, branch, current_year, hostelAddress, graduationYear, degree, phoneNumber, tlName}) {
//             const formData = new FormData();
//                 formData.append("name", name);
//                 formData.append("email", email);
//                 formData.append("phone_number", phoneNumber);
//                 formData.append("department", branch);
//                 formData.append("degree", degree);
//                 formData.append("current_year", current_year);
//                 formData.append("graduation_year", graduationYear);
//                 formData.append("hostel_address", hostelAddress);
//                 formData.append("tlName", tlName);
    
//                 // NOTE: SHOULD THE POST NEEDS TO BE CHANGED???
//                 const response = await axiosAuthService.post(`student_register/`, formData, {
//                     headers: { "Content-Type": "application/x-www-form-urlencoded" },
//                 });
//                 return response;
//         },

//     studentLogin: async function ({ email, password }) {
//             const formData = new FormData();
//             formData.append("email", email);
//             formData.append("password", password);

//             console.log("E:",email);
//             console.log("P:",password);


//             const response = await axiosAuthService.post(`login/`, formData, {
//                 // headers: { "Content-Type": "application/x-www-form-urlencoded" },
//             });

//             console.log("R:",response);


//             return response;
//     }
// };

// export default Auth_API;



// const Auth_APIs = {
    
//         // studentRegister: async function  ({ name, email, phoneNumber, position, other_position}) {
//         //     const formData = new FormData();
//         //         formData.append("name", name);
//         //         formData.append("email", email);
//         //         formData.append("phoneNumber", phoneNumber);
//         //         formData.append("position", position);
//         //         formData.append("otherPosition", other_position);
    
    
//         //         // NOTE: SHOULD THE POST NEEDS TO BE CHANGED???
//         //         const response = await axiosApiService.post(`/auth/register/`, formData, {
//         //             headers: { "Content-Type": "application/x-www-form-urlencoded" },
//         //         });
//         //         return response;
//         // },

//         studentLogin: async function ({email, password}) {
//             const formData = new FormData();
//             formData.append("email", email);
//             formData.append("password", password);
//             console.log('198', formData);
//             // try{
//                 const response = await axiosApiService.post(`/auth/login/`, formData, {
//                     headers: { "Content-Type": "application/x-www-form-urlencoded" },
//                 });
//                 //dispatch({ type: actionTypes.USER_LOGIN_SUCCESS, payload: response.data });
//                 return response.data
//             //}
//             // catch(error){
//             //     // dispatch({ type: actionTypes.USER_LOGIN_FAIL, payload: error });
//             //     //console.log('error', error);
//             //     return{error}
//             // }
//         },







//     // studentLogin: async function ({email, password}) {
//     //     const formData = new FormData();
//     //     formData.append("email", email);
//     //     formData.append("password", password);
        
//     //     console.log('198', formData);
        
//     //     try{
//     //         const response = await axiosApiService.post(`/auth/login/`, formData, {
//     //             headers: { "Content-Type": "application/x-www-form-urlencoded" },
//     //         });
//     //         return response;
//     //     }catch(error){
//     //         console.error("Error fetching data", error);
//     //         return {};
//     //     }
//     // },









//     // adminLogin: async function ({email, password}) {
//     //     const formData = new FormData();
//     //     formData.append("email", email);
//     //     formData.append("password", password);
//     //     //console.log('198', formData);
//     //     // try{
//     //         const response = await axiosApiService.post(`/auth/login/`, formData, {
//     //             headers: { "Content-Type": "application/x-www-form-urlencoded" },
//     //         });
//     //         //dispatch({ type: actionTypes.USER_LOGIN_SUCCESS, payload: response.data });
//     //         return response.data
//         //}
//         // catch(error){
//         //     // dispatch({ type: actionTypes.USER_LOGIN_FAIL, payload: error });
//         //     //console.log('error', error);
//         //     return{error}
//         // }
//     // },

    

// }
// export default Auth_APIs;
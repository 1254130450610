import { axiosApiService } from "./axios";

const BookMachineAPIs = {
  BookMachineSend: async function(accessToken,values) {
    if (!accessToken) {
            throw new Error('Access token is missing.');
    }
    try {
      console.log(values);
      const response = await axiosApiService.post(`/machine_reservation_list/`, values);
      // console.log(response);
      return response.data; 
    } catch (error) {
      console.error('Error booking machine:', error);
      throw error;
    }
  },

  ReservedMachineGet: async function() {
    try {
      const response = await axiosApiService.get(`/machine_reservation_list/`);
      // console.log(response);
      return response.data; 
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  },
 
  ReservedMachineUpdate: async function(id, updateData) {
    try {
      const response = await axiosApiService.patch(`/machine_reservation_detail/${id}/`, updateData);
      // console.log(response);
      return response.data
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  },

  ReservedMachineDelete: async function(id) {
    try {
      const response = await axiosApiService.delete(`/machine_reservation_detail/${id}/`);
      return response.data; 
    } catch (error) {
      throw error;
    }
  }
};

export default BookMachineAPIs;

 

// import { axiosApiService } from "./axios";

// const BookMachineAPIs = {
//   BookMachineSend: async function(accessToken, values) {
//     if (!accessToken) {
//       throw new Error('Access token is missing.');  
//     }

//     try {
//       console.log(values);  
//       const response = await axiosApiService.get(`/machine_reservation_list/`, {
//         // headers: {
//         //   Authorization: `Bearer ${accessToken}`  
//         // }
//       });
//       console.log(response);
//       return response.data; // Assuming the server returns the data
//     } catch (error) {
//       console.error('Error fetching machine data:', error);  
//       throw error;
//     }
//   },
// };

// export default BookMachineAPIs;


// import { axiosApiService } from "./axios";

// const BookMachineAPIs = {
//     BookMachineSend: async function() {
//     try {
//       const response = await axiosApiService.post(`/admin_reservation_list/`);
//       const filteredData = response.data.map(item => ({
//         id: item.id,
        
//         reserved_from: item.reserved_from,
//         reserved_till: item.reserved_till,
//         machine: item.machine,
//         reserved_by: item.reserved_by
            

//       }));
//       return filteredData;
//       // return response.data;
//     } catch (error) {
//       console.error('Error fetching Equipment list:', error);
//       throw error;
//     }
//   },
// };

// export default BookMachineAPIs;



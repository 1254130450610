import React, { useState, useEffect } from 'react';
import { Paper, TableContainer } from '@mui/material';
import TableListHeader from './TableListHeader';
import TableListItem from './TableListItem';
import CustomPagination from '../../Pagination';

const EquipmentTableView = ({key, data, setOpenDialog, searchQuery }) => {

  console.log('EquipmentTableView-Data',data);

  console.log('EquipmentTableView-searchQuery',searchQuery);

  const [currentPage, setCurrentPage] = useState(1);
  // const productsPerPage = 6;
  const [productsPerPage, setProductsPerPage] = useState(10); // Default to 6 per page


  // Filter data based on searchQuery
  const filteredData = data.filter((product) =>
    product.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Calculate total number of pages based on filtered data
  // const totalItems = filteredData.length;
  const totalItems = data.length;
  const totalPages = Math.ceil(totalItems / productsPerPage);

  // Get current products
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  // const currentProducts = filteredData.slice(indexOfFirstProduct, indexOfLastProduct);
  const currentProducts = data.slice(indexOfFirstProduct, indexOfLastProduct);


  // Change page
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Reset to first page if searchQuery changes
  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery]);

  return (
    <TableContainer
      component={Paper}
      elevation={0}
      sx={{
        border: '0.5px solid rgba(181, 181, 181, 1)',
        background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #B5B5B5, #B5B5B5)',
        boxShadow: 'none',
        ml: '1rem',
        width: '95%',
        borderRadius: '8px',
        mr: '1rem'
      }}
    >
      <TableListHeader />
      {currentProducts.map((product) => (
        <TableListItem
          key={product.id}
          product={product}
          setOpenDialog={() => setOpenDialog(product.id)}
          searchQuery={searchQuery}
        />
      ))}
      {/* <CustomPagination count={totalPages} page={currentPage} onChange={handlePageChange} /> */}
      <CustomPagination count={totalPages} page={currentPage} onChange={handlePageChange} productsPerPage={productsPerPage} setProductsPerPage={setProductsPerPage} />
    </TableContainer>
  );
};

export default EquipmentTableView;



// import React, { useState } from 'react';
// import { Paper, TableContainer } from '@mui/material';
// import TableListHeader from './TableListHeader';
// import TableListItem from './TableListItem';
// // import MachineData from '../../../utilities/dummy_data/machine_list';
// import CustomPagination from '../../Pagination';

// const EquipmentTableView = ({data, setOpenDialog, searchQuery }) => {

//   console.log('EquipmentTableView-Data',data);

//   console.log('EquipmentTableView-searchQuery',searchQuery);

 
//   const [currentPage, setCurrentPage] = useState(1);
//   const productsPerPage = 6;

//   // Calculate total number of pages
//   const totalItems = data.length;
//   const totalPages = Math.ceil(totalItems / productsPerPage);

//   // Get current products
//   const indexOfLastProduct = currentPage * productsPerPage;
//   const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
//   const currentProducts = data.slice(indexOfFirstProduct, indexOfLastProduct);

//   // Change page
//   const handlePageChange = (event, value) => {
//     setCurrentPage(value);
//   };

//   return (
//     <TableContainer
//       component={Paper}
//       elevation={0}
//       sx={{
//         border: '0.5px solid rgba(181, 181, 181, 1)',
//         background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #B5B5B5, #B5B5B5)',
//         boxShadow: 'none',
//         ml: '1rem',
//         width: '95%',
//         borderRadius: '8px',
//         mr: '1rem'
//       }}
//     >
//       <TableListHeader/>
//       {currentProducts.map((product) => (
//         <TableListItem key={product.id} product={product} setOpenDialog={() => setOpenDialog(product.id)} searchQuery={searchQuery}/>
//       ))}
//       <CustomPagination count={totalPages} page={currentPage} onChange={handlePageChange} />
//     </TableContainer>
//   );
// };

// export default EquipmentTableView;









// import React, { useState } from "react";
// import { Box, Typography } from "@mui/material";
// import IconButton from '@mui/material/IconButton';
// import { DataGrid } from "@mui/x-data-grid";

// const columns = [
//     { 
//       field: 'name',   
//       headerName: 'Item Details', 
//       width: 275,
//       renderCell: (params) => (
//         <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>  
//           <img src='./machine.png' alt='' style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
//           <Box>
//             <Typography variant="subtitle2">{params.row.name}</Typography>
//           </Box>
//         </Box>
//       ),
//     },
//     { field: 'status', headerName: 'Status', width: 275 },
//     { field: 'date', headerName: 'Availability', width: 275 },

//     {
//       field: 'action',  
//       headerName: 'Action',
//       sortable: false,
//       renderCell: (params) => {
//         return (
//           <React.Fragment>  
//             <IconButton aria-label="move up">
//               {/* <ArrowUpwardIcon /> */}
//             </IconButton>
//           </React.Fragment>
//         );
//       },
//       width: 150,
//     },
// ];

// const EquipmentTableView = ({data}) => {
//     const [page, setPage] = useState(0);  

//     // Modify data structure to include an 'id' property
//     const rows = data.map((item, index) => ({ ...item, id: index }));

//     const handlePageChange = (newPage) => {
//         setPage(newPage);  
//     };
//     return(
//         <Box style={{ height: 400, width: "100%" }}>  
//             <DataGrid
//                 rows={rows}
//                 columns={columns}
//                 pageSize={7}
//                 page={page}
//                 onPageChange={handlePageChange}
//                 rowsPerPageOptions={[7]}
//                 pagination
//                 checkboxSelection  // <-- Adding checkbox selection
//             />
//         </Box>
//     )
    
//   }
//   export default EquipmentTableView;

  
  
  
  
  
  


// import React from "react"
// import { Box, Typography } from "@mui/material"
// import IconButton from '@mui/material/IconButton'
// import { useState } from "react";
// import { DataGrid } from "@mui/x-data-grid";
// const columns = [
//     { 
//       field: 'product_name', 
//       headerName: 'Item Details', 
//       width: 275,
//       renderCell: (params) => (
//         <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
//           <img src='./machine.png' alt='' style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
//           <Box>
//             <Typography variant="subtitle2">{params.row.product_name}</Typography>
//           </Box>
//         </Box>
//       ),
//     },
//     { field: 'description', headerName: 'Description', width: 275 },
//     { field: 'stock_available', headerName: 'Stock', width: 275 },
//     {
//       field: 'action',
//       headerName: 'Action',
//       sortable: false,
//       renderCell: (params) => {
//         return (
//           <React.Fragment>
//             <IconButton aria-label="move up">
//               {/* <ArrowUpwardIcon /> */}
//             </IconButton>
//           </React.Fragment>
//         );
//       },
//       width: 150,
//     },
//   ];

// const EquipmentTableView = ({data, setOpensetOpenDialog}) => {
//     const [page, setPage] = useState(0);
//     console.log('data', data);
//     const rows = data.map((item, index) => ({ ...item, id: index }));
//     console.log('56', rows);
//     //const rows = 0;

//     const handlePageChange = (newPage) => {
//         setPage(newPage);
//     };
//     return(
//         <Box>
//         <DataGrid
//                 rows={rows}
//                 columns={columns}
//                 pageSize={7}
//                 page={page}
//                 onPageChange={handlePageChange}
//                 rowsPerPageOptions={[3]}
//                 pagination
//                 paginationMode="server" // Use this if you're handling pagination server-side
//                 checkboxSelection
//                 disableSelectionOnClick
//             />
//         </Box>
//     )

// }
// export default EquipmentTableView












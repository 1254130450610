import React, { useState, useEffect } from 'react';
import { Card, CardActions, CardContent, CardMedia, Button, Typography, CircularProgress } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
// import Tooltip from '@mui/material/Tooltip';
import { Box } from "@mui/material";
// import { useUser } from '../../../UserContext'; // Adjust the import path as necessary
import EquipmentDetailAPI from 'utilities/api/StudentEquipmentDetail';

import EquipmentDetails from 'components/popups/EquipmentDetails';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { useSelector } from 'react-redux';
import ImageBaseURL from 'utilities/api/axios';


const EquipmentCardView = ({ item, searchQuery }) => {
    // const { accessToken } = useUser();

    console.log('EquipmentCardView-item',item);

    const {access, user} = useSelector((state) => state.user);

    const machineId = item.id;
    const [machineDetails, setMachineDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [openDialog, setOpenDialog] = useState(false); // State to manage dialog open/close


    useEffect(() => {
        const fetchMachineDetails = async () => {
            setLoading(true);
            try {
                const details = await EquipmentDetailAPI.EquipmentDetailGet(access, machineId);
                setMachineDetails(details);
            } catch (error) {
                setError(error.message || 'Failed to fetch machine details');
            } finally {
                setLoading(false);
            }
        };

        if (machineId) {
            fetchMachineDetails();
        }
    }, [access, machineId]);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
    setOpenDialog(false);
    };

    const filterData = (details) => {
        const query = searchQuery.toLowerCase();
        const { name, category, location, availability } = details;

        return (
            (name && name.toLowerCase().includes(query)) ||
            (category && category.toLowerCase().includes(query)) ||
            (location && location.toLowerCase().includes(query)) ||
            (availability && availability.toLowerCase().includes(query))
        );
    };

    const filtered = machineDetails && filterData(machineDetails);


    // Styles
    const cardStyles = {
        maxWidth: 250,
        padding: '1rem .5rem',
        borderRadius: '1rem',
        border: '2px solid var(--Primary-Primary01, rgba(160, 160, 160, 0.3) )',
        background: 'var(--Primary-Primary-11, #e8e8f2e6)',
        margin: '10px 0px 0px 10px',
        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)', // Increased shadow
        transition: 'box-shadow 0.3s ease', // Transition for smooth effect
        '&:hover': {
            // boxShadow: '0px 12px 20px rgba(0, 0, 0, 0.3)', // Increased shadow on hover
            border: '2px solid var(--Primary-Primary01, rgba(255, 134, 0, 0.35) )',
        },
    };

    const mediaStyles = {
        height: 150,
        borderRadius: '.75rem',
        // border: '1px solid #7594ff',
    };

    const titleStyles = {
        color: '#686868',
        fontFamily: 'Inter',
        fontSize: '30px',
        fontWeight: '400',
        lineHeight: '1.5',
        marginBottom:'5px',
        // textAlign: 'center',
    };

    const infoStyles = {
        // color: '#212121',
        color: '#717070',
        fontFamily: 'Inter',
        fontSize: '15px',
        fontWeight: '350',
        lineHeight: '1.5',
    };

    const infoMarker = {
        fontWeight: '500',
        color: '#666565',
    };

    // const baseURL = 'https://staging.misadmin.mapit.ai/';

    return (
        <>
            {filtered && (
                <Card sx={cardStyles}>

                    {loading && <CircularProgress />} {/* Display loading indicator */}
                    {!loading && !error && machineDetails && ( // Render content if details are available
                        <>
                            <CardMedia
                                sx={mediaStyles}
                                image={machineDetails.image ? `${ImageBaseURL.defaults.baseURL}${machineDetails.image}` : "./machine.png"} // Conditional image rendering
                                title={machineDetails.name}
                            />
                            <CardContent sx={{ padding: '10px 0px 0px 10px' }}>
                                <Typography variant="h5" sx={titleStyles}>
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                        {machineDetails.name}
                                        <Button onClick={handleOpenDialog} sx={{ marginLeft: 'auto' }}>
                                            <VisibilityIcon sx={{ color: '#273f77' }} />
                                        </Button>
                                        <EquipmentDetails
                                            open={openDialog}
                                            onClose={handleCloseDialog}
                                            machineId={machineId}
                                            machineDetails={machineDetails}
                                        />
                                    </Box>
                                </Typography>
                                <Typography variant="body1" sx={infoStyles}>
                                    <span style={infoMarker}>Category:</span> {machineDetails.category || 'N/A'}
                                </Typography>
                                <Typography variant="body1" sx={infoStyles}>
                                    <span style={infoMarker}>Availability:</span> {machineDetails.availability || 'N/A'}
                                </Typography>
                                <Typography variant="body1" sx={infoStyles}>
                                    <span style={infoMarker}>Location:</span> {machineDetails.location || 'N/A'}
                                </Typography>
                                <Typography variant="body1" sx={infoStyles}>
                                    <span style={infoMarker}>Description:</span> {machineDetails.description || 'N/A'}
                                </Typography>
                            </CardContent>
                        </>
                    )}
                    {error && <Typography color="error">{error}</Typography>} {/* Display error message */}
                </Card>
            )}
        </>
    );
};

export default EquipmentCardView;






// import { Box } from "@mui/material";
// import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
// import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
// import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

// const EquipmentCardView = ({item, setOpenDialog}) => {

//     console.log("EquipmentCardView:",item);
//     const id = item.id;
//     const handleOpenDialog = () => {
//         setOpenDialog(true);
//     };
//     return(
//     <Card sx={{ 
//         maxWidth: 300,
//         padding:'.5rem',
//         borderRadius: '1rem',
//         border: '2px solid var(--Primary-Primary01, #212121)',
//         background: 'var(--Primary-Primary-11, #F4F4F4)'  
//     }}>
//         <CardMedia
//             sx={{ height: 150, borderRadius:'.75rem' }}
//             image="./machine.png"
//             title="green iguana"
//         />
//         <CardContent>
//             <Typography gutterBottom variant="h5" component="div">
//              {`${item.name}`}
//             </Typography>
//             <Typography variant="body2" color="text.secondary">
//                 <span style={{fontWeight:'bold'}}>Availablity: </span>{`${item.date}`}
//             </Typography>
//             <Typography variant="body2" color="text.secondary">
//                 <span style={{fontWeight:'bold'}}>{`${item.status}`}</span>
//             </Typography>
//         </CardContent>
//     </Card>

//     )
// }
// export default EquipmentCardView;






// import { Box } from "@mui/material";
// import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
// import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
// import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

// const EquipmentCardView = ({item, setOpenDialog}) => {
//     const handleOpenDialog = () => {
//         setOpenDialog(true);
//     };
//     return(
//     <Card sx={{ 
//         maxWidth: 300,
//         padding:'.5rem',
//         borderRadius: '1rem',
//         border: '2px solid var(--Primary-Primary01, #212121)',
//         background: 'var(--Primary-Primary-11, #F4F4F4)'  
//     }}>
//         <CardMedia
//             sx={{ height: 150, borderRadius:'.75rem' }}
//             image="./machine.png"
//             title="green iguana"
//         />
//         <CardContent>
//             <Typography gutterBottom variant="h5" component="div">
//              {`${item.product_name}`}
//             </Typography>
//             <Typography variant="body2" color="text.secondary">
//                 <span style={{fontWeight:'bold'}}>Availablity: </span>{`${item.stock_available}`}
//             </Typography>
//             <Typography variant="body2" color="text.secondary">
//                 <span style={{fontWeight:'bold'}}>{`${item.tag}`}</span>
//             </Typography>
//         </CardContent>
//     </Card>

//     )
// }
// export default EquipmentCardView;
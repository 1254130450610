import React, { useState, useEffect } from 'react';
import { Card, CircularProgress, Button } from "@mui/material";
import Checkbox from '@mui/material/Checkbox';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import Box from '@mui/material/Box';
import LikeButton from './LikeButton';
import EquipmentDetailAPI from 'utilities/api/StudentEquipmentDetail'; // Import your API utility
import { useSelector } from 'react-redux';
import EquipmentDetails from 'components/popups/EquipmentDetails';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ImageBaseURL from 'utilities/api/axios';

const TableListItem = ({ product, searchQuery }) => {
  const { access } = useSelector((state) => state.user);
  const equipmentId = product.id;
  const [machineDetails, setMachineDetails] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false); // State to manage dialog open/close

  useEffect(() => {
    const fetchMachineDetails = async () => {
      setLoading(true);
      try {
        const details = await EquipmentDetailAPI.EquipmentDetailGet(access, equipmentId);
        setMachineDetails(details);
      } catch (error) {
        setError('Failed to fetch machine details');
      } finally {
        setLoading(false);
      }
    };

    if (equipmentId) {
      fetchMachineDetails();
    }
  }, [access, equipmentId]);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  // Filter function
  const filterData = (details) => {
    const query = searchQuery.toLowerCase();
    const { name, category, description } = details;

    return (
      (name && name.toLowerCase().includes(query)) ||
      (category && category.toLowerCase().includes(query)) ||
      (description && description.toLowerCase().includes(query))
    );
  };

  const filtered = machineDetails && filterData(machineDetails);

  // const baseURL = 'https://staging.misadmin.mapit.ai/';

  return (
    <>
      {filtered && (
        <Card
          sx={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            bgcolor: 'white',
            height: 'auto',
            boxSizing: 'border-box',
            padding: '3px',
          }}
        >
          {loading && <CircularProgress />}
          {!loading && !error && machineDetails && (
            <>
             <CardMedia
                component="img"
                sx={{
                  width: '50px', // Set a fixed width
                  height: '50px', // Set a fixed height equal to the width
                  borderRadius: '50%',
                  flexShrink: 0,
                  marginRight: "1vw",
                  padding: '2px 4px',
                  objectFit: 'cover' // Ensure image fills the circular container
                }}
                image={machineDetails.image ? `${ImageBaseURL.defaults.baseURL}${machineDetails.image}` : "./machine.png"} // Conditional image rendering
                alt={product.product_name}
              />
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  p: 0,
                  width: '20%', // Adjusted width
                  height: 'auto',
                  overflow: 'hidden',
                }}
              >
                <Typography
                  variant="subtitle1"
                  sx={{
                    color: '#898989',
                    fontWeight: '400',
                    fontSize: '1.4rem',
                    lineHeight: '1.25rem',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                  }}
                >
                  {machineDetails.name}
                </Typography>
              </Box>

              <Typography
                variant="body2"
                noWrap
                sx={{
                  width: '25%', // Adjusted width
                  color: '#7A7A7A',
                  fontWeight: '500',
                  fontSize: '1.25rem',
                  lineHeight: 'auto',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {machineDetails.category}
              </Typography>

              <Typography
                variant="body2"
                noWrap
                sx={{
                  width: '25%', // Adjusted width
                  color: '#7A7A7A',
                  fontWeight: '500',
                  fontSize: '1.25rem',
                  lineHeight: 'auto',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
              >
                {machineDetails.description}
              </Typography>

              <Typography
                sx={{
                  width: '10%', // Adjusted width
                  color: '#898989',
                  fontWeight: '400',
                  fontSize: '1.25rem',
                  lineHeight: '1.5rem',
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  textOverflow: 'ellipsis',
                  textAlign: 'center',
                }}
              >
                {machineDetails ? machineDetails.category : "N/A"}
              </Typography>

              <Box
                sx={{
                  width: '7%', // Adjusted width
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                  gap: '0.25rem',
                  padding: '0.875rem 0.75rem',
                }}
              >
                {/* Like button */}
                {/* <LikeButton /> */}
                {/* Equipment details popup */}
                <Button onClick={handleOpenDialog}><VisibilityIcon></VisibilityIcon></Button>
                <EquipmentDetails open={openDialog} onClose={handleCloseDialog} machineId={equipmentId} machineDetails={machineDetails} />
              </Box>
            </>
          )}
        </Card>
      )}
    </>
  );
};

export default TableListItem;

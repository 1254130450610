import React, { useState } from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import CardMedia from '@mui/material/CardMedia';
import { Box, Button } from '@mui/material';
import InventoryDialog from 'components/popups/InventoryDialog';
import ImageBaseURL from 'utilities/api/axios';

const TableListItem = ({ product, searchQuery, inventoryDetails, refreshInventoryDetails }) => {
  const [openDialog, setOpenDialog] = useState(false);

  const tagStyles = {
    Exhaustible: { borderRadius: '50px', width: '100px', textAlign: 'center', background: '#FF6347', color: '#FFFFFF', display: 'inline-block', justifyContent: 'center', alignItems: 'center' },
    NonExhaustible: { borderRadius: '50px', width: '130px', textAlign: 'center', background: '#32CD32', color: '#FFFFFF', display: 'inline-block', justifyContent: 'center', alignItems: 'center' },
    Purchase: { width: '80px', textAlign: 'center', borderRadius: '50px', color: '#273F77', background: '#D8DBF5', display: 'inline-block', justifyContent: 'center', alignItems: 'center' },
    InLabOnly: { width: '100px', textAlign: 'center', borderRadius: '50px', color: '#E79A2B', background: '#FFF1E4', display: 'inline-block', justifyContent: 'center', alignItems: 'center' },
    Issue: { width: '60px', textAlign: 'center', borderRadius: '50px', color: '#007BFF', background: '#CCE5FF', display: 'inline-block', justifyContent: 'center', alignItems: 'center' },
    Free: { width: '60px', textAlign: 'center', borderRadius: '50px', color: '#107C10', background: '#DCFDDB', display: 'inline-block', justifyContent: 'center', alignItems: 'center' },
    HighValue: { width: '100px', textAlign: 'center', borderRadius: '50px', color: '#D0A300', background: '#FEFFD2', display: 'inline-block', justifyContent: 'center', alignItems: 'center' }
  };

  const buttonStyles = {
    display: 'flex', width: '80%', justifyContent: 'center', alignItems: 'center', borderRadius: '0.5rem', border: '1px solid #273F77', fontWeight: '600', color: '#273F77', backgroundColor: 'transparent',
    '&:hover': { backgroundColor: '#273F77', color: '#FFFFFF' },
  };

  const allowedTabs = [];

  if (inventoryDetails) {
    for (let tagNumber = 1; tagNumber <= 4; tagNumber++) {
      const tagValue = inventoryDetails[`tag${tagNumber}`];
      if (tagValue) {
        allowedTabs.push(tagValue);
      }
    }
  }

  const filterData = (details, allowedTabs) => {
    const query = searchQuery.toLowerCase();
    const { name, category, stock_available } = details;
    const hasMatchingTag = allowedTabs && allowedTabs.some(tag => tag.toLowerCase().includes(query));
    return (
      (name && name.toLowerCase().includes(query)) ||
      (category && category.toLowerCase().includes(query)) ||
      hasMatchingTag ||
      (stock_available && stock_available.toString().toLowerCase().includes(query))
    );
  };

  const filtered = inventoryDetails && filterData(inventoryDetails, allowedTabs);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    refreshInventoryDetails();
  };

  // const baseURL = 'https://staging.misadmin.mapit.ai/';

  return (
    <>
      {filtered && (
        <Card sx={{ display: 'flex', alignItems: 'center', width: '100%', bgcolor: 'white', boxSizing: 'border-box' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', width: '20%', p: 0, ml:'2%' }}>
            <CardMedia
              component="img"
              sx={{
                width: '50px',
                height: '50px',
                borderRadius: '50%',
                marginRight: '1vw',
                objectFit: 'cover',
                // marginLeft:'2%'
              }}
              image={inventoryDetails.image ? `${ImageBaseURL.defaults.baseURL}${inventoryDetails.image}` : "./machine.png"}
              alt={inventoryDetails.name}
            />
            <Typography variant="subtitle1" sx={{ color: '#898989', fontSize: '1.25rem', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
              {inventoryDetails?.name}
            </Typography>
          </Box>
          <Typography sx={{ textAlign: 'center', width: '25%', color: '#898989', fontWeight: '400', fontSize: '1.25rem', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
            {inventoryDetails?.category}
          </Typography>
          <Typography sx={{ textAlign: 'center', width: '15%', color: '#898989', fontWeight: '400', fontSize: '1.25rem', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
            {inventoryDetails?.stock_available}
          </Typography>
          <CardContent sx={{ width: '20%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '0.5rem', alignItems:'center' }}>
              {allowedTabs.map((tagValue, index) => (
                <div key={index} style={tagStyles[tagValue]}>
                  {tagValue}
                </div>
              ))}
            </Box>
          </CardContent>
          <Box sx={{ width: '20%', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0.25rem', padding: '0.875rem 0.75rem' }}>
            {allowedTabs.includes('Free') ? (
              <Typography sx={{ fontWeight: '520', color: '#273F77' }}>Free to use</Typography>
            ) : (
              <Button onClick={handleOpenDialog} sx={buttonStyles}>Get</Button>
            )}
          </Box>
        </Card>
      )}
      <InventoryDialog open={openDialog} onClose={handleCloseDialog} machineId={product.id} allowedTabs={allowedTabs} tagStyles={tagStyles} />
    </>
  );
};

export default TableListItem;

import React, { useState } from 'react';
import Header from 'components/header';
import Sidebar from 'components/sidebar';
import { Box } from '@mui/material';
import DashboardContent from 'components/dashboard/DashboardContent';

const Dashboard = () => {
    return (
        <Box sx={{ display: 'flex' }}>
            <Sidebar />
            <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', width: '100%' }}>
                <Header />
                <DashboardContent />
            </Box>
        </Box>
    );
};

export default Dashboard;

import { axiosAuthService } from "./axios";

const StudentUserDetailAPIs = {

    StudentDetailUpdate: async function (id, data) {
        try {
            const response = await axiosAuthService.patch(`/users/${id}/`, data);
            console.log('Response:', response.data);
            return response.data; // Assuming the server returns the data
        } catch (error) {
            console.error('Error updating user:', error);
            throw error;
        }
    },

    StudentDetailGet: async function (id) {
        try {
            const response = await axiosAuthService.get(`/users/${id}/`);
            console.log('Response:', response.data);
            return response.data; // Assuming the server returns the data
        } catch (error) {
            console.error('Error getting user details:', error);
            throw error;
        }
    },

    // StudentDetailPut: async function (id, data) {
    //     try {
    //         const response = await axiosAuthService.put(`/auth/users/${id}/`, data);
    //         console.log('Response:', response.data);
    //         return response.data; // Assuming the server returns the data
    //     } catch (error) {
    //         console.error('Error putting user details:', error);
    //         throw error;
    //     }
    // },

    // StudentDetailDelete: async function (id) {
    //     try {
    //         const response = await axiosAuthService.delete(`/auth/users/${id}/`);
    //         console.log('Response:', response.data);
    //         return response.data; // Assuming the server returns the data
    //     } catch (error) {
    //         console.error('Error deleting user:', error);
    //         throw error;
    //     }
    // },
};

export default StudentUserDetailAPIs;

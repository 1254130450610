import React, { useEffect, useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme, styled } from "@mui/material/styles"; 
import { useSelector } from "react-redux"; // Import useSelector
import reportsData from "./reportsData";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
    TextField,
    Button,
    MenuItem,
    Box,
    Typography,
    FormControl,
    Select,
    InputLabel,
    FormHelperText,
} from "@mui/material";
import ReportIssueAPI from "utilities/api/ReportIssueApi";

const typographyStyle = {
    color: "#333",
    textAlign: "center",
    fontFamily: "'Roboto', sans-serif",
    fontSize: "1.2rem",
    fontWeight: 700,
    lineHeight: "1.5",
    letterSpacing: "0.02rem",
};

const boxStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: ".6rem",
    alignSelf: "stretch",
    marginBottom: "1rem",
};

const StyledFormControl = styled(FormControl)(({ theme }) => ({
    margin: theme.spacing(2, 0),
    minWidth: 120,
    "& .MuiInputLabel-root": {
        color: "#666",
        fontWeight: 500,
    },
    "& .MuiInputBase-root": {
        borderRadius: 8,
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
        transition: "box-shadow 0.3s ease-in-out",
        "&:hover": {
            boxShadow: "0 4px 15px rgba(0, 0, 0, 0.2)",
        },
    },
    "& .MuiSelect-select": {
        padding: theme.spacing(1.5),
    },
}));

const ReportIssue = () => {
    const theme = useTheme(); 
    const { user } = useSelector((state) => state.user); // Fetch the user from Redux state
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [buttonYPosition, setButtonYPosition] = useState(50); 
    const [isDragging, setIsDragging] = useState(false);

    const handleButtonClick = () => {
        setIsDialogOpen(true);
    };

    const handleCloseDialog = () => {
        setIsDialogOpen(false);
    };

    const handleMouseDown = (event) => {
        setIsDragging(true);
    };

    const handleMouseMove = (event) => {
        if (isDragging) {
            const newYPosition = (event.clientY / window.innerHeight) * 100;
            setButtonYPosition(newYPosition);
        }
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const validationSchema = Yup.object().shape({
        reportTitle: Yup.string().required("Report Title is required"),
        reportIssue: Yup.string().required("Report Issue is required"),
        otherIssue: Yup.string()
    });

    useEffect(() => {
        formik.resetForm();
    }, [isDialogOpen]);

    useEffect(() => {
        if (isDragging) {
            document.addEventListener("mousemove", handleMouseMove);
            document.addEventListener("mouseup", handleMouseUp);
        } else {
            document.removeEventListener("mousemove", handleMouseMove);
            document.removeEventListener("mouseup", handleMouseUp);
        }
        return () => {
            document.removeEventListener("mousemove", handleMouseMove);
            document.removeEventListener("mouseup", handleMouseUp);
        };
    }, [isDragging]);

    const formik = useFormik({
        initialValues: {
            reportTitle: "",
            reportIssue: "",
            otherIssue: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            console.log("Submitting values", values);

            // Pass the user data to the API call
            ReportIssueAPI.ReportListPost({ ...values, user })
                .then((result) => {
                    console.log(result);
                    alert("You have registered successfully!");
                    handleCloseDialog();
                })
                .catch((error) => {
                    console.log(error);
                    alert(`${error.response.data.error}`);
                });
        },
    });
    // const formik = useFormik({
    //     initialValues: {
    //         reportTitle: "",
    //         reportIssue: "",
    //         otherIssue: "",
    //     },
    //     validationSchema: validationSchema,
    //     onSubmit: (values) => {
    //         console.log("Submitting values", values);
    
    //         // Ensure that an empty string is sent if user ID doesn't exist
    //         const userId = user && user.id ? user.id : '';
    
    //         ReportIssueAPI.ReportListPost({ ...values, user: userId })
    //             .then((result) => {
    //                 console.log(result);
    //                 alert("You have registered successfully!");
    //                 handleCloseDialog();
    //             })
    //             .catch((error) => {
    //                 console.log(error);
    //                 alert(`${error.response.data.error}`);
    //             });
    //     },
    // });    

    return (
        <div>
            <Button
                variant="contained"
                onClick={handleButtonClick}
                onMouseDown={handleMouseDown}
                sx={{
                    color: "#fff",
                    zIndex: 100,
                    position: "fixed",
                    right: 0,
                    top: `${buttonYPosition}%`,
                    border: "none",
                    borderRadius: "8px",
                    padding: "8px",
                    cursor: "pointer",
                    backgroundColor: "#E79A2B",
                    transform: "translateY(-50%) rotate(90deg)",
                    transformOrigin: "right center",
                    marginRight: "1rem",
                    textTransform: 'none',
                    fontWeight: 600,
                    gap: '8px',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                Report Problem
            </Button>

            <Dialog open={isDialogOpen} onClose={handleCloseDialog} sx={{ '& .MuiDialog-paper': { width: '600px', maxWidth: 'none', padding: '2%', borderRadius: '5%' } }}>
                <DialogTitle>
                    Select Your Issue
                    <IconButton
                        aria-label="close"
                        onClick={handleCloseDialog}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    <form onSubmit={formik.handleSubmit}>
                        <Box sx={boxStyle}>
                            <Typography sx={typographyStyle}>Title</Typography>
                            <StyledFormControl fullWidth>
                                <InputLabel id="report-title-label">Report Title</InputLabel>
                                <Select
                                    labelId="report-title"
                                    id="reportTitle"
                                    name="reportTitle"
                                    value={formik.values.reportTitle}
                                    label="Report Title"
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.reportTitle &&
                                        Boolean(formik.errors.reportTitle)
                                    }
                                >
                                    {reportsData.map((option, index) => (
                                        <MenuItem key={index} value={option.issueTitle}>
                                            {option.issueTitle}
                                        </MenuItem>
                                    ))}
                                </Select>
                                {formik.touched.reportTitle && formik.errors.reportTitle && (
                                    <FormHelperText error>
                                        {formik.errors.reportTitle}
                                    </FormHelperText>
                                )}
                            </StyledFormControl>
                        </Box>

                        <Box sx={boxStyle}>
                            <Typography sx={typographyStyle}>Issue</Typography>
                            <StyledFormControl fullWidth>
                                {formik.values.reportTitle !== "Other" ? (
                                    <>
                                        <InputLabel id="report-issue-label">
                                            Report Issue
                                        </InputLabel>

                                        <Select
                                            labelId="report-issue"
                                            id="reportIssue"
                                            name="reportIssue"
                                            value={formik.values.reportIssue}
                                            label="Report Issue"
                                            onChange={formik.handleChange}
                                            error={
                                                formik.touched.reportIssue &&
                                                Boolean(formik.errors.reportIssue)
                                            }
                                        >
                                            {reportsData
                                                .filter(
                                                    (option) =>
                                                        option.issueTitle === formik.values.reportTitle
                                                )
                                                .flatMap((filteredOption) => filteredOption.issueInfo)
                                                .map((issue, index) => (
                                                    <MenuItem key={index} value={issue}>
                                                        {issue}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </>
                                ) : (
                                    <TextField
                                        labelId="report-issue"
                                        id="reportIssue"
                                        name="reportIssue"
                                        value={formik.values.reportIssue}
                                        label="Report Issue"
                                        onChange={formik.handleChange}
                                        error={
                                            formik.touched.reportIssue &&
                                            Boolean(formik.errors.reportIssue)
                                        }
                                    />
                                )}

                                {formik.touched.reportIssue && formik.errors.reportIssue && (
                                    <FormHelperText error>
                                        {formik.errors.reportIssue}
                                    </FormHelperText>
                                )}
                            </StyledFormControl>
                        </Box>

                        {formik.values.reportIssue === "Other" && (
                            <Box sx={boxStyle}>
                                <Typography sx={typographyStyle}>
                                    Specify Other Issue
                                </Typography>
                                <StyledFormControl fullWidth>
                                    <TextField
                                        id="otherIssue"
                                        name="otherIssue"
                                        value={formik.values.otherIssue}
                                        label="Other Issue"
                                        onChange={formik.handleChange}
                                    />
                                </StyledFormControl>
                            </Box>
                        )}

                        <DialogActions>
                            <Button type="submit" variant="contained" color="primary" sx={{
                                color: "#fff",
                                border: "none",
                                borderRadius: "8px",
                                padding: "8px",
                                cursor: "pointer",
                                backgroundColor: "#E79A2B",
                                marginRight: "1rem",
                                textTransform: 'none',
                                fontWeight: 600,
                                gap: '8px',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}>
                                Continue
                            </Button>
                        </DialogActions>
                    </form>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default ReportIssue;

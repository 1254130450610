import React, { useState, useEffect } from 'react';
import { Drawer, Box, Button, Typography, TextField, MenuItem, Autocomplete, Chip } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import {
  NoteAddOutlined as NoteAddOutlinedIcon,
  AutoModeOutlined as AutoModeOutlinedIcon,
  GroupAddOutlined as GroupAddOutlinedIcon,
  InsertLinkOutlined as InsertLinkOutlinedIcon,
  Edit as EditIcon,
  PostAddOutlined as PostAddOutlinedIcon
} from '@mui/icons-material';
import StudentGetUser from 'utilities/api/StudentAllUserAPIs';
import CloseIcon from '../../utilities/dummy_assets/Close_round.png';
import ProjectAPIs from 'utilities/api/StudentProjectAPI';
import { toast } from 'react-toastify';

const validationSchema = Yup.object().shape({
  title: Yup.string().required('Project Title is required'),
  type: Yup.string().required('Project Type selection is required'),
  progress_status: Yup.string().required('Project progress status is required'),
  description: Yup.string().required('Project description is required'),
  links: Yup.string(),
});

const styledBox = {
  display: 'flex', alignItems: 'center', gap: '10px', mb: 2, padding: '0px 5px'
};

const AddProject = ({ open, onClose }) => {
  const { user,access } = useSelector((state) => state.user);
  const [teammateOptions, setTeammateOptions] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [currentTeammates, setCurrentTeammates] = useState([]);

  useEffect(() => {
    const fetchAllUsers = async () => {
      try {
        const users = await StudentGetUser.AllUserListGet();
        setAllUsers(users);
      } catch (error) {
        console.error('Failed to fetch all users:', error);
      }
    };

    fetchAllUsers();
  }, []);

  const handleTeammateSearch = async (event, value) => {
    if (value.length > 0) {
      try {
        const results = await StudentGetUser.UserDetailsGet(value);
        setTeammateOptions(results.slice(0, 5));
      } catch (error) {
        console.error('Error fetching teammate options:', error);
      }
    } else {
      setTeammateOptions([]);
    }
  };

  const handleTeammateSelect = (event, value) => {
    const newTeammates = value.map(teammate => {
      return allUsers.find(user => user.id === teammate.id) || teammate;
    });
    const updatedTeammates = [...currentTeammates, ...newTeammates].filter(
      (v, i, a) => a.findIndex(t => t.id === v.id) === i
    );
    setCurrentTeammates(updatedTeammates);
    setTeammateOptions([]);
  };

  const handleRemoveTeammate = (teammateToRemove) => {
    setCurrentTeammates(currentTeammates.filter(teammate => teammate.id !== teammateToRemove.id));
  };

  const formik = useFormik({
    initialValues: {
        title: '',
        type: '',
        progress_status: '',
        links: '',
        description: '',
        teammates: []
    },
    validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
        // Map teammates to their IDs
        values.teammates = currentTeammates.map(teammate => teammate.id);
        values.student = user.id;
        values.TL = user.TL; 

        try {
            // Attempt to create the project
            await ProjectAPIs.ProjectCreate(access, values);
            toast.success('Project created successfully!');
            onClose(); // Close the form or modal
        } catch (error) {
            toast.error('Failed to create project. Please try again.');
            console.error('Error creating project:', error);
        } finally {
            setSubmitting(false); // Ensure that submitting state is reset regardless of success or failure
        }
    },
  });

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      fullWidth={true}
      maxWidth="md"
      sx={{
        '& .MuiPaper-root': {
          display: 'flex',
          height: 'auto',
          width: '50%',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          background: '#FAFAFA',
          boxShadow: '-4px 0px 24px 0px rgba(0, 0, 0, 0.15)',
          overflow: 'hidden',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          padding: '1rem',
          justifyContent: 'space-between',
          alignItems: 'center',
          alignSelf: 'stretch',
          background: '#273F77',
        }}
      >
        <Typography
          sx={{
            color: '#FFF',
            fontFamily: 'Roboto',
            fontSize: '2rem',
            fontWeight: 700,
          }}
        >
          Add Project
        </Typography>
        <img src={CloseIcon} alt='close icon' onClick={onClose} style={{ cursor: 'pointer' }} />
      </Box>
      <Box
        sx={{
          display: 'flex',
          padding: '1.5rem',
          flexDirection: 'column',
          alignItems: 'flex-start',
          gap: '1.5rem',
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Box sx={styledBox}>
            <EditIcon sx={{ color: '#5C5C5C' }} />
            <TextField
              name="title"
              label="Project Title"
              variant="outlined"
              fullWidth
              style={{ width: '300px', padding: '0px 10px' }}
              value={formik.values.title}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.title && Boolean(formik.errors.title)}
              helperText={formik.touched.title && formik.errors.title}
            />
          </Box>

          <Box sx={styledBox}>
            <NoteAddOutlinedIcon sx={{ color: '#5C5C5C' }} />
            <TextField
              name="type"
              label="Type of Project"
              variant="outlined"
              select
              fullWidth
              style={{ width: '300px', padding: '0px 10px' }}
              value={formik.values.type}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.type && Boolean(formik.errors.type)}
              helperText={formik.touched.type && formik.errors.type}
            >
              <MenuItem value="1">Academic</MenuItem>
              <MenuItem value="2">Research</MenuItem>
              <MenuItem value="3">Personal</MenuItem>
              <MenuItem value="4">Other</MenuItem>
            </TextField>
          </Box>

          <Box sx={styledBox}>
            <AutoModeOutlinedIcon sx={{ color: '#5C5C5C' }} />
            <TextField
              name="progress_status"
              label="Project Progress Status"
              variant="outlined"
              select
              fullWidth
              style={{ width: '300px', padding: '0px 10px' }}
              value={formik.values.progress_status}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.progress_status && Boolean(formik.errors.progress_status)}
              helperText={formik.touched.progress_status && formik.errors.progress_status}
            >
              <MenuItem value="1">Not Started</MenuItem>
              <MenuItem value="2">In Progress</MenuItem>
              <MenuItem value="3">Completed</MenuItem>
            </TextField>
          </Box>

          <Box sx={styledBox}>
            <GroupAddOutlinedIcon sx={{ color: '#5C5C5C' }} />
            <Autocomplete
              multiple
              options={teammateOptions}
              getOptionLabel={(option) => `${option.name} (${option.username})`}
              filterSelectedOptions
              onInputChange={(event, newInputValue) => handleTeammateSearch(event, newInputValue)}
              onChange={handleTeammateSelect}
              value={currentTeammates}
              sx={{ width: '650px', padding: '0px 10px' }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    key={option.id}
                    label={`${option.name} (${option.username})`}
                    {...getTagProps({ index })}
                    onDelete={() => handleRemoveTeammate(option)}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Add Teammates"
                  sx={{ width: '100%' }}
                />
              )}
            />
          </Box>

          <Box sx={styledBox}>
            <InsertLinkOutlinedIcon sx={{ color: '#5C5C5C' }} />
            <TextField
              name="links"
              label="Add Link/Documents"
              variant="outlined"
              fullWidth
              style={{ width: '650px', padding: '0px 10px' }}
              value={formik.values.links}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.links && Boolean(formik.errors.links)}
              helperText={formik.touched.links && formik.errors.links}
            />
          </Box>

          <Box sx={styledBox}>
            <PostAddOutlinedIcon sx={{ color: '#5C5C5C' }} />
            <TextField
              name="description"
              label="Project Description"
              variant="outlined"
              multiline
              rows={4}
              fullWidth
              InputProps={{
                sx: {
                  padding: '0px 10px',
                  marginLeft: '10px',
                  height: 'auto',
                  resize: 'none',
                },
              }}
              value={formik.values.description}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.description && Boolean(formik.errors.description)}
              helperText={formik.touched.description && formik.errors.description}
            />
          </Box>

          <Box sx={{
            display: 'flex',
            padding: '1rem',
            justifyContent: 'flex-end',
            alignItems: 'center',
            gap: '1.5rem',
            alignSelf: 'stretch',
            background: '#F4F4F4',
          }}>
            <Button onClick={onClose} sx={{
              borderRadius: '12px',
              border: '1px solid #B5B5B5',
            }}>
              Cancel
            </Button>
            <Button type="submit" variant="contained" sx={{
              borderRadius: '12px',
              border: '2px solid #212121',
              background: '#E79A2B',
              '&:hover': {
                backgroundColor: '#cf7a00',
              },
            }}>
              Submit
            </Button>
          </Box>
        </form>
      </Box>
    </Drawer>
  );
};

export default AddProject;

import React, { useEffect, useRef, useState } from 'react';
import { Paper, Typography, Box, Button } from '@mui/material';
import { Chart } from 'chart.js/auto';
import ProjectAPIs from 'utilities/api/StudentProjectAPI';
import nodatafoundImage from '../../../utilities/dummy_assets/nodatafound.jpg';
import { useNavigate } from 'react-router-dom';

const ProjectTypesChart = ({user}) => {
    const navigate = useNavigate();
    const chartRef = useRef(null);
    const chartInstanceRef = useRef(null);
    const [projectTypesData, setProjectTypesData] = useState({
        labels: ['Self Project', 'Professor Project', 'Course Project', 'Competitions'],
        values: [0, 0, 0, 0],
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await ProjectAPIs.ProjectsGet();
                const filteredData = data.filter(project => 
                    project.student === user.id || project.teammates.includes(user.id)
                );
                const projectTypesCount = filteredData.reduce((acc, project) => {
                    switch (project.type) {
                        case '1':
                            acc[0] += 1;
                            break;
                        case '2':
                            acc[1] += 1;
                            break;
                        case '3':
                            acc[2] += 1;
                            break;
                        case '4':
                            acc[3] += 1;
                            break;
                        default:
                            break;
                    }
                    return acc;
                }, [0, 0, 0, 0]);
    
                setProjectTypesData({
                    labels: ['Academic Project', 'Research Project', 'Personal Project', 'Other'],
                    values: projectTypesCount,
                });
            } catch (error) {
                console.error('Error fetching project data:', error);
            }
        };
    
        fetchData();
    }, [user]);
    
    useEffect(() => {
        // Only proceed if there is data and the canvas element exists
        if (chartRef.current && Math.max(...projectTypesData.values) > 0) {
            const ctx = chartRef.current.getContext('2d');

            // Cleanup function to destroy existing chart instance
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
            }

            // Calculate the maximum value for scaling
            const maxValue = Math.max(...projectTypesData.values);
            const dynamicMax = maxValue > 0 ? Math.ceil(maxValue * 1.2) : 50; // Ensure it's at least 50 if maxValue is 0

            // Create new chart instance
            chartInstanceRef.current = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: projectTypesData.labels,
                    datasets: [
                        {
                            label: 'Your Project Types',
                            data: projectTypesData.values,
                            backgroundColor: 'rgba(75, 192, 192, 0.6)',
                        },
                    ],
                },
                options: {
                    indexAxis: 'y',
                    scales: {
                        x: {
                            beginAtZero: true,
                            max: dynamicMax,
                            ticks: {
                                stepSize: 1, // Ensure the y-axis values are integers
                            },
                        },
                    },
                    plugins: {
                        legend: {
                            labels: {
                                font: {
                                    size: 14, // Adjust the font size of the legend
                                },
                            },
                        },
                    },
                    responsive: true,
                    maintainAspectRatio: false,
                    layout: {
                        padding: {
                            top: 20,
                            right: 20,
                            bottom: 20,
                            left: 20,
                        },
                    },
                },
            });
        }

        // Cleanup function to destroy chart instance on component unmount
        return () => {
            if (chartInstanceRef.current) {
                chartInstanceRef.current.destroy();
            }
        };
    }, [projectTypesData]);

    return (
        <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Paper style={{
                borderRadius: '24px',
                backgroundColor: '#f9fafe',
                borderStyle: 'solid',
                borderColor: '#d3d3d3',
                borderWidth: '1px',
                padding: '12px 16px',
                alignItems: 'center',
                width: '100%',
                maxWidth: '800px', // Max width for responsiveness
                height: '400px', // Fixed height
                display: 'flex',
                flexDirection: 'column'
            }}>
                <Typography variant="h6" component="div" gutterBottom style={{ fontSize: '25px', color: '#212121', textAlign: 'center', alignSelf: 'stretch' }}>
                    Your Project Types
                </Typography>
                {Math.max(...projectTypesData.values) > 0 ? (
                    <div style={{ flex: 1, width: '100%', position: 'relative' }}>
                        <canvas ref={chartRef}></canvas>
                    </div>
                ) : (
                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height="100%">
                        <img src={nodatafoundImage} alt="No Data Found" style={{ width: '250px', marginBottom: '16px' }} />
                        <Typography variant="h6" color="textSecondary">
                            No projects available
                        </Typography>
                        <Button onClick={()=>{navigate('/projects')}} variant="contained" color="warning" style={{ marginTop: '16px', backgroundColor: '#e79a2b', color: '#fff', borderRadius: '8px', borderColor: '#8c5000', padding: '8px 16px' }}>Reserve Now</Button>
                    </Box>
                )}
            </Paper>
        </Box>
    );
};

export default ProjectTypesChart;

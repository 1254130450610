import React from "react";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate,
} from "react-router-dom";
import { Signup } from "./pages/Signup.jsx"; // Adjust the import path as necessary
import { Login } from "./pages/Login.jsx"; // Adjust the import path as necessary
import Dashboard from "./pages/dashboard.jsx"; // Adjust the import path as necessary
import ExploreTL from "./pages/exploretl.jsx"; // Adjust the import path as necessary
import Projects from "./pages/projects.jsx"; // Adjust the import path as necessary
import Records from "./pages/records.jsx"; // Adjust the import path as necessary
import Profile from "./pages/Profile.jsx"; // Adjust the import path as necessary
import { useUser } from "./UserContext"; // Adjust the import path as necessary
import RegisterPage from "pages/auth/Register.jsx";
import CommonLoginPage from "pages/auth/commonLogin.jsx";
import AdminLoginPage from "pages/auth/adminLogin.jsx";
import StudentLoginPage from "pages/auth/studentLogin.jsx";
import ProtectedRoute from "components/utils/ProtectedRoute.jsx";
import Training from "pages/training.jsx";
import { useAxiosInterceptors } from "utilities/api/axios.js";
import ReportIssue from "components/ReportIssue/ReportIssue.jsx";
import RoutesWrapper from "routes.js";
import Calendar from "components/calendar/calendar.js";
import { ToastContainer } from "react-toastify";

export const App = () => {
    useAxiosInterceptors();

    return (

        <Router>
            <ReportIssue />
            <RoutesWrapper />
            <ToastContainer />
        </Router>
 );
};

        // <Router>
        //     <ReportIssue/>
        //     <Routes>
        //         <Route index element={<Navigate to="/login" />} />
        //         <Route path="/register" element={<RegisterPage />} />
        //         <Route path="/login" element={<CommonLoginPage />} />
        //         {/* <Route path="/adminlogin" element={<AdminLoginPage />} /> */}
        //         <Route path="/studentlogin" element={<StudentLoginPage />} />
        //         <Route
        //             path="/dashboard"
        //             element={
        //                 <ProtectedRoute>
        //                     <Dashboard />
        //                 </ProtectedRoute>
        //             }
        //         />
        //         <Route
        //             path="/explore"
        //             element={
        //                 <ProtectedRoute>
        //                     <ExploreTL />
        //                 </ProtectedRoute>
        //             }
        //         />
        //         <Route
        //             path="/records"
        //             element={
        //                 <ProtectedRoute>
        //                     <Records />
        //                 </ProtectedRoute>
        //             }
        //         />
        //         <Route
        //             path="/training"
        //             element={
        //                 <ProtectedRoute>
        //                     <Training />
        //                 </ProtectedRoute>
        //             }
        //         />
        //         <Route
        //             path="/profile"
        //             element={
        //                 <ProtectedRoute>
        //                     <Profile />
        //                 </ProtectedRoute>
        //             }
        //         />
        //         <Route
        //             path="/projects"
        //             element={
        //                 <ProtectedRoute>
        //                     <Projects />
        //                 </ProtectedRoute>
        //             }
        //         />

        //         {/* Redirect to dashboard for any other route */}
        //         <Route path="*" element={<Navigate to="/dashboard" />} />



        //         {/* <Route path="/signup" element={<Signup />} />
        //         <Route path="/login" element={<Login />} /> */}
        //         {/* <Route path="/" element={<Dashboard />} /> */}
        //         {/* <Route path="/" element={userData ? <Dashboard /> : <Navigate to="/login" />} />
        // <Route path="/explore" element={userData ? <ExploreTL /> : <Navigate to="/login" />} />
        // <Route path="/dashboard" element={userData ? <Dashboard /> : <Navigate to="/login" />} />
        // <Route path="/projects" element={userData ? <Projects /> : <Navigate to="/login" />} />
        // <Route path="/inventory" element={userData ? <Inventory /> : <Navigate to="/login" />} />
        // <Route path="/Profile" element={userData ? <Profile /> : <Navigate to="/login" />} /> */}
        //     </Routes>
        // </Router>
   
// import React from 'react';
// import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
// import { Signup } from './pages/Signup.jsx';
// import { Login } from './pages/Login.jsx';
// import Dashboard  from './pages/dashboard.jsx';
// import ExploreTL from 'pages/exploretl.jsx';
// import Projects from 'pages/projects.jsx';
// import Inventory from 'pages/inventory.jsx';

// import Profile from 'pages/Profile.jsx'

//  export const App = () => {
//      return (
//     <Router>
//     <Routes>
//       <Route path="/" element={<Dashboard/>}/>
//       <Route path="/explore" element={<ExploreTL/>}/>
//       <Route path="/dashboard" element={<Dashboard/>}/>
//        <Route path="/signup" element={<Signup/>}/>
//         <Route path="/login" element={<Login/>}/>
//         <Route path="/projects" element={<Projects/>}/>
//         <Route path ="/inventory" element={<Inventory/>}/>

//         <Route path ="/Profile" element={<Profile/>}/>

//     </Routes>
//     </Router>
//   );
// };

// import { UserProvider } from './UserContext'; // Adjust the import path as necessary
// export const App = () => {
//   return (
//      <UserProvider>
//        <Router>
//          <Routes>
//            <Route path="/" element={<Dashboard />} />
//            <Route path="/explore" element={<ExploreTL />} />
//            <Route path="/dashboard" element={<Dashboard />} />
//            <Route path="/signup" element={<Signup />} />
//            <Route path="/login" element={<Login />} />
//            <Route path="/projects" element={<Projects />} />
//            <Route path="/inventory" element={<Inventory />} />
//            <Route path="/Profile" element={<Profile />} />
//          </Routes>
//        </Router>
//      </UserProvider>
//   );
//  };

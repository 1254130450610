import { createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
    palette : {
        mode: 'dark',
        primary: {
            main: '#90caf9',
        },
        secondary: {
            main: '#f48fb1',
        },
        background: {
            default: '#121212',
            paper: '#1e1e1e',
        },
        text: {
            primary: '#ffffff',
            secondary: '#b0bec5',
        },
        action: {
            selected: '#333333',
        },
    },
    typography : {
        fontFamily: 'Roboto, Arial, sans-serif',
        body1: {
            fontSize: '1rem',
        },
        button: {
            textTransform: 'none',
            fontWeight: 600,
        },
    },
    component : {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#1e1e1e',
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                h6: {
                    color: '#ffffff',
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    marginInline: '1rem',
                },
                containedPrimary: {
                    backgroundColor: '#1976d2',
                    color: '#fff',
                    '&:hover': {
                        backgroundColor: '#1565c0',
                    },
                },
            },
        },
        MuiTabs: {
            styleOverrides: {
                root: {
                    background: '#1e1e1e',
                    border: '1px solid rgba(144,202,249,0.6)',
                    borderRadius: '12px',
                    marginBottom: '10px',
                },
            },
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    border: '2px solid rgba(144,202,249,0.6)',
                    '&.Mui-selected': {
                        background: 'rgb(20, 20, 20)',
                        color: 'white',
                    },
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    margin: '1rem 0',
                    width: '25ch',
                    '& .MuiInputAdornment-root': {
                        border: 'none',
                    },
                },
            },
        },
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    overflow: 'auto',
                    height: '100%',
                    background: '#1e1e1e',
                    border: '1px solid rgba(144,202,249,0.6)',
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    backgroundColor: '#1e1e1e',
                    color: '#ffffff',
                },
            },
        },
    },
});

export default darkTheme;
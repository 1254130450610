import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Header from 'components/header';
import Sidebar from 'components/sidebar';
import TopBarComponent from 'components/ExploreTL/TopBar';
import { Box, Typography } from '@mui/material';
import Content from 'components/ExploreTL/Content';
import nodatafoundImage from '../utilities/dummy_assets/nodatafound.jpg';

// Importing API's
import MachineListAPIs from 'utilities/api/StudentMachineList';
import InventoryListAPIs from 'utilities/api/StudentInventoryList';
import EquipmentListAPIs from 'utilities/api/StudentEquipmentList';

const ExploreTL = () => {
  const { access, user } = useSelector((state) => state.user);
  const location = useLocation();
  
  const [activeCategory, setActiveCategory] = useState(0);
  const [activeView, setActiveView] = useState(0);
  const [search, setSearch] = useState('');
  const [machineData, setMachineData] = useState([]);
  const [equipmentData, setEquipmentData] = useState([]);
  const [inventoryData, setInventoryData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response_machine = await MachineListAPIs.FilterMachineListGet(access);
        setMachineData(response_machine);
      } catch (error) {
        console.error('Error fetching machine list:', error);
      }

      try {
        const response_equipment = await EquipmentListAPIs.FilterEquipmentListGet(access);
        setEquipmentData(response_equipment);
      } catch (error) {
        console.error('Error fetching equipment list:', error);
      }

      try {
        const response_inventory = await InventoryListAPIs.FilterInventoryListGet(access);
        setInventoryData(response_inventory);
      } catch (error) {
        console.error('Error fetching inventory list:', error);
      }
    };

    fetchData();
  }, [user, access]);

  useEffect(() => {
    if (location.state && location.state.activeCategory !== undefined) {
      setActiveCategory(location.state.activeCategory);
    }
  }, [location.state]);

  let dataToDisplay;

  switch (activeCategory) {
    case 0:
      dataToDisplay = machineData;
      break;
    case 1:
      dataToDisplay = equipmentData;
      break;
    case 2:
      dataToDisplay = inventoryData;
      break;
    default:
      dataToDisplay = [];
  }

  const hasData = dataToDisplay.length > 0;

  return (
    <Box sx={{ display: 'flex', height: '100vh' }}>
      <Sidebar />
      <Box
        sx={{
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <Header />
        <Box
          sx={{
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            margin: '0px 20px',
            position: 'relative',
            height: '100%',
          }}
        >
          <TopBarComponent
            activeCategory={activeCategory}
            setActiveCategory={setActiveCategory}
            setSearch={setSearch}
            activeView={activeView}
            setActiveView={setActiveView}
          />
          {hasData ? (
            <Content
              data={dataToDisplay}
              searchQuery={search}
              activeCategory={activeCategory}
              activeView={activeView}
              setSearch={setSearch}
            />
          ) : (
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flexGrow={1}>
              <img src={nodatafoundImage} alt="No Data Found" style={{ width: 'auto', marginBottom: '16px' }} />
              <Typography variant='h5' color="textSecondary">
                No data available
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default ExploreTL;

import { createSlice } from '@reduxjs/toolkit';

const tutorialSlice = createSlice({
    name: 'tutorial',
    initialState: {
        onDetail: null,
        detailId: null, // Add a new state property to store the id
        onTutorialPage: false,

    },
    reducers: {
        setOnDetail: (state, action) => {
            console.log('Setting onDetail:', action.payload);
            state.onDetail = action.payload.onDetail; // Update the onDetail state
            state.detailId = action.payload.id; // Update the detailId state
        },
        resetOnDetail: (state) => { // Removed the unnecessary action parameter
            console.log('Resetting onDetail');
            state.onDetail = null;
            state.detailId = null; // Reset the detailId state
        },
        onTutorialPage: (state, action) => {
            console.log('Reducer onTutorialPage called with payload:', action.payload);
            state.onTutorialPage = action.payload;
        },
    },
});

export const { setOnDetail, resetOnDetail, onTutorialPage } = tutorialSlice.actions;
export default tutorialSlice;



// import { createSlice } from '@reduxjs/toolkit';

// const tutorialSlice = createSlice({
//     name: 'tutorial',
//     initialState: {
//         onDetail: null,
//         detailId: null, // Add a new state property to store the id
//     },
//     reducers: {
//         setOnDetail: (state, action) => {
//             console.log('Setting onDetail:', action.payload);
//             state.onDetail = action.payload.onDetail; // Update the onDetail state
//             state.detailId = action.payload.id; // Update the detailId state
//         },
//         resetOnDetail: (state) => { // Removed the unnecessary action parameter
//             console.log('Resetting onDetail');
//             state.onDetail = null;
//             state.detailId = null; // Reset the detailId state
//         },
//     },
// });

// export const { setOnDetail, resetOnDetail } = tutorialSlice.actions;
// export default tutorialSlice;



// import { createSlice } from '@reduxjs/toolkit';

// const tutorialSlice = createSlice({
//     name: 'tutorial',
//     initialState: {
//         onDetail: null,
//     },
//     reducers: {
//         setOnDetail: (state, action) => {
//             console.log('Setting onDetail:', action.payload);
//             state.onDetail = action.payload;
//         },
//         resetOnDetail: (state, action) => {
//             console.log('Resetting onDetail');
//             state.onDetail = null;
//         },
//     },
// });

// export const { setOnDetail, resetOnDetail } = tutorialSlice.actions;
// export default tutorialSlice;

import React from "react";
import { useFormik } from "formik";
import axios from "axios";
import Avatar from "@mui/material/Avatar";
import { alpha, styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from '@mui/material/Stack';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import { MenuItem } from '@mui/material';
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import * as Yup from 'yup';
import { Label } from '@mui/icons-material';

const TextFieldStyle = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.mode === 'light' ? '#F3F6F9' : '#1A2027',
        border: '1px solid',
        borderColor: theme.palette.mode === 'light' ? '#E0E3E7' : '#2D3843',
        fontSize: 16,
        width: 'auto',
        padding: '10px 12px',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}));

const initialValues = {
    studentName: '',
    studentEmail: '',
    phoneNo: '',
    branch: '',
    graduationYear: '',
    degree: '',
    hostelAddress: '',
    currentYear: '',
};

const validationSchema = Yup.object({
    studentName: Yup.string().required('Required'),
    studentEmail: Yup.string().email('Invalid email address').required('Required'),
    phoneNo: Yup.string().required('Required'),
    branch: Yup.string().required('Required'),
    graduationYear: Yup.string().required('Required'),
    degree: Yup.string().required('Required'),
    currentYear: Yup.string().required('Required'),
});

export const SignupForm = () => {
    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: (values, { setSubmitting }) => {
            setTimeout(() => {
                console.log(values);
                alert(JSON.stringify(values, null, 2));
                setSubmitting(false);
            }, 400);
        },
    });

    return (
        <div className='containersingupform'>
            <div className='top-right'>
                <Link style={{ textDecoration: "None", color: "#FFF" }} href="/signup">
                    <button style={{ textDecoration: "None", color: "#FFF" }} className='RegisterButton'>Register</button>
                </Link>
                <Link style={{ textDecoration: "None", color: "#273F77" }} href="/login">
                    <button style={{ textDecoration: "None", color: "#273F77" }} className='LoginButton'>Login</button>
                </Link>
            </div>
            <div className='Form' style={{ position: "absolute", marginTop: "10%" }}>
                <Container maxWidth="sm">
                    <form onSubmit={formik.handleSubmit}>
                        <Stack spacing={2} direction="column">
                            <Stack spacing={2} direction="row">
                                <FormControl variant="standard">
                                    <InputLabel shrink htmlFor="FormName">
                                        Student Name
                                    </InputLabel>
                                    <TextFieldStyle
                                        name="studentName"
                                        placeholder="Enter Student Name"
                                        id="FormName"
                                        value={formik.values.studentName}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.studentName && Boolean(formik.errors.studentName)}
                                        helperText={formik.touched.studentName && formik.errors.studentName}
                                        fullWidth
                                    />
                                </FormControl>
                                <FormControl variant="standard">
                                    <InputLabel shrink htmlFor="FormEmail">
                                        Student Email Address
                                    </InputLabel>
                                    <TextFieldStyle
                                        name="studentEmail"
                                        placeholder="Enter Student Email"
                                        id="FormEmail"
                                        value={formik.values.studentEmail}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.studentEmail && Boolean(formik.errors.studentEmail)}
                                        helperText={formik.touched.studentEmail && formik.errors.studentEmail}
                                        fullWidth
                                    />
                                </FormControl>
                            </Stack>
                            <Stack spacing={2} direction="row">
                                <FormControl variant="standard">
                                    <InputLabel shrink htmlFor="FormPhone">
                                        Phone No.
                                    </InputLabel>
                                    <TextFieldStyle
                                        name="phoneNo"
                                        placeholder="Enter Phone No."
                                        id="FormPhone"
                                        value={formik.values.phoneNo}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.phoneNo && Boolean(formik.errors.phoneNo)}
                                        helperText={formik.touched.phoneNo && formik.errors.phoneNo}
                                        fullWidth
                                    />
                                </FormControl>
                                <FormControl variant="standard">
                                    <InputLabel shrink htmlFor="FormBranch">
                                        Branch
                                    </InputLabel>
                                    <TextFieldStyle
                                        name="branch"
                                        placeholder="Enter Branch"
                                        id="FormBranch"
                                        value={formik.values.branch}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.branch && Boolean(formik.errors.branch)}
                                        helperText={formik.touched.branch && formik.errors.branch}
                                        fullWidth
                                    />
                                </FormControl>
                            </Stack>
                            <Stack spacing={2} direction="row">
                                <Stack direction="column">
                                    <InputLabel shrink htmlFor="FormYear">
                                        Graduation Year
                                    </InputLabel>
                                    <TextField
                                        id="FormYear"
                                        name="graduationYear"
                                        label="Select Year"
                                        select
                                        style={{ width: "250px" }}
                                        variant="outlined"
                                        value={formik.values.graduationYear}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.graduationYear && Boolean(formik.errors.graduationYear)}
                                        helperText={formik.touched.graduationYear && formik.errors.graduationYear}
                                    >
                                        <MenuItem value="2021">2021</MenuItem>
                                        <MenuItem value="2022">2022</MenuItem>
                                        <MenuItem value="2023">2023</MenuItem>
                                        <MenuItem value="2024">2024</MenuItem>
                                        <MenuItem value="2025">2025</MenuItem>
                                        <MenuItem value="2026">2026</MenuItem>
                                        <MenuItem value="2027">2027</MenuItem>
                                        <MenuItem value="2028">2028</MenuItem>
                                    </TextField>
                                </Stack>
                                <Stack direction="column">
                                    <InputLabel shrink htmlFor="FormDegree">
                                        Degree
                                    </InputLabel>
                                    <TextField
                                        id="FormDegree"
                                        name="degree"
                                        label="Select Degree"
                                        select
                                        fullWidth
                                        style={{ width: "250px" }}
                                        variant="outlined"
                                        value={formik.values.degree}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.degree && Boolean(formik.errors.degree)}
                                        helperText={formik.touched.degree && formik.errors.degree}
                                    >
                                        <MenuItem value="1">B.Tech</MenuItem>
                                        <MenuItem value="2">B.Sc</MenuItem>
                                        <MenuItem value="3">M.Tech</MenuItem>
                                        <MenuItem value="4">M.Sc</MenuItem>
                                    </TextField>
                                </Stack>
                            </Stack>
                            <Stack spacing={2} direction="row">
                                <FormControl variant="standard">
                                    <InputLabel shrink htmlFor="FormHostel">
                                        Hostel Address (Optional)
                                    </InputLabel>
                                    <TextFieldStyle
                                        name="hostelAddress"
                                        placeholder="Enter Current Hostel Address"
                                        id="FormHostel"
                                        value={formik.values.hostelAddress}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.hostelAddress && Boolean(formik.errors.hostelAddress)}
                                        helperText={formik.touched.hostelAddress && formik.errors.hostelAddress}
                                        fullWidth
                                    />
                                </FormControl>
                                <Stack direction="column">
                                    <InputLabel shrink htmlFor="FormCYear">
                                        Current Year
                                    </InputLabel>
                                    <TextField
                                        id="FormCYear"
                                        name="currentYear"
                                        label="Enter Current Year"
                                        select
                                        fullWidth
                                        style={{ width: "250px" }}
                                        variant="outlined"
                                        value={formik.values.currentYear}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.currentYear && Boolean(formik.errors.currentYear)}
                                        helperText={formik.touched.currentYear && formik.errors.currentYear}
                                    >
                                        <MenuItem value="1">1st</MenuItem>
                                        <MenuItem value="2">2nd</MenuItem>
                                        <MenuItem value="3">3rd</MenuItem>
                                        <MenuItem value="4">4th</MenuItem>
                                        <MenuItem value="5">5th</MenuItem>
                                    </TextField>
                                </Stack>
                            </Stack>
                            <Stack spacing={2} direction="row">
                                <Button
                                    variant="contained"
                                    className='cancelButton'
                                    onClick={() => formik.resetForm()}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className='submitButton'
                                    type="submit"
                                    variant="contained"
                                >
                                    Register
                                </Button>
                            </Stack>
                            <Stack direction="row">
                                <Typography align="right" className="AlreadyText" variant="body2">
                                    Already have an account?{' '}
                                    <Link href="/login">
                                        Login here
                                    </Link>
                                </Typography>
                            </Stack>
                        </Stack>
                    </form>
                </Container>
            </div>
        </div>
    );
};

import React from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
    Box, Button, TextField, MenuItem, Typography
} from '@mui/material';
import ProjectAPIs from 'utilities/api/StudentProjectAPI';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify'; 

const validationSchema = Yup.object().shape({
    type: Yup.string().required('Project type is required'),
    progress_status: Yup.string().required('Progress status is required'),
    title: Yup.string().required('Title is required'),
    description: Yup.string().required('Description is required'),
    student: Yup.number().required('Student ID is required').positive('Student ID must be a positive number').integer('Student ID must be an integer')
});

const AddProjectForm = ({ onClose, onAddProject }) => {
  const { user, access } = useSelector((state) => state.user);

  const formik = useFormik({
    initialValues: {
      type: '',
      progress_status: '',
      title: '',
      description: '',
      student: user.id,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        const result = await ProjectAPIs.ProjectCreate(access, values);
        toast.success('Project created successfully!');
        // alert('Project created successfully!');
        console.log(result);
        onAddProject();  // Notify parent component to refresh projects
        onClose();
      } catch (error) {
        toast.error('Failed to create project');
        // alert('Failed to create project');
        console.error(error);
      }
    }
  });

  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h5" component="h2" sx={{ marginBottom: 2 }}>
        Add New Project
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          <TextField
            select
            name="type"
            label="Project Type"
            value={formik.values.type}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.type && Boolean(formik.errors.type)}
            helperText={formik.touched.type && formik.errors.type}
          >
            <MenuItem value="">Select type</MenuItem>
            <MenuItem value="1">Academic</MenuItem>
            <MenuItem value="2">Research</MenuItem>
            <MenuItem value="3">Personal</MenuItem>
            <MenuItem value="4">Other</MenuItem>
          </TextField>
          <TextField
            select
            name="progress_status"
            label="Progress Status"
            value={formik.values.progress_status}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.progress_status && Boolean(formik.errors.progress_status)}
            helperText={formik.touched.progress_status && formik.errors.progress_status}
          >
            <MenuItem value="">Select status</MenuItem>
            <MenuItem value="1">Not Started</MenuItem>
            <MenuItem value="2">In Progress</MenuItem>
            <MenuItem value="3">Completed</MenuItem>
          </TextField>
          <TextField
            name="title"
            label="Title"
            value={formik.values.title}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.title && Boolean(formik.errors.title)}
            helperText={formik.touched.title && formik.errors.title}
          />
          <TextField
            name="description"
            label="Description"
            value={formik.values.description}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.description && Boolean(formik.errors.description)}
            helperText={formik.touched.description && formik.errors.description}
          />
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button onClick={onClose} color="secondary" sx={{ mr: 2 }}>
            Cancel
          </Button>
          <Button type="submit" color="primary" variant="contained">
            Add
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default AddProjectForm;

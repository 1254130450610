// MCQPopup.js

import React, { useState } from 'react';

const styles = {
  mcqPopup: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: 'white',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.5)',
    zIndex: 1000,
    textAlign: 'center',
    width: '300px',
  },
  h3: {
    marginBottom: '20px',
  },
  button: {
    display: 'block',
    width: '100%',
    padding: '10px',
    marginBottom: '10px',
    fontSize: '16px',
    cursor: 'pointer',
    border: 'none',
    borderRadius: '4px',
    transition: 'background-color 0.3s',
  },
  selectedButton: {
    backgroundColor: '#d3d3d3',
  },
  correctButton: {
    backgroundColor: '#4caf50',
    color: 'white',
  },
  incorrectButton: {
    backgroundColor: '#f44336',
    color: 'white',
  },
};

const MCQPopup = ({ question, options, correctOption, onCorrect, onRetry }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [isCorrect, setIsCorrect] = useState(false);

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    if (option === correctOption) {
      setIsCorrect(true);
    } else {
      setIsCorrect(false);
    }
  };

  const handleButtonClick = () => {
    if (isCorrect) {
      onCorrect();
    } else {
      onRetry();
    }
  };

  return (
    <div style={styles.mcqPopup}>
      <h3 style={styles.h3}>{question}</h3>
      {options.map((option, index) => (
        <button
          key={index}
          onClick={() => handleOptionSelect(option)}
          style={{
            ...styles.button,
            ...(selectedOption && option === selectedOption ? (isCorrect ? styles.correctButton : styles.incorrectButton) : {}),
            ...(selectedOption && option !== selectedOption ? styles.selectedButton : {}),
          }}
          disabled={!!selectedOption}
        >
          {option}
        </button>
      ))}
      {selectedOption && (
        <button onClick={handleButtonClick} style={styles.button}>
          {isCorrect ? 'Next' : 'Retry'}
        </button>
      )}
    </div>
  );
};

export default MCQPopup;

import React from 'react'
import Header from 'components/header'
// import Projectscont from 'components/content/projectscont'
import Projectscont from 'components/Project/projectscont'
import Sidebar from 'components/sidebar'
import { Box } from '@mui/material'
// import SomeComponent from 'components/someComponent'
// import TabbedInterface from 'components/Project/TabbedInterface'

const Projects = () => {
  return (

    <Box sx={{display:'flex'}}>
    <Sidebar/>
    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', width:'100%' }}>
      <Header/> 
      <Projectscont></Projectscont>
      {/* <TabbedInterface></TabbedInterface> */}
      {/* <SomeComponent/> */}
    </Box>
  </Box>
  )
}

export default Projects
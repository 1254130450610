import React, { useEffect, useState } from 'react';
import { Box, Paper, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import BookMachineAPIs from 'utilities/api/StudentBookMachine';
import IssueInventoryAPIs from 'utilities/api/StudentIssueInventory';
import PurchaseInventoryAPIs from 'utilities/api/StudentPurchaseInventory';
import nodatafoundImage from '../../../utilities/dummy_assets/nodatafound.jpg';

const CardItem = ({ number, title, description, buttonColor, buttonText, backgroundColor, borderColor, onClick }) => {
  return (
    <Paper 
      elevation={3} 
      style={{ 
        padding: '8px', 
        borderRadius: '12px', 
        position: 'relative', 
        margin: '16px 0', 
        border: `1px solid ${borderColor}`,
        backgroundColor: backgroundColor,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '110px',
        width: '100%'
      }}
    >
      <Button 
        variant="contained" 
        style={{ 
          position: 'absolute', 
          top: '-14px', 
          right: '24px', 
          backgroundColor: buttonColor,
          borderRadius: '50px',
          padding: '4px 16px',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 3
        }}
        onClick={onClick}
      >
        <Typography style={{ 
          fontSize: '16px', 
          lineHeight: '20px', 
          color: '#fff', 
          textAlign: 'left' 
        }}>
          {buttonText}
        </Typography>
      </Button>
      <Typography variant="h5" component="div" gutterBottom style={{ 
        fontSize: '22px', 
        fontWeight: '700', 
        color: '#212121', 
        zIndex: 0, 
        textAlign: 'left' 
      }}>
        {number}
      </Typography>
      <Typography variant="body1" component="div" style={{ 
        fontSize: '14px', 
        textTransform: 'capitalize', 
        fontWeight: '500', 
        textAlign: 'left', 
        color: '#212121', 
        zIndex: 1, 
        marginTop: '4px' 
      }}>
        {title}
      </Typography>
      <Typography variant="body2" color="textSecondary" component="div" gutterBottom style={{ 
        fontSize: '12px', 
        textTransform: 'lowercase', 
        fontWeight: '500', 
        textAlign: 'left', 
        color: '#7a7a7a', 
        zIndex: 2, 
        marginTop: '4px' 
      }}>
        {description}
      </Typography>
    </Paper>
  );
};

const NoDataItem = ({ title, buttonColor, buttonText, backgroundColor, borderColor, onClick }) => {
  return (
    <Paper 
      elevation={3} 
      style={{ 
        padding: '8px', 
        borderRadius: '12px', 
        position: 'relative', 
        margin: '16px 0', 
        border: `1px solid ${borderColor}`,
        backgroundColor: backgroundColor,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        height: '110px',
        width: '100%'
      }}
    >
      <img src={nodatafoundImage} alt="No Data Found" style={{ width: '100px', marginLeft: '16px', marginRight: '16px' }} />
      <Typography variant="h5" component="div" gutterBottom style={{ 
        fontSize: '18px', 
        fontWeight: '700', 
        color: '#212121', 
        zIndex: 0, 
        textAlign: 'left' 
      }}>
        No {title} Found
      </Typography>
      <Button 
        variant="contained" 
        style={{ 
          position: 'absolute', 
          top: '-14px', 
          right: '24px', 
          backgroundColor: buttonColor,
          borderRadius: '50px',
          padding: '4px 16px',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 3
        }}
        onClick={onClick}
      >
        <Typography style={{ 
          fontSize: '16px', 
          lineHeight: '20px', 
          color: '#fff', 
          textAlign: 'left' 
        }}>
          {buttonText}
        </Typography>
      </Button>
    </Paper>
  );
};

const DashboardCards = ({ user }) => {
  const [machineCount, setMachineCount] = useState(0);
  const [issuedCount, setIssuedCount] = useState(0);
  const [purchasedCount, setPurchasedCount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch machine reservations
        const machines = await BookMachineAPIs.ReservedMachineGet();
        const approvedMachines = machines.filter(machine => 
          (machine.approved === 'Approved' || machine.approved === 'AutoApproved') &&
          machine.reserved_by === user.id
        );
        setMachineCount(approvedMachines.length);

        // Fetch issued inventory
        const issuedInventory = await IssueInventoryAPIs.IssuedInventoryGet();
        const approvedIssuedInventory = issuedInventory.filter(item => 
          item.pickup_ === 'Yes' && 
          item.issued_by === user.id
        );
        setIssuedCount(approvedIssuedInventory.length);

        // Fetch purchased inventory
        const purchasedInventory = await PurchaseInventoryAPIs.PurchasedInventoryGet();
        const approvedPurchasedInventory = purchasedInventory.filter(item => 
          item.pickup_ === 'Yes' && 
          item.purchased_by === user.id
        );
        setPurchasedCount(approvedPurchasedInventory.length);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, [user]);

  const handleExploreNowClick = (tabIndex) => {
    navigate('/records', { state: { activeTab: tabIndex } });  // Navigate to the /records route
  };

  const handleNoDataExploreClick = (tabIndex) => {
    // For machineCount, redirect to machine tab (tabIndex 0).
    // For issuedCount and purchasedCount, redirect to inventory tab (tabIndex 1).
    const categoryMapping = {
      0: 0, // Machines Booked -> Machine tab
      1: 2, // Inventory Issued -> Inventory tab
      2: 2, // Inventory Purchased -> Inventory tab
    };

    navigate('/explore', { state: { activeCategory: categoryMapping[tabIndex] } });
  };

  return (
    <Box style={{ flex: 1, width: '95%' }}>
      {machineCount > 0 ? (
        <CardItem 
          number={machineCount} 
          title="Machines Booked" 
          description="Based on approved bookings" 
          buttonColor="#978fed" 
          buttonText="Explore Now"
          backgroundColor="#e7e5f9"
          borderColor="#978fed"
          onClick={() => handleExploreNowClick(0)}  // Bookable tab (0)
        />
      ) : (
        <NoDataItem 
          title="Machines Booked" 
          buttonColor="#978fed" 
          buttonText="Explore Now"
          backgroundColor="#e7e5f9"
          borderColor="#978fed"
          onClick={() => handleNoDataExploreClick(0)}  // Redirect to /exploretl with activeCategory 0 (Machine tab)
        />
      )}
      {issuedCount > 0 ? (
        <CardItem 
          number={issuedCount} 
          title="Inventory Issued" 
          description="Based on approved pickups" 
          buttonColor="#ca628d" 
          buttonText="Explore Now"
          backgroundColor="#f6e7ed"
          borderColor="#ca628d"
          onClick={() => handleExploreNowClick(1)}  // Issuable tab (1)
        />
      ) : (
        <NoDataItem 
          title="Inventory Issued" 
          buttonColor="#ca628d" 
          buttonText="Explore Now"
          backgroundColor="#f6e7ed"
          borderColor="#ca628d"
          onClick={() => handleNoDataExploreClick(1)}  // Redirect to /exploretl with activeCategory 1 (Inventory tab)
        />
      )}
      {purchasedCount > 0 ? (
        <CardItem 
          number={purchasedCount} 
          title="Inventory Purchased" 
          description="Based on approved pickups" 
          buttonColor="#6eba65" 
          buttonText="Explore Now"
          backgroundColor="#dfeddd"
          borderColor="#6eba65"
          onClick={() => handleExploreNowClick(2)}  // Purchasable tab (2)
        />
      ) : (
        <NoDataItem 
          title="Inventory Purchased" 
          buttonColor="#6eba65" 
          buttonText="Explore Now"
          backgroundColor="#dfeddd"
          borderColor="#6eba65"
          onClick={() => handleNoDataExploreClick(2)}  // Redirect to /exploretl with activeCategory 1 (Inventory tab)
        />
      )}
    </Box>
  );
};

export default DashboardCards;

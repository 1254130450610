import { axiosApiService } from "./axios";

const MachineDetailAPI = {
  MachineDetailGet: async function(access,id) {
  // MachineDetailGet: async function(id) {
    try {
      const response = await axiosApiService.get(`/admin_machine_detail/${id}/`);

      if (!response || !response.data) {
        throw new Error('Invalid response from server');
      }

      const machineDetail = {
        id: response.data.id,
        name: response.data.name,
        availability: response.data.availability,
        status: response.data.status,
        category: response.data.category,
        location: response.data.location,
        upc: response.data.upc,
        manufacturer: response.data.manufacturer,
        description: response.data.description,
        availability1: response.data.availability1,
        status1: response.data.status1,
        image: response.data.image,
        instances: response.data.instances,
        supervised: response.data.supervised,
        purchaseCost: response.data.purchase_cost, // Using camelCase for consistency
      };

      return machineDetail;
    } catch (error) {
      console.error('Error fetching machine detail:', error);
      throw error; // Rethrow the error to be handled by the calling code
    }
  }
};

export default MachineDetailAPI;


// import { axiosApiService } from "./axios";

// const MachineDetailAPI = {
//  MachineDetailGet: async function(id) {
//     try {
//       const response = await axiosApiService.get(`/admin_machine_detail/${id}/`, {
//         // headers: {
//         //   Authorization: `Bearer ${accessToken}`
//         // }
//       });
//       console.log(response);
//       // Map the response data to a more usable format
//       const machineDetail = {
//         id: response.data.id,
//         name: response.data.name,
//         availability: response.data.availability,
//         status: response.data.status,
//         category: response.data.category,
//         location: response.data.location,
//         upc: response.data.upc,
//         manufacturer: response.data.manufacturer,
//         description: response.data.description,
//         availability1: response.data.availability1,
//         status1: response.data.status1,
//         image: response.data.image,
//         instances: response.data.instances,
//         supervised: response.data.supervised,
//         purchaseCost: response.data.purchase_cost,
//       };
//       return machineDetail;
//     } catch (error) {
//       console.error('Error fetching machine detail:', error);
//       throw error; // Rethrow the error to be handled by the calling code
//     }
//  }
// };

// export default MachineDetailAPI;

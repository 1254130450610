import React, { useState, useEffect } from "react";
import { Card, CircularProgress, colors } from "@mui/material";
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Box } from "@mui/material";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import Tooltip from '@mui/material/Tooltip';
import MachineDetailAPI from "utilities/api/StudentMachineDetail";
import { useSelector } from 'react-redux';
import { Padding } from "@mui/icons-material";
import ImageBaseURL from "utilities/api/axios";

const CardListViewComponent = ({ item, setOpenDialog, searchQuery }) => {
    const { access } = useSelector((state) => state.user);
    const [machineDetails, setMachineDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const machineId = item.id;

    useEffect(() => {
        const fetchMachineDetails = async () => {
            setLoading(true);
            try {
                const machineDetails = await MachineDetailAPI.MachineDetailGet(access, machineId);
                setMachineDetails(machineDetails);
            } catch (error) {
                setError(error.message || 'Failed to fetch machine details');
            } finally {
                setLoading(false);
            }
        };

        if (machineId) {
            fetchMachineDetails();
        }
    }, [machineId, access]);

    const handleOpenDialog = () => {
        setOpenDialog(true);
    };

    const filterData = (details) => {
        const query = searchQuery.toLowerCase();
        const { name, category, location, availability } = details;

        return (
            (name && name.toLowerCase().includes(query)) ||
            (category && category.toLowerCase().includes(query)) ||
            (location && location.toLowerCase().includes(query)) ||
            (availability && availability.toLowerCase().includes(query))
        );
    };

    const filtered = machineDetails && filterData(machineDetails);

    const cardStyles = {
        cardContainer: {
            display: 'flex',
            width: '100%',
            margin: '5px 10px',
            background: 'var(--s-secondary-50, #ffffff30)',
            border: '1px solid var(--s-secondary-400, #edf1ff)',
            borderRadius: '1rem',
            alignItems: 'center',
        },
        media: {
            height: '72px',
            width: '120px',
            margin: '10px',
            marginRight: '5%',
            border: '1px solid var(--s-primary-source, #273F77)',
            borderRadius: '.5rem',
        },
        cardContent: {
            color: '#777777',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            width: '65%',
        },

        infoMarker : {
            // fontSize: '16px',
            fontWeight: '550',
        },

        // infoStyles : {
        //     color: '#212121',
        //     fontFamily: 'Inter',
        //     fontSize: '15px',
        //     fontWeight: '350',
        //     lineHeight: '1.5',
        // },

        nameBox: {
            width: '30%',
            paddingRight: '5px',
        },
        detailsBox: {
            width: '80%',
            paddingLeft: '10px',
        },
        detailText: {
            // color: '#212121',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontWeight: '400',
            lineHeight: '1.5',
        },
        description: {
            // color: '#212121',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontWeight: '400',
            lineHeight: '1.5',
            marginTop: '10px',
        },
        cardActions: {
            justifyContent: 'flex-end',
            paddingLeft: '5%',
        },
        button: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '0.5rem',
            padding: '.5rem',
            border: '1px solid var(--s-primary-source, #273F77)',
            letterSpacing: '0rem',
            color: '#273F77',
            fontWeight: '550',
            '&:hover': {
                backgroundColor: '#E79A2B',
            },
        },
    };

    // const baseURL = 'https://staging.misadmin.mapit.ai/';    

    return (
        <>
            {filtered && (  
                <Card sx={cardStyles.cardContainer}>
                {loading && <CircularProgress />} {/* Display loading indicator */}
                {!loading && !error && machineDetails && (
                    <>
                        <CardMedia
                            sx={cardStyles.media}
                            image={machineDetails.image ? `${ImageBaseURL.defaults.baseURL}${machineDetails.image}` : "./machine.png"}
                            title="Machine"
                        />
                        <CardContent sx={cardStyles.cardContent}>
                            <Box sx={cardStyles.nameBox}>
                                <Typography gutterBottom variant="h5" component="div" sx={{color:'#3a77a3', fontFamily: 'Inter', fontSize: '30px' }}>
                                    {`${item.name}`}
                                </Typography>
                            </Box>
                            <Box sx={cardStyles.detailsBox}>
                                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '80%' }}>
                                    <Typography variant="body1" sx={cardStyles.infoStyles}>
                                        <span style={cardStyles.infoMarker}>Availability:</span> {machineDetails.availability || 'N/A'}
                                    </Typography>
                                    <Typography variant="body1" sx={cardStyles.infoStyles}>
                                        <span style={cardStyles.infoMarker}>Location:</span> {machineDetails.location || 'N/A'}
                                    </Typography>
                                    <Typography variant="body1" sx={cardStyles.infoStyles}>
                                        <span style={cardStyles.infoMarker}>Category:</span> {machineDetails.category || 'N/A'}
                                    </Typography>
                                </Box>
                                <Typography variant="body1" sx={cardStyles.infoStyles}>
                                    <span style={cardStyles.infoMarker}>Description:</span> {machineDetails.description || 'N/A'}
                                </Typography>
                            </Box>
                        </CardContent>
                        <CardActions sx={cardStyles.cardActions}>
                            <Tooltip title="Feature under progress">
                                <CalendarMonthIcon sx={{ color: '#273F77', paddingBottom: '6px', marginRight:'4px', }} />
                            </Tooltip>
                            <Button onClick={handleOpenDialog} size="small" sx={cardStyles.button}>
                                Book Machine
                            </Button>
                        </CardActions>
                    </>
                )}
            </Card>
            )}
        </>
    )
}

export default CardListViewComponent;







// import React, { useState, useEffect } from "react";
// import { Card } from "@mui/material";
// import CardActions from '@mui/material/CardActions';
// import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
// import { Box } from "@mui/material";
// import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
// import Tooltip from '@mui/material/Tooltip';
// import MachineDetailAPI from "utilities/api/StudentMachineDetail";
// // import { useUser } from '../../../UserContext'; // Adjust the import path as necessary
// import { useSelector } from 'react-redux';



// const CardListViewComponent = ({ item, setOpenDialog }) => {
//     // const { accessToken } = useUser(); // Assuming user data is stored as userData in the context

//     const {access, user} = useSelector((state) => state.user);

//     console.log('item',item);



//     const machineId = item.id;
//     const [machineDetails, setMachineDetails] = useState(null);
//     const [loading, setLoading] = useState(false);
//     const [error, setError] = useState(null);

    
//     useEffect(() => {
//         const fetchMachineDetails = async () => {
//             setLoading(true);
//             try {
//                 const machineDetails = await MachineDetailAPI.MachineDetailGet(access, machineId);
//                 setMachineDetails(machineDetails);
//             } catch (error) {
//                 setError(error.message || 'Failed to fetch machine details');
//             } finally {
//                 setLoading(false);
//             }
//         };

//         if (machineId) {
//             fetchMachineDetails();
//         }

//     }, [machineId]);

//     const handleOpenDialog = () => {
//         setOpenDialog(true);
//     };
    
//     // console.log('1111',machineDetails);
    
    
//     return (
//         <Card sx={{ display: 'flex', width: '97%', background: 'var(--s-secondary-50, #F2F4F2)', border: '1px solid var(--s-secondary-400, #919F93)', borderRadius: '1rem', alignItems: 'center' }}>
//             <CardMedia
//                 sx={{ height: '72px', width: '120px', margin: '10px', border: '1px solid var(--s-primary-source, #273F77)', borderRadius: '.5rem' }}
//                 image="./machine.png"
//                 title="Machine"
//             />
//             <CardContent sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '70%' }}>
//                 <Box sx={{ width: '30%', paddingRight: '5px' }}> {/* Separate column for the name */}
//                     <Typography gutterBottom variant="h5" component="div" sx={{color:'#3a77a3', fontFamily: 'Inter', fontSize: '30px' }}>
//                         {`${item.name}`}
//                     </Typography>
//                 </Box>
//                 <Box sx={{ width: '80%', paddingLeft: '10px' }}> {/* Additional details column */}
//                     {/* Availability, Location, Category on the same line */}
//                     <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '80%' }}>
//                         <Typography variant="body1" sx={{ color: '#212121', fontFamily: 'Inter', fontSize: '12px', fontWeight: '400', lineHeight: '1.5' }}>
//                             <b>Availability</b>: {`${machineDetails?.stock_available}`}
//                         </Typography>
//                         <Typography variant="body1" sx={{ color: '#212121', fontFamily: 'Inter', fontSize: '12px', fontWeight: '400', lineHeight: '1.5' }}>
//                             <b>Location</b>: {machineDetails?.location || 'N/A'}
//                         </Typography>
//                         <Typography variant="body1" sx={{ color: '#212121', fontFamily: 'Inter', fontSize: '12px', fontWeight: '400', lineHeight: '1.5' }}>
//                             <b>Category</b>: {machineDetails?.category || 'N/A'}
//                         </Typography>
//                     </Box>
//                     {/* Description on a separate line */}
//                     <Typography variant="body1" sx={{ color: '#212121', fontFamily: 'Inter', fontSize: '12px', fontWeight: '400', lineHeight: '1.5', marginTop: '10px' }}>
//                         <b>Description</b>: {machineDetails?.description || 'N/A'}
//                     </Typography>
//                 </Box>
//             </CardContent>

//             <CardActions sx={{ justifyContent: 'flex-end',  paddingLeft: '5%' }}>
//                 <Tooltip title="Feature under progress">
//                     <CalendarMonthIcon sx={{ color: '#273F77', paddingBottom: '6px' }} />
//                 </Tooltip>
//                 <Button onClick={handleOpenDialog} size="small" sx={{
//                     display: 'flex',
//                     justifyContent: 'center',
//                     alignItems: 'center',
//                     borderRadius: '0.5rem',
//                     border: '1px solid var(--s-primary-source, #273F77)',
//                     letterSpacing: '0rem',
//                     color: '#273F77',
//                     '&:hover': {
//                         backgroundColor: '#E79A2B',
//                     }
//                 }}>
//                     <b>Book Machine</b>
//                 </Button>
//             </CardActions>
//         </Card>
//     )
// }
// export default CardListViewComponent;

import React, { useState, useEffect, useMemo } from 'react';
import { TableContainer, Paper, Box, FormControl, InputLabel, Select, MenuItem, TextField, InputAdornment, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CustomPagination from '../Pagination';
import PurchaseInventoryAPIs from 'utilities/api/StudentPurchaseInventory';
import PurchasableHeader from './PurchasableHeader';
import PurchasableListItem from './PurchasableListItem';
import nodatafoundImage from '../../utilities/dummy_assets/nodatafound.jpg'; // Import the No Data Found image

const PurchasableTab = ({ user }) => {
    const [selectedItem, setSelectedItem] = useState('');
    const [selectedProject, setSelectedProject] = useState('');
    const [selectedAmount, setSelectedAmount] = useState('');
    const [selectedQuantity, setSelectedQuantity] = useState('');
    const [selectedPickup, setSelectedPickup] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const productsPerPage = 10;
    const [machineData, setMachineData] = useState([]);
    const [itemNames, setItemNames] = useState([]);
    const [projectNames, setProjectNames] = useState([]);
    const [amounts, setAmounts] = useState([]);
    const [quantities, setQuantities] = useState([]);
    const [pickups, setPickups] = useState([]);
    const [refreshData, setRefreshData] = useState(false); // New state to trigger re-fetch

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await PurchaseInventoryAPIs.PurchasedInventoryGet();
                const userData = response.reverse().filter(item => item.purchased_by === user?.id);
                setMachineData(userData);
                const items = [...new Set(userData.map(item => item.name))];
                const projects = [...new Set(userData.map(item => item.project_name))];
                const amounts = [...new Set(userData.map(item => item.purchase_amount))];
                const quantities = [...new Set(userData.map(item => item.quantity))];
                const pickups = [...new Set(userData.map(item => item.pickup_))];
                setItemNames(items);
                setProjectNames(projects);
                setAmounts(amounts);
                setQuantities(quantities);
                setPickups(pickups);
            } catch (error) {
                console.error('Error fetching machine list:', error);
            }
        };

        fetchData();
    }, [user, refreshData]); // Adding refreshData to dependencies to refetch when updated

    const handleItemChange = (event) => {
        setSelectedItem(event.target.value);
    };

    const handleProjectChange = (event) => {
        setSelectedProject(event.target.value);
    };

    const handleAmountChange = (event) => {
        setSelectedAmount(event.target.value);
    };

    const handleQuantityChange = (event) => {
        setSelectedQuantity(event.target.value);
    };

    const handlePickupChange = (event) => {
        setSelectedPickup(event.target.value);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredData = useMemo(() => {
        return machineData.filter(item => {
            return (
                (selectedItem === '' || item.name === selectedItem) &&
                (selectedProject === '' || item.project_name === selectedProject) &&
                (selectedAmount === '' || item.purchase_amount === selectedAmount) &&
                (selectedQuantity === '' || item.quantity === selectedQuantity) &&
                (selectedPickup === '' || item.pickup_ === selectedPickup) &&
                (searchQuery === '' || item.project_name.toLowerCase().includes(searchQuery.toLowerCase()))
            );
        });
    }, [selectedItem, selectedProject, selectedAmount, selectedQuantity, selectedPickup, searchQuery, machineData]);

    const totalItems = filteredData.length;
    const totalPages = Math.ceil(totalItems / productsPerPage);
    const currentProducts = filteredData.slice((currentPage - 1) * productsPerPage, currentPage * productsPerPage);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleDelete = (id) => {
        setMachineData((prevProducts) => prevProducts.filter((product) => product.id !== id));
    };

    const triggerDataRefresh = () => {
        setRefreshData(prev => !prev); // Toggle the state to trigger re-fetch
    };

    return (
        <Box>
            {machineData.length > 0 ? (
                <>
                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, width: '100%' }}>
                        <TextField
                            label="Search Projects"
                            value={searchQuery}
                            onChange={handleSearchChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{ m: 1, minWidth: 250 }}
                        />
                        <FormControl sx={{ m: 1, minWidth: 250 }}>
                            <InputLabel id="item-select-label">Select Item</InputLabel>
                            <Select
                                labelId="item-select-label"
                                value={selectedItem}
                                onChange={handleItemChange}
                                label="Select Item"
                            >
                                <MenuItem value="">
                                    <em>All</em>
                                </MenuItem>
                                {itemNames.map((name) => (
                                    <MenuItem key={name} value={name}>
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl sx={{ m: 1, minWidth: 250 }}>
                            <InputLabel id="project-select-label">Select Project</InputLabel>
                            <Select
                                labelId="project-select-label"
                                value={selectedProject}
                                onChange={handleProjectChange}
                                label="Select Project"
                            >
                                <MenuItem value="">
                                    <em>All</em>
                                </MenuItem>
                                {projectNames.map((name) => (
                                    <MenuItem key={name} value={name}>
                                        {name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl sx={{ m: 1, minWidth: 250 }}>
                            <InputLabel id="pickup-select-label">Select Pickup Status</InputLabel>
                            <Select
                                labelId="pickup-select-label"
                                value={selectedPickup}
                                onChange={handlePickupChange}
                                label="Select Pickup Status"
                            >
                                <MenuItem value="">
                                    <em>All</em>
                                </MenuItem>
                                {pickups.map((pickup) => (
                                    <MenuItem key={pickup} value={pickup}>
                                        {pickup}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <TableContainer component={Paper} elevation={0} sx={{ overflowY: 'auto', height: '100%', border: '1px solid black', borderRadius: '20px' }}>
                        <PurchasableHeader />
                        <Box className="inventoryContainer" sx={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                            {currentProducts.map((item) => (
                                <PurchasableListItem key={item.id} product={item} onDelete={handleDelete} onDialogClose={triggerDataRefresh} />
                            ))}
                        </Box>
                        {totalPages > 1 && (
                            <CustomPagination count={totalPages} page={currentPage} onChange={handlePageChange} />
                        )}
                    </TableContainer>
                </>
            ) : (
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flexGrow={1} padding="40px">
                    <img src={nodatafoundImage} alt="No Data Found" style={{ width: '300px', marginBottom: '16px' }} />
                    <Typography variant="h6" color="textSecondary">
                        No data available
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default PurchasableTab;

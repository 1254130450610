import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { Typography, Box, TextField, Button, DialogActions, MenuItem, IconButton, Dialog, DialogContent } from '@mui/material';
import { DateRange as DateRangeIcon, AccessTime as AccessTimeIcon, Assignment as AssignmentIcon, CreateOutlined as CreateOutlinedIcon, EditNoteOutlined as EditNoteOutlinedIcon } from '@mui/icons-material';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import StorageIcon from '@mui/icons-material/Storage';
import InventoryIcon from '@mui/icons-material/Inventory';
import AddIcon from '@mui/icons-material/Add';
import * as Yup from 'yup';
import { toast } from 'react-toastify'; 

import IssueInventoryAPIs from 'utilities/api/StudentIssueInventory';
import ProjectAPIs from 'utilities/api/StudentProjectAPI';
import InventoryDetailAPI from 'utilities/api/StudentInventoryDetail';
import AddProjectForm from 'components/ExploreTL/machine/AddProjectForm';

const IssueInventory = ({ user, access, machineId, onClose }) => {
  const [bookingError, setBookingError] = useState(null);
  const [projects, setProjects] = useState([]);
  const [inventoryDetails, setInventoryDetails] = useState(null);
  const [isAddProjectDialogOpen, setAddProjectDialogOpen] = useState(false);

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    try {
      const projectData = await ProjectAPIs.ProjectsGet();
      const filteredProjects = projectData.filter(project => project.student === user.id || project.teammates.includes(user.id));
      setProjects(filteredProjects);
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };

  const fetchInventoryDetails = async () => {
    if (machineId) {
      try {
        const details = await InventoryDetailAPI.InventoryDetailGet(access, machineId);
        setInventoryDetails(details);
      } catch (error) {
        console.error('Error fetching inventory details:', error);
      }
    }
  };

  useEffect(() => {
    fetchInventoryDetails();
  }, [machineId, access]);

  const validationSchema = Yup.object().shape({
    issued_from: Yup.date().required('Reserved From date is required'),
    issued_till: Yup.date().required('Reserved To date is required'),
    issued_by: Yup.string().required('Reserved By field is required'),
    quantity: Yup.number()
      .required('Number of Quantity is required')
      .max(inventoryDetails?.stock_available || Infinity, `Quantity cannot exceed available stock (${inventoryDetails?.stock_available || 0})`),
    pickup: Yup.number().required('Pickup number is required'),
    inventory: Yup.number().required('Machine ID is required'),
    type_of_project: Yup.string().required('Type of project is required'),
    project: Yup.string().required('Project Title is required'),
    purpose: Yup.string().required('Project Details is required'),
  });

  const formik = useFormik({
    initialValues: {
        issued_by: user?.id || '',
        inventory: machineId || '',
        issued_from: '',
        issued_till: '',
        quantity: '',
        pickup: 1, // Set a default condition to be 'No'
        type_of_project: '',
        project: '',
        purpose: '',
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, { resetForm }) => {
        console.log('Submitting form with values:', values);
        try {
            // Make the API call to issue the inventory
            await IssueInventoryAPIs.IssueInventory(access, values);
            toast.success('Inventory issued successfully!');
            console.log('Form submitted successfully');

            // Calculate the new stock available
            const newStockAvailable = inventoryDetails.stock_available - values.quantity;

            // Update the stock available
            await InventoryDetailAPI.InventoryUpdate(access, machineId, { stock_available: newStockAvailable });
            toast.success('Stock updated successfully!');
            console.log('Stock updated successfully');

            // Fetch the updated inventory details
            await fetchInventoryDetails();

            onClose();
            resetForm(); // Optionally reset the form here if needed
        } catch (error) {
            toast.error('Error submitting form. Please try again.');
            console.error('Error submitting form:', error.message);
        }
    },
  });
  const handleProjectChange = (event) => {
    const selectedProject = projects.find(project => project.id === event.target.value);
    if (selectedProject) {
      formik.setFieldValue('project', selectedProject.id);
      formik.setFieldValue('type_of_project', selectedProject.type); // Auto-fill the project type
      formik.setFieldValue('purpose', selectedProject.description); // Auto-fill the project details
    }
  };

  const handleAddProject = async () => {
    await fetchProjects();
    setAddProjectDialogOpen(false);
  };

  const getCurrentDateIST = () => {
    const today = new Date();
    const utcDate = today.getTime() + today.getTimezoneOffset() * 6000;
    const istDate = new Date(utcDate + 3600000 * 5.5);
    return istDate.toISOString().split('T')[0]; // Format to 'YYYY-MM-DD'
  };

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', mb: 2 }}>
          <PersonAddAltIcon sx={{ paddingRight: '10px', color: 'rgb(92,92,92)' }} />
          <Typography variant="h6" sx={{ color: 'rgb(92,92,92)', fontFamily: 'Inter', fontSize: '16px', fontWeight: '600' }}>
            {user?.username || ''}
          </Typography>
        </Box>

        {inventoryDetails && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', mb: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <StorageIcon sx={{ mr: 1 }} />
                <Typography variant="body1" color="textSecondary">
                  Stock Available:
                </Typography>
              </Box>
              <Typography variant="body1" sx={{ ml: 2 }}>
                {inventoryDetails.stock_available} {inventoryDetails.stock_unit}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <InventoryIcon sx={{ mr: 1 }} />
                <Typography variant="body1" color="textSecondary">
                  Stock Total:
                </Typography>
              </Box>
              <Typography variant="body1" sx={{ ml: 2 }}>
                {inventoryDetails.stock_total} {inventoryDetails.stock_unit}
              </Typography>
            </Box>
          </Box>
        )}

        <Box sx={{ display: 'flex', flexDirection: 'column', mb: 2 }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <DateRangeIcon sx={{ color: 'rgb(92,92,92)' }} />
            <TextField
              type="date"
              name="issued_from"
              label="Issued from"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              inputProps={{ min: getCurrentDateIST() }}
              fullWidth
              value={formik.values.issued_from}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.issued_from && Boolean(formik.errors.issued_from)}
              helperText={formik.touched.issued_from && formik.errors.issued_from}
            />
            <Typography variant="body1" sx={{ color: '#212121', fontFamily: 'Inter', fontWeight: '450', lineHeight: '1.5' }}>
              To
            </Typography>
            <TextField
              type="date"
              name="issued_till"
              label="Issued till"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              inputProps={{ min: getCurrentDateIST() }}
              fullWidth
              value={formik.values.issued_till}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.issued_till && Boolean(formik.errors.issued_till)}
              helperText={formik.touched.issued_till && formik.errors.issued_till}
            />
          </Box>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', mb: 2 }}>
          <AccessTimeIcon sx={{ color: 'rgb(92,92,92)' }} />
          <TextField
            type="number"
            name="quantity"
            label="Enter Quantity"
            variant="outlined"
            sx={{ width: '50%' }}
            value={formik.values.quantity}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.quantity && Boolean(formik.errors.quantity)}
            helperText={formik.touched.quantity && formik.errors.quantity}
          />
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', mb: 2 }}>
          <CreateOutlinedIcon sx={{ color: 'rgb(92,92,92)' }} />
          <TextField
            name="project"
            label="Project Title"
            variant="outlined"
            select
            fullWidth
            value={formik.values.project}
            onChange={handleProjectChange}
            onBlur={formik.handleBlur}
            error={formik.touched.project && Boolean(formik.errors.project)}
            helperText={formik.touched.project && formik.errors.project}
          >
            <MenuItem value="">Select Project</MenuItem>
            {projects.map(project => (
              <MenuItem key={project.id} value={project.id}>
                {project.title}
              </MenuItem>
            ))}
          </TextField>
          <IconButton onClick={() => setAddProjectDialogOpen(true)} color="primary">
            <AddIcon />
          </IconButton>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', mb: 2 }}>
          <AssignmentIcon sx={{ color: 'rgb(92,92,92)' }} />
          <TextField
            name="type_of_project"
            label="Type of Project"
            variant="outlined"
            sx={{ width: '50%' }}
            value={formik.values.type_of_project}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.type_of_project && Boolean(formik.errors.type_of_project)}
            helperText={formik.touched.type_of_project && formik.errors.type_of_project}
            disabled
          />
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', mb: 2 }}>
          <EditNoteOutlinedIcon sx={{ color: 'rgb(92,92,92)' }} />
          <TextField
            type="text"
            name="purpose"
            label="Add Purpose"
            variant="outlined"
            fullWidth
            InputProps={{ sx: { width: '100%' }}}
            value={formik.values.purpose}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.purpose && Boolean(formik.errors.purpose)}
            helperText={formik.touched.purpose && formik.errors.purpose}
            disabled
          />
        </Box>

        <DialogActions>
          <Button
            onClick={onClose}
            color="primary"
            sx={{
              width: '80px',
              height: '35px',
              color: 'black',
              border: '1px solid rgb(218,218,218)',
              borderRadius: '10px',
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            color="primary"
            sx={{
              width: '80px',
              height: '35px',
              color: 'white',
              backgroundColor: 'rgb(231,154,43)',
              border: '1px solid black',
              borderRadius: '10px',
            }}
          >
            Issue
          </Button>
        </DialogActions>
        {bookingError && (
          <Typography color="error" sx={{ marginLeft: '35px', marginBottom: '10px' }}>
            {bookingError}
          </Typography>
        )}
      </form>

      <Dialog open={isAddProjectDialogOpen} onClose={() => setAddProjectDialogOpen(false)} fullWidth maxWidth="sm">
        <DialogContent>
          <AddProjectForm onClose={() => setAddProjectDialogOpen(false)} onAddProject={handleAddProject} />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default IssueInventory;

import { axiosApiService } from "./axios";

const TinkerLabListAPIs = {
    TinkerLabsListGet: async function(){
        try {
            const response = await axiosApiService.get(`/auth/tinkerers_lab_list/`);
            return response.data;
        } catch (error) {
            console.error('Error fetching TL data:', error.response ? error.response.data : error.message);
            throw error;
        }
    },

    NewTinkerLabsAdd: async function(values){
        try {
            const response = await axiosApiService.post(`/auth/tinkerers_lab_list/`, values);
            return response.data;
        } catch (error) {
            console.error('Error posting TL data:', error.response ? error.response.data : error.message);
            throw error;
        }
    },
}

export default TinkerLabListAPIs;
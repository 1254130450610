import React from "react";
import { useFormik } from "formik";
import axios from "axios";
import Avatar from "@mui/material/Avatar";
import { alpha, styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from '@mui/material/Stack';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import { MenuItem } from '@mui/material';
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import * as Yup from 'yup';

import { useNavigate } from "react-router-dom";
import { useUser } from "../../UserContext";

import Auth_API from 'utilities/api/auth_api';

const TextFieldStyle = styled(InputBase)(({ theme }) => ({
    'label + &': {
        marginTop: theme.spacing(3),
    },
    '& .MuiInputBase-input': {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.mode === 'light' ? '#F3F6F9' : '#1A2027',
        border: '1px solid',
        borderColor: theme.palette.mode === 'light' ? '#E0E3E7' : '#2D3843',
        fontSize: 16,
        width: 'auto',
        padding: '10px 12px',
        transition: theme.transitions.create([
            'border-color',
            'background-color',
            'box-shadow',
        ]),
        // Use the system font instead of the default Roboto font.
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:focus': {
            boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
            borderColor: theme.palette.primary.main,
        },
    },
}));

const initialValues = {
    email: '',
    password: '',
};

const validationSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Required'),
    password: Yup.string().required('Required'),
});

export const LoginForm = () => {
    const navigate = useNavigate();
    const { setUser, setToken } = useUser();

    const formik = useFormik({
        initialValues,
        validationSchema,
        onSubmit: async (values, { setSubmitting }) => {
            try {
                const response = await Auth_API.studentLogin(values);
                console.log("RES:", response);
                setUser(response.user); // Set user data in the context
                setToken(response.access); // Set access token in the context
                navigate("/dashboard");
            } catch (error) {
                console.error("Error logging in:", error);
                // setTimeout(() => {
                //     console.log(error)
                //     alert(JSON.stringify(error, null, 2));
                //     setSubmitting(false);
                // }, 400);
            } finally {
                setSubmitting(false);
            }
        }
        // onSubmit: async (values, { setSubmitting }) => {
        //     try {
        //         console.log(values);
        //         const response = await Auth_APIs.studentLogin(values);
        //         console.log(response);
        //         navigate('/dashboard');
        //     } catch (error) {
        //         console.error("Error logging in:", error);
        //         // Handle login error, e.g., displaying an error message
        //     } finally {
        //         setSubmitting(false);
        //     }
        // }
    });

    return (
        <div className='containersingupform'>
            <div className='top-right'>
                <Link style={{ textDecoration: "None" }} href="/signup">
                    <button style={{ color: "#273F77" }} className='LoginButton'>Register</button>
                </Link>
                <Link style={{ textDecoration: "None" }} href="/login">
                    <button style={{ color: "#FFF" }} className='RegisterButton'>Login</button>
                </Link>
            </div>
            <div className='Form2' style={{ position: "absolute", marginTop: "10%" }}>
                <Container maxWidth="sm">
                    <form onSubmit={formik.handleSubmit}>
                        <Stack spacing={2} direction="column">
                            <FormControl variant="standard">
                                <InputLabel shrink htmlFor="FormEmail2">
                                    Student Email Address
                                </InputLabel>
                                <TextFieldStyle
                                    name="email"
                                    placeholder="Enter Student Email"
                                    id="FormEmail2"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email ? formik.errors.email : ""}
                                    fullWidth
                                />
                            </FormControl>
                            <FormControl variant="standard">
                                <InputLabel shrink htmlFor="password">
                                    Password
                                </InputLabel>
                                <TextFieldStyle
                                    name="password"
                                    placeholder="Enter Password"
                                    id="FormPassword2"
                                    type="password"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                    helperText={formik.touched.password ? formik.errors.password : ""}
                                    fullWidth
                                />
                            </FormControl>
                            <Button
                                className='submitButton'
                                type="submit"
                                variant="contained"
                            >
                                Login
                            </Button>
                            <Typography align="center" variant="body2">
                                Don't have an account?{' '}
                                <Link href="/signup">
                                    Signup here
                                </Link>
                            </Typography>
                        </Stack>
                    </form>
                </Container>
            </div>
        </div>
    );
};

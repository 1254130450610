import React from 'react'
import {useSelector} from "react-redux"
import {Navigate, useLocation} from "react-router-dom"

const ProtectedRoute = ({children}) => {
    const user = useSelector((state) => state.user);
    //console.log('7',user.user.access);
    if(!user.access) {
        return <Navigate to="/login"/>
    }
 return children

};

export default ProtectedRoute;
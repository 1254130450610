import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './App'; // Adjust the import path as necessary
import { UserProvider } from './UserContext'; // Adjust the import path as necessary
import {store} from './redux/store';
import { Provider } from 'react-redux';
import './index.css';

if (process.env.NODE_ENV === 'production') {
    console.log = () => { }
    console.error = () => { }
    console.debug = () => { }
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
        <Provider store={store}>
            <React.StrictMode>
                <App />
            </React.StrictMode>
        </Provider>
    </>
);


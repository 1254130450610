import { axiosApiService } from "./axios";

const ReportIssueAPI = {
    ReportListGet: async function () {
        const response = await axiosApiService.get(`/auth/report_user_issue_list/`);
        return response.data;
    },
    ReportListPost: async function (values) {
        const { reportTitle, reportIssue, otherIssue, user } = values; // Destructure user from values
        const TIER_CHOICES = {
            'Bookings': 1,
            'Messaging': 2,
            'Workshops': 3,
            'Records': 4,
            'Login': 5,
            'Issues/Purchases': 6,
            'Web Application': 7,
            'Other': 8,
        };
        
        const tier = TIER_CHOICES[reportTitle];
        const formData = new FormData();
        formData.append("tier", tier);
        if (tier < 8) {
            formData.append(`sub_tier${tier}`, reportIssue);
        }
        formData.append("user", user.id); // Send the user ID or appropriate user property
        if (otherIssue !== "") {
            formData.append("description", otherIssue);
        }
        console.log(formData, " formData");

        const response = await axiosApiService.post(`/auth/report_user_issue_list/`, formData, {
            headers: { "Content-Type": "application/x-www-form-urlencoded" },
        });
        return response.data;
    },
};

export default ReportIssueAPI;

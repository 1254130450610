import * as React from 'react';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import { Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import { isSameDay, isSameWeek } from 'date-fns';

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== 'isToday' && prop !== 'isCurrentWeek',
})(({ theme, isToday, isCurrentWeek }) => ({
  ...(isToday && {
    backgroundColor: 'orange',
    color: theme.palette.common.white,
    borderRadius: '50%', // Make it circular like in the image
    '&:hover': {
      backgroundColor: 'darkorange',
    },
  }),
  ...(isCurrentWeek && !isToday && {
    backgroundColor: 'lightblue',
    borderRadius: '16px', // Slight rounding to match the week highlight style
  }),
  // width: 36, // Adjust width to match the image size
  // height: 36, // Adjust height to match the image size
  fontSize: '0.875rem', // Adjust font size for a cleaner look
  // margin: '0 2px', // Adjust spacing between dates
}));

const DatePickerDialog = ({ open, selectedDate, handleDateChange }) => {
  return (
    <Paper
      elevation={3}
      style={{  borderRadius: '4px', margin: '16px' }} // Added margin around the Paper component
    >
      <StaticDatePicker
        displayStaticWrapperAs="desktop"
        value={selectedDate}
        onChange={(newDate) => handleDateChange(newDate)}
        renderDay={(day, selectedDate, pickersDayProps) => {
          const isToday = isSameDay(day, new Date());
          const isCurrentWeek = isSameWeek(day, new Date(), { weekStartsOn: 0 });

          return (
            <CustomPickersDay
              {...pickersDayProps}
              day={day}
              isToday={isToday}
              isCurrentWeek={isCurrentWeek}
            />
          );
        }}
        // componentsProps={{
        //   actionBar: {
        //     actions: ['cancel', 'accept'], // Only show cancel and accept buttons
        //   },
        // }}
      />
    </Paper>
  );
};

export default DatePickerDialog;

import React from 'react';
import { Box, Container, Grid } from '@mui/material';
import ItemsBorrowedTable from './GraphsAndTables/ItemsBorrowedTable';
import ProjectTypesChart from './GraphsAndTables/ProjectTypesChart';
import DashboardCards from './GraphsAndTables/DashboardCards';
import StatisticsCards from './GraphsAndTables/StatisticsCards';
import HistoryTables from './GraphsAndTables/HistoryTables'; // Import the new component
import AdminCardList from './GraphsAndTables/AdminCard';
import OngoingWorkshops from './GraphsAndTables/OngoingWorkshops';
import YourProjects from './GraphsAndTables/YourProjects';
import { useSelector } from 'react-redux';

const DashboardContent = () => {
    const { user } = useSelector((state) => state.user);

    return (
    <Container sx={{ width: '100%', '@media (min-width: 1200px)': { maxWidth: '1380px' } }}>
        <Box sx={{ p: 2 }}>
            <Grid container spacing={3}>
                <Grid item xs={4}>
                    <DashboardCards user={user}/>
                </Grid>
                <Grid item xs={4}>
                    <ItemsBorrowedTable user={user}/>
                </Grid>
                <Grid item xs={4}>
                    <ProjectTypesChart user={user}/>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                {/* <Grid item xs={6}>
                    <OngoingWorkshops user={user}/>
                </Grid>
                <Grid item xs={6}> */}
                <Grid item xs={12}>
                    <YourProjects user={user}/>
                </Grid>
            </Grid>
            {/* <Grid container spacing={3}>
                <Grid item xs={12}>
                    <StatisticsCards user={user}/>
                </Grid>
            </Grid> */}
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <HistoryTables user={user}/>
                </Grid>
            </Grid>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <AdminCardList user={user}/> 
                </Grid>
            </Grid>
        </Box>
    </Container>
    );
};

export default DashboardContent;

import React, { useState, useEffect } from 'react';
import { Button, AppBar, Toolbar, Typography, Select, MenuItem } from '@mui/material';
import { AccountCircle, CalendarToday as CalendarTodayIcon } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { updateTL } from '../redux/slices/userSlice';
import TinkerLabListAPIs from 'utilities/api/TinkerLabListAPIs';
import ImageBaseURL from 'utilities/api/axios';

const Header = () => {
  const { access, user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [tlList, setTlList] = useState([]);
  const size = '36px';

  const circularStyle = {
    borderRadius: '50%',
    width: size,
    height: size,
    minWidth: size,
    padding: 0,
    margin: "0.3%"
  };

  const calcircularStyle = {
    borderRadius: '50%',
    width: size, 
    height: size,
    minWidth: size, 
    padding: 0, 
    margin: "0.3%",
    marginInline: ".2rem"
  };

  const handleTLChange = (event) => {
    dispatch(updateTL({ TL: event.target.value }));
  };

  useEffect(() => {
    const fetchTLList = async () => {
      try {
        const data = await TinkerLabListAPIs.TinkerLabsListGet();
        setTlList(data);
      } catch (error) {
        console.error('Error fetching TL data:', error);
      }
    };

    if (user.is_org_admin) {
      fetchTLList();
    }
  }, [user]);

  const userAvatarUrl = user.profile_pic ? `${ImageBaseURL.defaults.baseURL}${user.profile_pic}` : '';

  return (
    <AppBar position="static" elevation={1} sx={{ width: '100%', background: 'var(--s-primary-50, #F2F3FC)' }}>
      <Toolbar>
        <Typography variant="h6" className="poweredBy" style={{ flexGrow: 1 }}>
          {user.is_org_admin ? (
            <Select
              value={user.TL_name}
              onChange={handleTLChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Select TL' }}
            >
              {tlList.map((tl) => (
                <MenuItem key={tl.id} value={tl.name}>
                  {tl.name}
                </MenuItem>
              ))}
            </Select>
          ) : (
            user.TL_name
          )}
        </Typography>
        {user.TL_name !== "Org-Admin" && (
          <Link to="/calendar">
            <Button variant="contained" style={calcircularStyle} color="primary">
              <CalendarTodayIcon />
            </Button>
          </Link>
        )}
        <Link to="/profile">
          <Button edge="end" variant="contained" style={circularStyle} color="primary">
            {userAvatarUrl ? (
              <img src={userAvatarUrl} alt="Profile" style={{ borderRadius: '50%', width: size, height: size }} />
            ) : (
              <AccountCircle />
            )}
          </Button>
        </Link>
      </Toolbar>
    </AppBar>
  );
};

export default Header;

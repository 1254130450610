import { axiosApiService } from "./axios";

const ProjectAPIs = {
    ProjectsGet: async function () {
        try {
            const response = await axiosApiService.get(`/project/`);
            console.log(response);
            return response.data; // Assuming the server returns the project data
        } catch (error) {
            console.error('Error fetching projects:', error);
            throw error;
        }
    },
    
    ProjectDetailGet: async function (id) {
        try {
            const response = await axiosApiService.get(`/project/${id}/`);
            console.log(response);
            return response.data; // Assuming the server returns the project detail data
        } catch (error) {
            console.error('Error fetching project detail:', error);
            throw error;
        }
    },

    ProjectCreate: async function (accessToken, values) {
        if (!accessToken) {
            throw new Error('Access token is missing.');
        }

        try {
            const response = await axiosApiService.post(`/project/`, values, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            });

            console.log('Response:', response.data);
            return response.data; // Assuming the server returns the data
        } catch (error) {
            console.error('Error creating project:', error);
            throw error;
        }
    },

    ProjectUpdate: async function (id, updateData) {
        try {
            const response = await axiosApiService.patch(`/project/${id}/`, updateData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            return response.data; // Assuming the server returns the updated project data
        } catch (error) {
            console.error('Error updating project:', error);
            throw error;
        }
    },

    ProjectDelete: async function (id) {
        try {
            const response = await axiosApiService.delete(`/project/${id}/`);
            return response.data; // Assuming the server returns a success message or the deleted project data
        } catch (error) {
            console.error('Error deleting project:', error);
            throw error;
        }
    }
};

export default ProjectAPIs;
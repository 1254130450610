import React, { useState, useEffect, useCallback } from 'react';
import { TableContainer, Paper, Box, FormControl, InputLabel, Select, MenuItem, TextField, InputAdornment, Typography } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CustomPagination from '../Pagination';
import IssueInventoryAPIs from 'utilities/api/StudentIssueInventory';
import IssuableHeader from './IssuableHeader';
import IssuableListItem from './IssuableListItem';
import nodatafoundImage from '../../utilities/dummy_assets/nodatafound.jpg';

const IssuableTab = ({ user }) => {
    const [selectedItem, setSelectedItem] = useState('');
    const [selectedProject, setSelectedProject] = useState('');
    const [selectedQuantity, setSelectedQuantity] = useState('');
    const [selectedPickupStatus, setSelectedPickupStatus] = useState('');
    const [selectedReturnedStatus, setSelectedReturnedStatus] = useState('');
    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const productsPerPage = 10;
    const [filteredData, setFilteredData] = useState([]);
    const [machineData, setMachineData] = useState([]);
    const [itemNames, setItemNames] = useState([]);
    const [projectNames, setProjectNames] = useState([]);
    const [quantities, setQuantities] = useState([]);
    const [pickupStatus, setPickupStatus] = useState([]);
    const [returnedStatus, setReturnedStatus] = useState([]);
    const [refreshData, setRefreshData] = useState(false); // New state to trigger re-fetch

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await IssueInventoryAPIs.IssuedInventoryGet();
                const userData = response.reverse().filter(item => item.issued_by === user?.id);
                setMachineData(userData);
                setFilteredData(userData);
                const items = [...new Set(userData.map(item => item.name))];
                const projects = [...new Set(userData.map(item => item.project_name))];
                const quantities = [...new Set(userData.map(item => item.quantity))];
                const pickups = [...new Set(userData.map(item => item.pickup_))];
                const returns = [...new Set(userData.map(item => item.returned_))];
                setItemNames(items);
                setProjectNames(projects);
                setQuantities(quantities);
                setPickupStatus(pickups);
                setReturnedStatus(returns);
            } catch (error) {
                console.error('Error fetching issued inventory:', error);
            }
        };

        fetchData();
    }, [user, refreshData]); // Adding refreshData to dependencies to refetch when updated

    const handleItemChange = (event) => {
        setSelectedItem(event.target.value);
    };

    const handleProjectChange = (event) => {
        setSelectedProject(event.target.value);
    };

    const handleQuantityChange = (event) => {
        setSelectedQuantity(event.target.value);
    };

    const handlePickupStatusChange = (event) => {
        setSelectedPickupStatus(event.target.value);
    };

    const handleReturnedStatusChange = (event) => {
        setSelectedReturnedStatus(event.target.value);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const filterData = useCallback(() => {
        setFilteredData(machineData.filter(item => {
            return (
                (selectedItem === '' || item.name === selectedItem) &&
                (selectedProject === '' || item.project_name === selectedProject) &&
                (selectedQuantity === '' || item.quantity === selectedQuantity) &&
                (selectedPickupStatus === '' || item.pickup_time === selectedPickupStatus) &&
                (selectedReturnedStatus === '' || item.returned_time === selectedReturnedStatus) &&
                (searchQuery === '' || item.project_name.toLowerCase().includes(searchQuery.toLowerCase()))
            );
        }));
    }, [selectedItem, selectedProject, selectedQuantity, selectedPickupStatus, selectedReturnedStatus, searchQuery, machineData]);

    useEffect(() => {
        filterData();
    }, [selectedItem, selectedProject, selectedQuantity, selectedPickupStatus, selectedReturnedStatus, searchQuery, filterData]);

    const totalItems = filteredData.length;
    const totalPages = Math.ceil(totalItems / productsPerPage);
    const currentProducts = filteredData.slice((currentPage - 1) * productsPerPage, currentPage * productsPerPage);

    const handlePageChange = (event, value) => {
        setCurrentPage(value);
    };

    const handleDelete = (id) => {
        setFilteredData(prevProducts => prevProducts.filter(product => product.id !== id));
    };

    const triggerDataRefresh = () => {
        setRefreshData(prev => !prev); // Toggle the state to trigger re-fetch
    };

    return (
        <Box>
            {machineData.length > 0 ? (
                <>
                    <div className='headerContainer'>
                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, width: '100%' }}>
                            <TextField
                                label="Search Projects"
                                value={searchQuery}
                                onChange={handleSearchChange}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{ m: 1, minWidth: 250 }}
                            />
                            <FormControl sx={{ m: 1, minWidth: 250 }}>
                                <InputLabel id="item-select-label">Select Item</InputLabel>
                                <Select
                                    labelId="item-select-label"
                                    value={selectedItem}
                                    onChange={handleItemChange}
                                    label="Select Item"
                                >
                                    <MenuItem value="">
                                        <em>All</em>
                                    </MenuItem>
                                    {itemNames.map((name) => (
                                        <MenuItem key={name} value={name}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl sx={{ m: 1, minWidth: 250 }}>
                                <InputLabel id="project-select-label">Select Project</InputLabel>
                                <Select
                                    labelId="project-select-label"
                                    value={selectedProject}
                                    onChange={handleProjectChange}
                                    label="Select Project"
                                >
                                    <MenuItem value="">
                                        <em>All</em>
                                    </MenuItem>
                                    {projectNames.map((name) => (
                                        <MenuItem key={name} value={name}>
                                            {name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl sx={{ m: 1, minWidth: 250 }}>
                                <InputLabel id="pickup-time-select-label">Select Pickup Status</InputLabel>
                                <Select
                                    labelId="pickup-time-select-label"
                                    value={selectedPickupStatus}
                                    onChange={handlePickupStatusChange}
                                    label="Select Pickup Status"
                                >
                                    <MenuItem value="">
                                        <em>All</em>
                                    </MenuItem>
                                    {pickupStatus.map((time) => (
                                        <MenuItem key={time} value={time}>
                                            {time}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <FormControl sx={{ m: 1, minWidth: 250 }}>
                                <InputLabel id="return-time-select-label">Select Return Status</InputLabel>
                                <Select
                                    labelId="return-time-select-label"
                                    value={selectedReturnedStatus}
                                    onChange={handleReturnedStatusChange}
                                    label="Select Return Status"
                                >
                                    <MenuItem value="">
                                        <em>All</em>
                                    </MenuItem>
                                    {returnedStatus.map((time) => (
                                        <MenuItem key={time} value={time}>
                                            {time}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                    </div>
                    <TableContainer component={Paper} elevation={0} sx={{ overflowY: 'auto', height: '100%', border: '1px solid black', borderRadius: '20px' }}>
                        <IssuableHeader />
                        <div className="inventoryContainer" style={{ display: 'flex', flexDirection: 'column', flexGrow: 1 }}>
                            {currentProducts.map((item) => (
                                <IssuableListItem key={item.id} product={item} onDelete={handleDelete} onDialogClose={triggerDataRefresh} />
                            ))}
                        </div>
                        {totalPages > 1 && (
                            <CustomPagination count={totalPages} page={currentPage} onChange={handlePageChange} />
                        )}
                    </TableContainer>
                </>
            ) : (
                <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flexGrow={1} padding="40px">
                    <img src={nodatafoundImage} alt="No Data Found" style={{ width: '300px', marginBottom: '16px' }} />
                    <Typography variant="h6" color="textSecondary">
                        No data available
                    </Typography>
                </Box>
            )}
        </Box>
    );
};

export default IssuableTab;

import React from 'react';
import { Table, TableHead, TableRow, TableCell, TableSortLabel } from '@mui/material';

const BookableHeader = () => {
  return (
    <Table sx={{ width: '100%', height: 'auto' }}>
      <TableHead>
        <TableRow sx={{
          display: 'flex',
          alignItems: 'center',
          bgcolor: '#F7F7F7',
          borderBottom: '1px solid #F2F2F2',
        }}>
          <TableCell sx={{ width: '23%', textAlign: 'center', padding: '0.875rem 0.75rem', borderBottom: '1px solid #F2F2F2' }}>
            <TableSortLabel hideSortIcon sx={{ color: '#5C5C5C', fontWeight: '500', fontSize: '20px', lineHeight: '20px' }}>
              Item Name
            </TableSortLabel>
          </TableCell>
          <TableCell sx={{ width: '16%', textAlign: 'center', padding: '0.875rem 0.75rem', borderBottom: '1px solid #F2F2F2' }}>
            <TableSortLabel hideSortIcon sx={{ color: '#5C5C5C', fontWeight: '500', fontSize: '20px', lineHeight: '20px' }}>
              Project Name
            </TableSortLabel>
          </TableCell>
          <TableCell sx={{ width: '12%', textAlign: 'center', padding: '0.875rem 0.75rem', borderBottom: '1px solid #F2F2F2' }}>
            <TableSortLabel hideSortIcon sx={{ color: '#5C5C5C', fontWeight: '500', fontSize: '20px', lineHeight: '20px' }}>
              Location
            </TableSortLabel>
          </TableCell>
          <TableCell sx={{ width: '15%', textAlign: 'center', padding: '0.875rem 0.75rem', borderBottom: '1px solid #F2F2F2' }}>
            <TableSortLabel hideSortIcon sx={{ color: '#5C5C5C', fontWeight: '500', fontSize: '20px', lineHeight: '20px' }}>
              From Time
            </TableSortLabel>
          </TableCell>
          <TableCell sx={{ width: '15%', textAlign: 'center', padding: '0.875rem 0.75rem', borderBottom: '1px solid #F2F2F2' }}>
            <TableSortLabel hideSortIcon sx={{ color: '#5C5C5C', fontWeight: '500', fontSize: '20px', lineHeight: '20px' }}>
              To Time
            </TableSortLabel>
          </TableCell>
          <TableCell sx={{ width: '12%', textAlign: 'center', padding: '0.875rem 0.75rem', borderBottom: '1px solid #F2F2F2' }}>
            <TableSortLabel hideSortIcon sx={{ color: '#5C5C5C', fontWeight: '500', fontSize: '20px', lineHeight: '20px' }}>
              Approval Status
            </TableSortLabel>
          </TableCell>
          <TableCell sx={{ width: '10%', textAlign: 'center', padding: '0.875rem 0.75rem', borderBottom: '1px solid #F2F2F2' }}>
            <TableSortLabel hideSortIcon sx={{ color: '#5C5C5C', fontWeight: '500', fontSize: '20px', lineHeight: '20px' }}>
              Action
            </TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
    </Table>
  );
};

export default BookableHeader;

// Import statements
import { configureStore, combineReducers, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import userSlice from './slices/userSlice';
import tutorialSlice from './slices/tutorialSlice';
import workshopSlice from './slices/workshopSlice';


// Persist configuration
const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['user'], // Specify reducers to persist
    stateReconciler: autoMergeLevel2,
};

// Combine reducers using slices created in respective files
const rootReducer = combineReducers({
    user: userSlice.reducer,
    tutorial: tutorialSlice.reducer,
    workshop: workshopSlice.reducer,
});

// Apply persistence to rootReducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Configure store with Redux Toolkit
const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => 
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ['persist/PERSIST', 'persist/REHYDRATE', 'persist/PAUSE', 'persist/PURGE', 'persist/FLUSH', 'persist/REGISTER'],
            },
        }),
});

// Persistor for the store
const persistor = persistStore(store);

// Exports
export { store, persistor };

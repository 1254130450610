import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import Header from 'components/header';
import Sidebar from 'components/sidebar';
import ProfileImage from 'components/profile/ProfileImage';
import PersonalInfo from 'components/profile/PersonalInfo';
import CollegeInfo from 'components/profile/CollegeInfo';
import ChangePassword from 'components/profile/ChangePassword';
import { useSelector } from 'react-redux';

function Profile() {
  // const { access, user } = useSelector((state) => state.user);
  // const userData = user;

  // console.log(userData);

  // const [formData, setFormData] = useState({
  //   name: userData?.name || '',
  //   username: userData?.username || '',
  //   email: userData?.email || '',
  //   phone: userData?.phone_number || '',
  //   position: userData?.position || '',
  //   studentId: userData?.student_id || '',
  //   branch: userData?.department || '',
  //   currentYear: userData?.current_year || '',
  //   graduationYear: userData?.graduation_year || '',
  //   degree: userData?.degree || '',
  //   staff: userData?.is_staff || false,
  //   student: userData?.is_student || false,
  //   imageUrl: userData?.imageUrl || '',
  // });

  return (
    <Box sx={{ display: 'flex' }}>
      <Sidebar />
      <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', width: '100%' }}>
        <Header />
        <Typography
          variant="h4"
          sx={{
            padding: '1rem',
            color: 'rgb(39,63,119)',
            fontFamily: 'Inter',
            fontWeight: '600'
          }}
        >
          My Profile
        </Typography>
        <ProfileImage />
        <PersonalInfo />
        <CollegeInfo />
        {/* <ChangePassword defaultFormData={formData} /> */}
      </Box>
    </Box>
  );
}

export default Profile;


// import React, { useState } from 'react';
// import { Box, Typography } from '@mui/material';
// import Header from 'components/header';
// import Sidebar from 'components/sidebar';
// import ProfileImage from 'components/profile/ProfileImage';
// import PersonalInfo from 'components/profile/PersonalInfo';
// import CollegeInfo from 'components/profile/CollegeInfo';
// import ChangePassword from 'components/profile/ChangePassword';

// // import UserProfile from 'components/profile/Name';

// // import { useUser } from "./../UserContext"; // Adjust the import path as necessary
// import { useSelector } from 'react-redux';

// function Profile() {

//   // const { userData } = useUser(); // Assuming user data is stored in userData
//   const {access, user} = useSelector((state) => state.user);  
//   const userData = user;

//   console.log(userData);

//   const [formData, setFormData] = React.useState({
//     name: userData?.name || '',
//     username: userData?.username || '',
//     email: userData?.email || '',
//     phone: userData?.phone_number || '',
//     position: userData?.position || '',
//     studentId: userData?.student_id || '',
//     branch: userData?.department || '', // Assuming this is a constant value
//     currentYear: userData?.current_year || '',
//     graduationYear: userData?.graduation_year || '',
//     degree: userData?.degree || '',
//     staff: userData?.is_staff || false,
//     student: userData?.is_student || false,
//     imageUrl: userData?.imageUrl || '',
// });


//   // const [formData, setFormData] = React.useState({
//   //   // firstName: 'John',
//   //   // lastName: 'Doe',
//   //   username: userData?.username || '',
//   //   email: 'johndoe@gmail.com',
//   //   phone: '9876543210',
//   //   position: 'TL student management body',
//   //   studentId: '123456',
//   //   branch: 'Computer Science',
//   //   currentYear: '2023',
//   //   graduationYear: '2027',
//   //   degree: 'B.Tech'
//   // });

//   // Combine firstName and lastName to create the full name
//   // const fullName = `${formData.firstName} ${formData.lastName}`;

//   return (
//     <Box sx={{ display: 'flex' }}>
//       <Sidebar />
//       <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', width: '100%', paddin:'10px' }}>
//         <Header />
//         <Typography variant="h4"
//           sx={{
//             padding: '1rem',
//             color:'rgb(39,63,119)',
//             fontFamily: 'Inter',
//             fontWeight: '600'
//           }}>
//           My Profile
//           {/* {formData.firstName}'s Profile  */}
//         </Typography>
//         {/* <UserProfile/> */}
//         {/* Pass the fullName and position to the ProfileImage component */}
//         {/* <ProfileImage imageUrl="path/to/your/image.jpg" name={formData.username} position={formData.position} /> */}
//         <ProfileImage formData={formData} />
//         {/* Pass formData to the Position component */}
//         <PersonalInfo defaultFormData={formData} />
//         {/* The Name and Position components are no longer needed since we're now passing the name and position directly to the ProfileImage component */}
//         <CollegeInfo formData={formData} />

//         <ChangePassword defaultFormData={formData} />



//       </Box>
//     </Box>
//   );
// }

// export default Profile;





// // import React from 'react';
// // import Header from 'components/header';
// // import Sidebar from 'components/sidebar';

// // import ProfilePage from 'components/profile/ProfilePage';


// // import { Box } from '@mui/material';


// // // const imageUrl = 'https://www.google.com/imgres?q=profile%20picture&imgurl=https%3A%2F%2Fcdn.pixabay.com%2Fphoto%2F2015%2F10%2F05%2F22%2F37%2Fblank-profile-picture-973460_960_720.png&imgrefurl=https%3A%2F%2Fpixabay.com%2Fvectors%2Fblank-profile-picture-mystery-man-973460%2F&docid=wg0CyFWNfK7o5M&tbnid=IFTclT_pNlD0eM&vet=12ahUKEwi3i-zpysCFAxXPbmwGHVevC-EQM3oECFQQAA..i&w=720&h=720&hcb=2&ved=2ahUKEwi3i-zpysCFAxXPbmwGHVevC-EQM3oECFQQAA';
// // import imageUrl from '../utilities/dummy_assets/logo.png';

// // const profile = {
// //     name: 'John Doe',
// //     bio: 'A passionate web developer with 5 years of experience in React and Node.js.',
// //     imageUrl: 'https://via.placeholder.com/150',
// //     skills: ['React', 'Node.js', 'JavaScript', 'HTML', 'CSS'],
// //     // experience: [
// //     //    {
// //     //      title: 'Senior Developer',
// //     //      company: 'Tech Company',
// //     //      duration: '2018 - Present',
// //     //      description: 'Developed and maintained web applications using React and Node.js.',
// //     //    },
// //     //    // Add more experience entries as needed
// //     // ],
// //     // education: [
// //     //    {
// //     //      school: 'XYZ University',
// //     //      degree: 'Bachelor of Science in Computer Science',
// //     //      year: '2015 - 2019',
// //     //    },
// //     //    // Add more education entries as needed
// //     // ],
// //     // contact: {
// //     //    email: 'john.doe@example.com',
// //     //    phone: '+1 123 456 7890',
// //     // },
// //    };

// // const Profile = () => {
// //  return (
//     // <Box sx={{ display: 'flex' }}>
//     //   <Sidebar />
//     //   <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', width: '100%' }}>
//     //     <Header />
//     //     <ProfilePage profile={profile} />
//     //     {/* <ProfilePicture imageUrl={imageUrl} /> */}
//     //     {/* Uncomment and use InventoryTableMain as needed */}
//     //     {/* <InventoryTableMain></InventoryTableMain> */}
//     //   </Box>
//     // </Box>
// //  );
// // };

// // export default Profile;

import React, { useState, useEffect } from 'react';
import { Card, CardActions, CardContent, CardMedia, Button, Typography, CircularProgress } from '@mui/material';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useSelector } from 'react-redux';
import MachineDetailAPI from 'utilities/api/StudentMachineDetail';
import ImageBaseURL from 'utilities/api/axios';

const CardComponent = ({ item, setOpenDialog, searchQuery }) => {
    const { access } = useSelector((state) => state.user);
    const [machineDetails, setMachineDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const machineId = item.id;

    useEffect(() => {
        const fetchMachineDetails = async () => {
            setLoading(true);
            try {
                const details = await MachineDetailAPI.MachineDetailGet(access, machineId);
                setMachineDetails(details);
            } catch (err) {
                setError(err.message || 'Failed to fetch machine details');
            } finally {
                setLoading(false);
            }
        };

        if (machineId) {
            fetchMachineDetails();
        }
    }, [machineId, access]);

    const filterData = (details) => {
        const query = searchQuery.toLowerCase();
        const { name, category, location, availability } = details;
        return (
            (name && name.toLowerCase().includes(query)) ||
            (category && category.toLowerCase().includes(query)) ||
            (location && location.toLowerCase().includes(query)) ||
            (availability && availability.toLowerCase().includes(query))
        );
    };

    const filtered = machineDetails && filterData(machineDetails);

    // Styles

    const cardStyles = {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between', // Ensures that content is spaced out with actions at the bottom
        maxWidth: 300,
        padding: '.5rem',
        borderRadius: '1rem',
        border: '2px solid var(--Primary-Primary01, #edf1ff)',
        background: 'var(--Primary-Primary-11, #F4F4F4)',
        margin: '10px 0px 0px 10px',
        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.2)',
        transition: 'box-shadow 0.3s ease',
        '&:hover': {
            boxShadow: '0px 12px 20px rgba(0, 0, 0, 0.3)',
        },
    };

    const mediaStyles = {
        height: 150,
        borderRadius: '.75rem',
        border: '1px solid #7594ff',
    };

    const titleStyles = {
        color: '#3a77a3',
        fontFamily: 'Inter',
        fontSize: '30px',
        fontWeight: '400',
        lineHeight: '1.5',
    };

    const infoStyles = {
        color: '#212121',
        fontFamily: 'Inter',
        fontSize: '15px',
        fontWeight: '350',
        lineHeight: '1.5',
    };

    const infoMarker = {
        fontWeight: '500',
    };

    const iconStyles = {
        color: '#273F77',
        padding: '0px 10px',
        marginRight: '35%',
    };

    const buttonStyles = {
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '0.5rem',
        border: '2px solid var(--s-primary-source, #273F77)',
        letterSpacing: '0rem',
        fontWeight: '700',
        fontSize: '1rem',
        color: '#273F77',
        '&:hover': {
            backgroundColor: '#E79A2B',
        },
    };

    // const baseURL = 'https://staging.misadmin.mapit.ai/';

    return (
        <>
            {filtered && (
                <Card sx={cardStyles}>
                    {loading && <CircularProgress />} {/* Display loading indicator */}
                    {!loading && !error && machineDetails && (
                        <>
                            <CardMedia
                                sx={mediaStyles}
                                image={machineDetails.image ? `${ImageBaseURL.defaults.baseURL}${machineDetails.image}` : "./machine.png"} // Conditional image rendering
                                title={machineDetails.name}
                            />
                            <CardContent sx={{ padding: '10px 0px 10px 0px' }}>
                                <Typography variant="h5" sx={titleStyles}>
                                    {machineDetails.name}
                                </Typography>
                                <Typography variant="body1" sx={infoStyles}>
                                    <span style={infoMarker}>Category:</span> {machineDetails.category || 'N/A'}
                                </Typography>
                                <Typography variant="body1" sx={infoStyles}>
                                    <span style={infoMarker}>Location:</span> {machineDetails.location || 'N/A'}
                                </Typography>
                                <Typography variant="body1" sx={infoStyles}>
                                    <span style={infoMarker}>Description:</span> {machineDetails.description || 'N/A'}
                                </Typography>
                                <Typography variant="body1" sx={infoStyles}>
                                    <span style={infoMarker}>Availability:</span> {machineDetails.availability || 'N/A'}
                                </Typography>
                            </CardContent>
                            {/* <hr style={{ margin: '0px', backgroundColor:'#a0a0a0' }} /> */}
                            <hr style={{ margin: '0px', height: '1.5px', backgroundColor: '#a0a0a0', opacity: '0.3' }} />
                            <CardActions sx={{ padding: '10px 0px 10px 0px' }}>
                                {/* <Tooltip title="Feature under progress">
                                    <CalendarMonthIcon sx={iconStyles} />
                                </Tooltip> */}
                                <Button onClick={setOpenDialog} size="small" sx={buttonStyles}>
                                    Book Machine
                                </Button>
                            </CardActions>
                        </>
                    )}
                    {error && <Typography color="error">{error}</Typography>} {/* Display error message */}
                </Card>
            )}
        </>
    );
};

export default CardComponent;





// import React, { useState, useEffect } from 'react';
// import { Card, CardActions, CardContent, CardMedia, Button, Typography, CircularProgress, Tooltip } from '@mui/material';
// import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
// import { useSelector } from 'react-redux';
// import MachineDetailAPI from 'utilities/api/StudentMachineDetail';

// const CardComponent = ({ item, setOpenDialog, searchQuery }) => {
//     const { access } = useSelector((state) => state.user);
//     const [machineDetails, setMachineDetails] = useState(null);
//     const [loading, setLoading] = useState(false);
//     const [error, setError] = useState(null);

//     const machineId = item.id;

//     useEffect(() => {
//         const fetchMachineDetails = async () => {
//             setLoading(true);
//             try {
//                 const machineDetails = await MachineDetailAPI.MachineDetailGet(access, machineId);
//                 setMachineDetails(machineDetails);
//             } catch (error) {
//                 setError(error.message || 'Failed to fetch machine details');
//             } finally {
//                 setLoading(false);
//             }
//         };

//         if (machineId) {
//             fetchMachineDetails();
//         }
//     }, [machineId, access]);

//     const filterData = (details) => {
//         const query = searchQuery.toLowerCase();
//         const { name, category, location, availability } = details;

//         return (
//             (name && name.toLowerCase().includes(query)) ||
//             (category && category.toLowerCase().includes(query)) ||
//             (location && location.toLowerCase().includes(query)) ||
//             (availability && availability.toLowerCase().includes(query))
//         );
//     };

//     const filtered = machineDetails && filterData(machineDetails);

//     return (
//         <>
//             {filtered && (
//                 <Card sx={{
//                     maxWidth: 300,
//                     padding: '.5rem',
//                     borderRadius: '1rem',
//                     border: '2px solid var(--Primary-Primary01, #212121)',
//                     background: 'var(--Primary-Primary-11, #F4F4F4)',
//                     margin: '10px 0px 0px 10px',
//                 }}>
//                     {loading && <CircularProgress />} {/* Display loading indicator */}
//                     {!loading && !error && machineDetails && ( // Render content if details are available
//                         <>
//                             <CardMedia
//                                 sx={{ height: 150, borderRadius: '.75rem' }}
//                                 image="./machine.png"
//                                 // image={`./${product.name.toLowerCase()}.png`} // Assuming images are named accordingly
//                                 title={machineDetails.name}
//                             />
//                             <CardContent>
//                                 <Typography gutterBottom variant="h5" component="div" sx={{ color: '#3a77a3', fontFamily: 'Inter', fontSize: '30px', fontWeight: '400', lineHeight: '1.5' }}>
//                                     {machineDetails?.name}
//                                 </Typography>
//                                 <Typography variant="body1" sx={{ color: '#212121', fontFamily: 'Inter', fontSize: '12px', fontWeight: '400', lineHeight: '1.5' }}>
//                                     <b>Category</b>: {machineDetails?.category || 'N/A'}
//                                 </Typography>
//                                 <Typography variant="body1" sx={{ color: '#212121', fontFamily: 'Inter', fontSize: '12px', fontWeight: '400', lineHeight: '1.5' }}>
//                                     <b>Location</b>: {machineDetails?.location || 'N/A'}
//                                 </Typography>
//                                 <Typography variant="body1" sx={{ color: '#212121', fontFamily: 'Inter', fontSize: '12px', fontWeight: '400', lineHeight: '1.5' }}>
//                                     <b>Description</b>: {machineDetails?.description || 'N/A'}
//                                 </Typography>
//                                 <Typography variant="body1" sx={{ color: '#212121', fontFamily: 'Inter', fontSize: '12px', fontWeight: '400', lineHeight: '1.5' }}>
//                                     <b>Availability</b>: {machineDetails?.availability || 'N/A'}
//                                 </Typography>
//                             </CardContent>
//                             <CardActions>
//                                 <Tooltip title="Feature under progress">
//                                     <CalendarMonthIcon sx={{ color: '#273F77', padding: '0px 10px', marginRight: '35%' }} />
//                                 </Tooltip>
//                                 <Button onClick={setOpenDialog} size="small" sx={{
//                                     display: 'flex',
//                                     padding: '0.5rem .5rem',
//                                     justifyContent: 'center',
//                                     alignItems: 'center',
//                                     borderRadius: '0.5rem',
//                                     border: '1px solid var(--s-primary-source, #273F77)',
//                                     letterSpacing: '0rem',
//                                     color: '#273F77',
//                                     '&:hover': {
//                                         backgroundColor: '#E79A2B',
//                                     }
//                                 }}>
//                                     Book Machine
//                                 </Button>
//                             </CardActions>
//                         </>
//                     )}
//                     {error && <Typography color="error">{error}</Typography>} {/* Display error message */}
//                 </Card>
//             )}
//         </>
//     );
// };

// export default CardComponent;













// import { useState, useEffect } from 'react';
// import { Card, CardActions, CardContent, CardMedia, Button, Typography, CircularProgress } from '@mui/material';
// import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
// import Tooltip from '@mui/material/Tooltip';
// // import { useUser } from '../../../UserContext'; // Adjust the import path as necessary

// import { useSelector } from 'react-redux';


// import MachineDetailAPI from 'utilities/api/StudentMachineDetail';

// const CardComponent = ({ item, setOpenDialog }) => {

//     // const { accessToken } = useUser(); // Assuming user data is stored as userData in the context

//     const {access, user} = useSelector((state) => state.user);



//     const machineId = item.id;
//     const [machineDetails, setMachineDetails] = useState(null);
//     const [loading, setLoading] = useState(false);
//     const [error, setError] = useState(null);

//     console.log('111', machineId);

//     useEffect(() => {
//         const fetchMachineDetails = async () => {
//             setLoading(true);
//             try {
//                 // const machineDetails = await MachineDetailAPI.MachineDetailGet(accessToken, machineId);
//                 const machineDetails = await MachineDetailAPI.MachineDetailGet(access,machineId);
//                 setMachineDetails(machineDetails);
//             } catch (error) {
//                 setError(error.message || 'Failed to fetch machine details');
//             } finally {
//                 setLoading(false);
//             }
//         };

//         if (machineId) {
//             fetchMachineDetails();
//         }

//     }, [machineId]);

//     const handleOpenDialog = () => {
//         setOpenDialog(true);
//     };

//     return (
//         <Card sx={{
//             maxWidth: 300,
//             padding: '.5rem',
//             borderRadius: '1rem',
//             border: '2px solid var(--Primary-Primary01, #212121)',
//             background: 'var(--Primary-Primary-11, #F4F4F4)'
//         }}>
//             {loading && <CircularProgress />} {/* Display loading indicator */}
//             {!loading && !error && machineDetails && ( // Render content if details are available
//                 <>
//                     <CardMedia
//                         sx={{ height: 150, borderRadius: '.75rem' }}
//                         image="./machine.png"
//                         title={machineDetails.name}
//                     />
//                     <CardContent>
//                     <Typography gutterBottom variant="h5" component="div" sx = {{color:'#3a77a3', fontFamily: 'Inter', fontSize: '30px', fontWeight: '400', lineHeight: '1.5'}}>
//                         {machineDetails?.name}
//                     </Typography>
//                     <Typography variant="body1" sx={{ color: '#212121', fontFamily: 'Inter', fontSize: '12px', fontWeight: '400', lineHeight: '1.5' }}>
//                         <b>Category</b>: {machineDetails?.category || 'N/A'}
//                     </Typography>
//                     <Typography variant="body1" sx={{ color: '#212121', fontFamily: 'Inter', fontSize: '12px', fontWeight: '400', lineHeight: '1.5' }}>
//                         <b>Location</b>: {machineDetails?.location || 'N/A'}
//                     </Typography>
//                     <Typography variant="body1" sx={{ color: '#212121', fontFamily: 'Inter', fontSize: '12px', fontWeight: '400', lineHeight: '1.5' }}>
//                         <b>Description</b>: {machineDetails?.description || 'N/A'}
//                     </Typography>
//                     <Typography variant="body1" sx={{ color: '#212121', fontFamily: 'Inter', fontSize: '12px', fontWeight: '400', lineHeight: '1.5' }}>
//                         <b>Availability</b>: {machineDetails?.availability || 'N/A'}
//                     </Typography>
//                     {/* <Typography variant="body2" color="text.secondary">
//                         <span style={{ fontWeight: 'bold' }}>Status: </span>{machineDetails?.status}
//                     </Typography> */}
//                     {/* Additional parameters */}
//                     {/* <Typography variant="body1" sx={{ color: '#212121', fontFamily: 'Inter', fontSize: '12px', fontWeight: '400', lineHeight: '1.5' }}>
//                         <b>UPC</b>: {machineDetails?.upc || 'N/A'}
//                     </Typography> */}
//                     {/* <Typography variant="body1" sx={{ color: '#212121', fontFamily: 'Inter', fontSize: '12px', fontWeight: '400', lineHeight: '1.5' }}>
//                         <b>Manufacturer</b>: {machineDetails?.manufacturer || 'N/A'}
//                     </Typography> */}
//                     {/* <Typography variant="body1" sx={{ color: '#212121', fontFamily: 'Inter', fontSize: '12px', fontWeight: '400', lineHeight: '1.5' }}>
//                         <b>Instances</b>: {machineDetails?.instances || 'N/A'}
//                     </Typography> */}
//                 </CardContent>
//                     <CardActions>
//                         <Tooltip title="Feature under progress">
//                             <CalendarMonthIcon sx={{ color: '#273F77', padding: '0px 10px', marginRight: '35%' }} />
//                         </Tooltip>
//                         <Button onClick={handleOpenDialog} size="small" sx={{
//                             display: 'flex',
//                             padding: '0.5rem .5rem',
//                             justifyContent: 'center',
//                             alignItems: 'center',
//                             borderRadius: '0.5rem',
//                             border: '1px solid var(--s-primary-source, #273F77)',
//                             letterSpacing: '0rem',
//                             color: '#273F77',
//                             '&:hover': {
//                                 backgroundColor: '#E79A2B',
//                             }
//                         }}>
//                             Book Machine
//                         </Button>
//                     </CardActions>
//                 </>
//             )}
//             {error && <Typography color="error">{error}</Typography>} {/* Display error message */}
//         </Card>
//     );
// };

// export default CardComponent;








// import { useState, useEffect } from 'react';
// import { Card, CardActions, CardContent, CardMedia, Button, Typography } from '@mui/material';
// import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

// import MachineDetailAPI from 'utilities/api/StudentMachineDetail';

// import Tooltip from '@mui/material/Tooltip';

// const CardComponent = ({ item, setOpenDialog }) => {

//     // console.log('CardComponent item',item);

//     const machineId  = item.id;

//     const [MachineDetails, setMachineDetails] = useState(null);

//   useEffect(() => {
//     // Check if machineId is available and it has changed
//     // if (open && machineId) {
//       console.log('Machine ID:', machineId);
//       // Call the API for machine details
//       const fetchMachineDetails = async () => {
//         try {
//           const machineDetails = await MachineDetailAPI.MachineDetailGet(machineId);
//           console.log('Machine Details:', machineDetails);
//           setMachineDetails(machineDetails);
//           // Here you can set the state or perform other actions with the machineDetails
//         } catch (error) {
//           console.error('Failed to fetch machine details:', error);
//         }
//       };
//       fetchMachineDetails();
//     // }
//   }, [ machineId]);

//   console.log('Machine Details', MachineDetails);

//   const product = MachineDetails;


//     // const [product, setProduct] = useState(MachineDetails);

//     const handleOpenDialog = () => {
//         setOpenDialog(true);
//     };

//     return (
//         <Card sx={{
//             maxWidth: 300,
//             padding: '.5rem',
//             borderRadius: '1rem',
//             border: '2px solid var(--Primary-Primary01, #212121)',
//             background: 'var(--Primary-Primary-11, #F4F4F4)'
//         }}>
//             <CardMedia
//                 sx={{ height: 150, borderRadius: '.75rem' }}
//                 image="./machine.png"
//                 // image={`./${product.name.toLowerCase()}.png`} // Assuming images are named accordingly
//                 title={product?.name}
//             />
//             <CardContent>
//                 <Typography gutterBottom variant="h5" component="div">
//                     {product?.name}
//                 </Typography>
//                 <Typography variant="body2" color="text.secondary">
//                     <span style={{ fontWeight: 'bold' }}>Status: </span>{product?.status}
//                 </Typography>
//             </CardContent>
//             <CardActions>
//                 <Tooltip title="Feature under progress">
//                     <CalendarMonthIcon sx={{ color: '#273F77', padding: '0px 10px', marginRight: '35%' }} />
//                 </Tooltip>
//                 <Button onClick={handleOpenDialog} size="small" sx={{
//                     display: 'flex',
//                     padding: '0.5rem .5rem',
//                     justifyContent: 'center',
//                     alignItems: 'center',
//                     borderRadius: '0.5rem',
//                     border: '1px solid var(--s-primary-source, #273F77)',
//                     letterSpacing: '0rem',
//                     color: '#273F77',
//                     '&:hover': {
//                         backgroundColor: '#E79A2B',
//                     }
//                 }}>
//                     Book Machine
//                 </Button>
//             </CardActions>
//         </Card>
//     );
// };

// export default CardComponent;












// import { Box } from "@mui/material";
// import Card from '@mui/material/Card';
// import CardActions from '@mui/material/CardActions';
// import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
// import Button from '@mui/material/Button';
// import Typography from '@mui/material/Typography';
// import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

// const CardComponent = ({item, setOpenDialog}) => {
//     const handleOpenDialog = () => {
//         setOpenDialog(true);
//     };
//     return(
//     <Card sx={{
//         maxWidth: 300,
//         padding:'.5rem',
//         borderRadius: '1rem',
//         border: '2px solid var(--Primary-Primary01, #212121)',
//         background: 'var(--Primary-Primary-11, #F4F4F4)'
//     }}>
//         <CardMedia
//             sx={{ height: 150, borderRadius:'.75rem' }}
//             image="./machine.png"
//             title="green iguana"
//         />
//         <CardContent>
//             <Typography gutterBottom variant="h5" component="div">
//              {`${item.name}`}
//             </Typography>
//             <Typography variant="body2" color="text.secondary">
//                 <span style={{fontWeight:'bold'}}>Availablity: </span>{`${item.stock_available}`}
//             </Typography>
//         </CardContent>
//         <CardActions>
//             <CalendarMonthIcon sx={{color:'#273F77', padding:'0px 10px', marginRight:'35%'}}/>
//             <Button onClick={handleOpenDialog} size="small" sx={{
//                 display: 'flex',
//                 padding: '0.5rem .5rem',
//                 justifyContent: 'center',
//                 alignItems: 'center',
//                 borderRadius: '0.5rem',
//                 border: '1px solid var(--s-primary-source, #273F77)',
//                 letterSpacing:'0rem',
//                 color:'#273F77',
//                 '&:hover' : {
//                     backgroundColor: '#E79A2B',
//                 }
//             }}>
//                 Book Machine
//             </Button>
//         </CardActions>
//     </Card>

//     )
// }
// export default CardComponent;
import { axiosApiService } from "./axios";

const EquipmentDetailAPI = {
    EquipmentDetailGet: async function(accessToken, id) {
    try {
      const response = await axiosApiService.get(`/admin_equipment_detail/${id}/`, {
        // headers: {
        //   Authorization: `Bearer ${accessToken}`
        // }
      });
    //   console.log(response);
      // Map the response data to a more usable format
      const equipmentDetail = {
        id: response.data.id,
        name: response.data.name,
        availability: response.data.availability,
        status: response.data.status,
        category: response.data.category,
        location: response.data.location,
        upc: response.data.upc,
        manufacturer: response.data.manufacturer,
        description: response.data.description,
        availability1: response.data.availability1,
        status1: response.data.status1,
        image: response.data.image,
        instances: response.data.instances,
        supervised: response.data.supervised,
        purchaseCost: response.data.purchase_cost,
      };
      return equipmentDetail;
    } catch (error) {
      console.error('Error fetching equipment detail:', error);
      throw error; // Rethrow the error to be handled by the calling code
    }
 }
};

export default EquipmentDetailAPI;

import { axiosApiService } from "./axios";

const IssueInventoryAPIs = {
  IssueInventory: async function(accessToken, values) {
    if (!accessToken) {
      throw new Error('Access token is missing.');
    }

    try {
      const response = await axiosApiService.post(`/inventory_issue_list/`, values, {
        // headers: {
        //   // 'Authorization': `Bearer ${accessToken}`,
        //   'Content-Type': 'application/json'
        // }
      });

      console.log('Response:', response.data);
      return response.data; // Assuming the server returns the data
    } catch (error) {
      console.error('Error issuing inventory:', error);
      throw error;
    }
  },
  IssuedInventoryGet: async function() {
    try {
      const response = await axiosApiService.get(`/inventory_issue_list/`);
      console.log(response);
      return response.data; 
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  },

  IssueInventoryUpdate: async function(id, updateData) {
    try {
      const response = await axiosApiService.patch(`/inventory_issue_detail/${id}/`, updateData);
      return response.data
    } catch (error) {
      throw error;
    }
  },

  IssueInventoryDelete: async function(id) {
    try {
      const response = await axiosApiService.delete(`/inventory_issue_detail/${id}/`);
      return response.data; 
    } catch (error) {
      throw error;
    }
  }
};

export default IssueInventoryAPIs;



 


// import { axiosApiService } from "./axios";

// const IssueInventoryAPIs = {
//     IssueInventory: async function(accessToken, values) {
//         if (!accessToken) {
//             throw new Error('Access token is missing.');
//         }

//         try {
//             console.log(values);
//             const response = await axiosApiService.get(`/inventory_issue_list/`, {
//               headers: {
//                 // "Authorization": `Bearer ${accessToken}`
//               "Content-Type":"application/json",
//               }
//             });
//             console.log(response);
//             return response.data; // Assuming the server returns the data
//           } catch (error) {
//             console.error('Error fetching machine data:', error);
//             throw error;
//           }
//         },
// };

// export default IssueInventoryAPIs;
import React, { useState } from 'react';
import {Pagination, Typography} from '@mui/material/';
import PaginationItem from '@mui/material/PaginationItem';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';

const CustomPagination = ({ count, page, onChange, productsPerPage, setProductsPerPage }) => {
  const theme = useTheme();

  const handleProductsPerPageChange = (event) => {
    setProductsPerPage(event.target.value);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: theme.spacing(2),
      }}
    >
      <Typography variant="subtitle1" sx={{ marginRight:'5px', color: '#898989', fontWeight: '400', fontSize: '1rem', lineHeight: '1.25rem', whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>
              Products per page:
          </Typography>
      <Select
        value={productsPerPage}
        onChange={handleProductsPerPageChange}
        variant="standard"
        sx={{ marginRight: '1rem' }}
      >
        <MenuItem value={10}>10</MenuItem>
        <MenuItem value={25}>25</MenuItem>
        <MenuItem value={50}>50 </MenuItem>
        <MenuItem value={100}>100 </MenuItem>
      </Select>
      <Pagination
        count={count}
        page={page}
        onChange={onChange}
        renderItem={(item) => (
          <PaginationItem
            components={{}}
            {...item}
            sx={{
              margin: theme.spacing(0, 1),
              '&.Mui-selected': {
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.common.white,
              },
            }}
          />
        )}
      />
    </Box>
  );
};

export default CustomPagination;





// import React from 'react';
// import Pagination from '@mui/material/Pagination';
// import PaginationItem from '@mui/material/PaginationItem';
// import { useTheme } from '@mui/material/styles';
// import { Box } from '@mui/system';

// const CustomPagination = ({ count, page, onChange }) => {
//   const theme = useTheme();

//   return (
//     <Box
//       sx={{
//         display: 'flex',
//         justifyContent: 'center',
//         alignItems: 'center',
//         padding: theme.spacing(2), // Adjust the padding as needed
//         // Apply additional styles if necessary to match your design
//       }}
//     >
//       <Pagination
//         count={count}
//         page={page}
//         onChange={onChange}
//         renderItem={(item) => (
//           <PaginationItem
//             components={{}}
//             {...item}
//             sx={{
//               margin: theme.spacing(0, 1), // Adjust spacing between pagination items
//               '&.Mui-selected': {
//                 backgroundColor: theme.palette.primary.main, // This is for the selected page number
//                 color: theme.palette.common.white,
//               },
//               // Apply additional styles to match the image provided
//             }}
//           />
//         )}
//       />
//     </Box>
//   );
// };

// export default CustomPagination;



import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, List, ListItem, TextField, Divider, Drawer, FormControl, InputLabel, Select, MenuItem, Autocomplete, Chip, Avatar, Alert } from '@mui/material';
import { Close as CloseIcon, NoteAdd as NoteAddIcon, Pending as PendingIcon, People as PeopleIcon, Link as LinkIcon, Create as CreateIcon, Description as DescriptionIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import StudentGetUser from 'utilities/api/StudentAllUserAPIs';
import ImageBaseURL from 'utilities/api/axios';
import { toast } from 'react-toastify';

const progress = {
  1: 'Archived',
  2: 'In Progress',
  3: 'Completed'
};

const type = {
  1: 'Academic',
  2: 'Research',
  3: 'Personal',
  4: 'Other'
};

const ProjectDrawer = ({ drawerWidth, drawerOpen, toggleDrawer, handleSubmit, handleDelete, currentProject }) => {
  const { user } = useSelector((state) => state.user);
  const [teammateOptions, setTeammateOptions] = useState([]);
  const [currentTeammates, setCurrentTeammates] = useState([]);
  const [creator, setCreator] = useState(null);
  const [searchValue, setSearchValue] = useState(''); // State for search input
  const [currentDrawerWidth, setCurrentDrawerWidth] = useState(drawerWidth);
  const [isResizing, setIsResizing] = useState(false);
  const [teammateError, setTeammateError] = useState(''); // State for teammate error message

  const formik = useFormik({
    initialValues: {
        type: currentProject ? currentProject.type : '',
        progress_status: currentProject ? currentProject.progress_status : '',
        links: currentProject ? currentProject.links : '',
        title: currentProject ? currentProject.title : '',
        description: currentProject ? currentProject.description : '',
        student: user.id
    },
    onSubmit: async (values) => {
        if (teammateError) {
            return;
        }
        values.teammates = currentTeammates.map(teammate => teammate.id);

        try {
            // Assuming handleSubmit is an async function
            await handleSubmit(values);
            // toast.success('Project details updated successfully!');
        } catch (error) {
            toast.error('Failed to update project details. Please try again.');
            console.error('Error updating project details:', error);
        }
    },
    enableReinitialize: true
});

  useEffect(() => {
    const fetchUserData = async (username) => {
      try {
        const userData = await StudentGetUser.UserDetailsGet(username);
        return userData[0]; // Assuming the API returns an array and we take the first item
      } catch (error) {
        console.error(`Error fetching data for ${username}:`, error);
        return null;
      }
    };

    const fetchProjectData = async () => {
      if (currentProject) {
        // Fetch creator data
        const creatorData = await fetchUserData(currentProject.student);
        setCreator(creatorData);

        // Fetch teammates data
        const teammates = await Promise.all(
          currentProject.teammates_names.map(async (username) => {
            const teammateData = await fetchUserData(username);
            return teammateData;
          })
        );

        setCurrentTeammates(teammates.filter(Boolean)); // Filter out any null values

        formik.setValues({
          type: currentProject.type || '',
          progress_status: currentProject.progress_status || '',
          links: currentProject.links || '',
          title: currentProject.title || '',
          description: currentProject.description || '',
          student: currentProject.student || user.id
        });
      } else if (!currentProject) {
        formik.resetForm();
        setCurrentTeammates([]);
        setCreator(null);
      }
    };

    fetchProjectData();
  }, [currentProject, user.id]);

  // const handleTeammateSearch = async (event, value) => {
  //   setSearchValue(value); // Update search value
  //   if (value.length > 0) {
  //     try {
  //       const results = await StudentGetUser.UserDetailsGet(value);
  //       setTeammateOptions(results.filter((user) => user.TL === currentProject.TL));
  //     } catch (error) {
  //       console.error('Error fetching teammate options:', error);
  //     }
  //   } else {
  //     setTeammateOptions([]);
  //   }
  // };
  const handleTeammateSearch = async (event, value) => {
    setSearchValue(value); // Update search value

    if (value.length > 0) {
        try {
            const results = await StudentGetUser.UserDetailsGet(value);

            // Filter the results to include only users whose TL_name matches the current user's TL_name
            const filteredResults = results.filter(users => users.TL_name === user.TL_name);

            setTeammateOptions(filteredResults);
        } catch (error) {
            console.error('Error fetching teammate options:', error);
        }
    } else {
        setTeammateOptions([]);
    }
  };

  const handleTeammateSelect = (event, value) => {
    const newTeammates = value.filter(teammate => 
      !currentTeammates.some(current => current.id === teammate.id) && 
      teammate.id !== formik.values.student
    );

    if (newTeammates.length !== value.length) {
      setTeammateError('Some teammates are already added or are the project owner.');
    } else {
      setTeammateError('');
    }

    const updatedTeammates = [...currentTeammates, ...newTeammates];

    setCurrentTeammates(updatedTeammates);
    setTeammateOptions([]);
    setSearchValue(''); // Clear search value only after adding teammates
  };

  const handleRemoveTeammate = (teammateToRemove) => {
    setCurrentTeammates(currentTeammates.filter(teammate => teammate.id !== teammateToRemove.id));
    setTeammateError('');
  };

  const handleMouseDown = (e) => {
    setIsResizing(true);
  };

  const handleMouseMove = (e) => {
    if (isResizing) {
      const newWidth = window.innerWidth - e.clientX;
      if (newWidth > 300) { // Minimum width constraint
        setCurrentDrawerWidth(newWidth);
      }
    }
  };

  const handleMouseUp = () => {
    setIsResizing(false);
  };

  useEffect(() => {
    if (isResizing) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    } else {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    }
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isResizing]);

  return (
    <Drawer anchor="right" open={drawerOpen} onClose={() => toggleDrawer(false)} sx={{ zIndex: 101 }}>
      <Box sx={{ height:'100vh', width: currentDrawerWidth, padding: 2, display: 'flex', flexDirection: 'column', position: 'relative' }} role="presentation">
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '16px 24px', backgroundColor: '#2c387e', color: 'white' }}>
          <Typography variant="h6">{currentProject ? 'Edit Project' : 'Add Project'}</Typography>
          <Button onClick={() => toggleDrawer(false)} sx={{ color: 'white' }}>
            <CloseIcon />
          </Button>
        </Box>
        <Box sx={{ padding: 2 }}>
          <form onSubmit={formik.handleSubmit}>
            <List>
              <ListItem>
                <NoteAddIcon />
                <FormControl variant="outlined" sx={{ width: '100%' }}>
                  <InputLabel htmlFor="type-select">Type of Project *</InputLabel>
                  <Select
                    label="Type of Project"
                    name="type"
                    value={formik.values.type}
                    onChange={formik.handleChange}
                    sx={{ width: '100%', borderRadius: '8px' }}
                  >
                    {Object.entries(type).map(([key, value]) => (
                      <MenuItem key={key} value={key}>{value}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </ListItem>
              <ListItem>
                <PendingIcon />
                <FormControl variant="outlined" sx={{ width: '100%' }}>
                  <InputLabel htmlFor="progress-status-select">Project Progress Status *</InputLabel>
                  <Select
                    label="Project Progress Status"
                    name="progress_status"
                    value={formik.values.progress_status}
                    onChange={formik.handleChange}
                    sx={{ width: '100%', borderRadius: '8px' }}
                  >
                    {Object.entries(progress).map(([key, value]) => (
                      <MenuItem key={key} value={key}>{value}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </ListItem>
              <ListItem>
                <LinkIcon />
                <TextField
                  variant="outlined"
                  sx={{ width: '100%' }}
                  placeholder="Add Link/Documents"
                  value={formik.values.links}
                  onChange={formik.handleChange}
                  name="links"
                />
              </ListItem>
              <ListItem>
                <CreateIcon />
                <TextField
                  variant="outlined"
                  sx={{ width: '100%' }}
                  placeholder="Add Project Title *"
                  value={formik.values.title}
                  onChange={formik.handleChange}
                  name="title"
                />
              </ListItem>
              <ListItem>
                <DescriptionIcon />
                <TextField
                  variant="outlined"
                  sx={{ width: '100%' }}
                  placeholder="Add Project Description *"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  name="description"
                />
              </ListItem>
              {creator && (
                <ListItem>
                  <PeopleIcon sx={{mr:'4px'}} />
                  <Typography variant="body2" sx={{ marginRight: 2 }}>
                    Creator:
                  </Typography>
                  <Chip
                    avatar={<Avatar src={creator?.profile_pic ? `${ImageBaseURL.defaults.baseURL}${creator.profile_pic}` : ''} />}
                    label={`${creator?.name || creator?.username} (${creator?.username})`}
                  />
                </ListItem>
              )}
              <input type="hidden" name="student" value={formik.values.student} />
              <ListItem label="Type of Project">
                <PeopleIcon sx={{mr:'4px'}} />
                <FormControl variant="outlined" sx={{ width: '100%', }}>
                  <Autocomplete
                    multiple
                    options={teammateOptions}
                    getOptionLabel={(option) => `${option.name || option.username} (${option.username})`}
                    filterSelectedOptions
                    onInputChange={handleTeammateSearch}
                    onChange={handleTeammateSelect}
                    sx={{
                      width: '100%',
                      height:'auto',
                      '& .MuiAutocomplete-inputRoot': {
                        flexWrap: 'wrap', // Ensure the input root wraps its content
                        height:'auto',
                        padding:'4px'
                      },
                      '& .MuiChip-root': {
                        margin: '4px', // Ensure chips have some margin for spacing
                      },
                      '& .MuiInputBase-root': {
                        display: 'flex', // Ensure the input base is a flex container
                        flexWrap: 'wrap', // Ensure it wraps its content
                      },
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Search and Add Teammates"
                        sx={{ width: '100%' }}
                      />
                    )}
                  />
                </FormControl>
              </ListItem>
              {teammateError && (
                <ListItem>
                  <Alert severity="error">{teammateError}</Alert>
                </ListItem>
              )}
              {currentTeammates.length > 0 && (
                <ListItem>
                  <Typography variant="subtitle1" sx={{ marginRight: 2 }}>
                    Current Teammates:
                  </Typography>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                    {currentTeammates.map(teammate => (
                      <Chip
                        key={teammate.id}
                        label={`${teammate.name || teammate.username} (${teammate.username})`}
                        avatar={<Avatar src={teammate.profile_pic ? `${ImageBaseURL.defaults.baseURL}${teammate.profile_pic}` : ''} />}
                        onDelete={() => handleRemoveTeammate(teammate)}
                        deleteIcon={<DeleteIcon />}
                        sx={{ margin: '4px 0' }}
                      />
                    ))}
                  </Box>
                </ListItem>
              )}
              <input type="hidden" name="student" value={formik.values.student} />
            </List>
            <Divider />
            <Box sx={{ display: 'flex', justifyContent: 'end', gap: '8px', marginTop: '4rem', marginRight: '2rem' }}>
              {currentProject && (
                <Button variant="outlined" color="warning" sx={{ minHeight: '48px', textTransform: 'none', borderRadius: '8px', border: '2px solid #212121', fontWeight: 600, width: '25%', color: '#C90505' }} onClick={handleDelete}>
                  Delete
                </Button>
              )}
              <Button type="submit" variant="contained" color="warning" sx={{ minHeight: '48px', textTransform: 'none', backgroundColor: '#E79A2B', borderRadius: '8px', border: '2px solid #212121', fontWeight: 600, width: '25%' }}>
                {currentProject ? 'Save' : 'Add'}
              </Button>
            </Box>
          </form>
        </Box>
        <Box
          sx={{
            width: '5px',
            backgroundColor: '#ddd',
            cursor: 'ew-resize',
            height: '100%',
            position: 'absolute',
            left: 0,
            top: 0,
            zIndex: 102,
          }}
          onMouseDown={handleMouseDown}
        />
      </Box>
    </Drawer>
  );
};

export default ProjectDrawer;

import { axiosApiService } from "./axios";

const InventoryDetailAPI = {
    /**
     * Fetches the details of a specific inventory item by ID.
     * @param {string} accessToken - The access token for authentication.
     * @param {string} id - The ID of the inventory item to fetch.
     * @returns {Promise<Object>} The inventory detail data.
     */
    InventoryDetailGet: async function (accessToken, id) {
        try {
            const response = await axiosApiService.get(`/admin_inventory_detail/${id}/`, {
                // Uncomment this line if authorization is required
                // headers: {
                //     Authorization: `Bearer ${accessToken}`
                // }
            });

            // Map the response data to a more usable format
            const InventoryDetail = {
                id: response.data.id,
                tag1: response.data.tag_1,
                tag2: response.data.tag_2,
                tag3: response.data.tag_3,
                tag4: response.data.tag_4,
                category: response.data.category,
                location: response.data.location,
                group: response.data.group,
                name: response.data.name,
                upc: response.data.upc,
                manufacturer: response.data.manufacturer,
                description: response.data.description,
                stock_available: response.data.stock_available,
                stock_total: response.data.stock_total,
                stock_unit: response.data.stock_unit,
                purchase_cost: response.data.purchase_cost,
                purchase_date: response.data.purchase_date,
                warranty_expiration: response.data.warranty_expiration,
                image: response.data.image,
                created_at: response.data.created_at,
                last_updated_at: response.data.last_updated_at,
                is_hidden: response.data.is_hidden
            };
            return InventoryDetail;
        } catch (error) {
            console.error('Error fetching inventory detail:', error);
            throw error;
        }
    },

    /**
     * Updates an existing inventory item by ID.
     * @param {string} accessToken - The access token for authentication.
     * @param {string} id - The ID of the inventory item to update.
     * @param {Object} updateData - The data to update the inventory item with.
     * @returns {Promise<Object>} The updated inventory item data.
     */
    InventoryUpdate: async function (accessToken, id, updateData) {
        if (!accessToken) {
            throw new Error('Access token is missing.');
        }

        try {
            const response = await axiosApiService.patch(`/admin_inventory_detail/${id}/`, updateData, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            });

            console.log(`Inventory item updated successfully for ID ${id}:`, response.data);
            return response.data;
        } catch (error) {
            console.error(`Error updating inventory item for ID ${id}:`, error);
            throw error;
        }
    },

    /**
     * Deletes an inventory item by ID.
     * @param {string} accessToken - The access token for authentication.
     * @param {string} id - The ID of the inventory item to delete.
     * @returns {Promise<Object>} The deleted inventory item data or success message.
     */
    InventoryDelete: async function (accessToken, id) {
        if (!accessToken) {
            throw new Error('Access token is missing.');
        }

        try {
            const response = await axiosApiService.delete(`/admin_inventory_detail/${id}/`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });

            console.log(`Inventory item deleted successfully for ID ${id}:`, response.data);
            return response.data;
        } catch (error) {
            console.error(`Error deleting inventory item for ID ${id}:`, error);
            throw error;
        }
    },

    /**
     * Fetches a list of all inventory items.
     * @param {string} accessToken - The access token for authentication.
     * @returns {Promise<Array>} The list of all inventory items.
     */
    InventoryListGet: async function (accessToken) {
        try {
            const response = await axiosApiService.get(`/admin_inventory_detail/`, {
                headers: {
                    'Authorization': `Bearer ${accessToken}`
                }
            });

            console.log('Inventory list fetched successfully:', response.data);
            return response.data;
        } catch (error) {
            console.error('Error fetching inventory list:', error);
            throw error;
        }
    }
};

export default InventoryDetailAPI;



// import { axiosApiService } from "./axios";

// const InventoryDetailAPI = {
//  InventoryDetailGet: async function(accessToken, id) {
//     try {
//       const response = await axiosApiService.get(`/admin_inventory_detail/${id}/`, {
//         // headers: {
//         //   Authorization: `Bearer ${accessToken}`
//         // }
//       });
//     //   console.log(response);
//       // Map the response data to a more usable format
//       const InventoryDetail = {
//         id: response.data.id,
//         tag1: response.data.tag_1,
//         tag2: response.data.tag_2,
//         tag3: response.data.tag_3,
//         tag4: response.data.tag_4,
//         category: response.data.category,
//         location: response.data.location,
//         group: response.data.group,
//         name: response.data.name,
//         upc: response.data.upc,
//         manufacturer: response.data.manufacturer,
//         description: response.data.description,
//         stock_available: response.data.stock_available,
//         stock_total: response.data.stock_total,
//         stock_unit: response.data.stock_unit,
//         purchase_cost: response.data.purchase_cost,
//         purchase_date: response.data.purchase_date,
//         warranty_expiration: response.data.warranty_expiration,
//         image: response.data.image,
//         created_at: response.data.created_at,
//         last_updated_at: response.data.last_updated_at,
//         is_hidden: response.data.is_hidden
//       };
//       return InventoryDetail;
//     } catch (error) {
//       console.error('Error fetching Inventory detail:', error);
//       throw error; // Rethrow the error to be handled by the calling code
//     }
//  }
// };

// export default InventoryDetailAPI;

import React, { useState } from 'react';
import { Card, Typography, Button, Tooltip } from '@mui/material';
import EditPurchaseDialog from 'components/popups/EditPurchaseDialog';

const PurchasableListItem = ({ product, onDelete, onDialogClose }) => {
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);

    const handleOpenDialog = (product) => {
        setSelectedProduct(product);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedProduct(null);
        onDialogClose(); // Trigger data refresh when dialog is closed
    };

    const getDateFromDateTime = (dateTimeStr) => {
        if (!dateTimeStr) {
            return 'NA';
        }
        const dateObj = new Date(dateTimeStr);
        const year = dateObj.getFullYear();
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');
        const day = String(dateObj.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const isEditDisabled = () => {
        return product.pickup_ === 'Yes';
    };

    return (
        <>
            <Card sx={{
                display: 'flex',
                alignItems: 'center',
                bgcolor: 'white',
                height: 'auto',
                boxSizing: 'border-box',
                padding: '0.875rem 0.75rem',
                borderBottom: '1px solid #F2F2F2'
            }}>
                <Typography variant="body2" sx={{
                    width: '15%',
                    textAlign: 'center',
                    color: '#7A7A7A',
                    fontWeight: '500',
                    fontSize: '20px',
                    lineHeight: '20px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                }}>
                    {product.name}
                </Typography>
                <Typography variant="body2" sx={{
                    width: '20%',
                    textAlign: 'center',
                    color: '#7A7A7A',
                    fontWeight: '500',
                    fontSize: '20px',
                    lineHeight: '20px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                }}>
                    {product.project_name}
                </Typography>
                <Typography variant="body2" sx={{
                    width: '15%',
                    textAlign: 'center',
                    color: '#7A7A7A',
                    fontWeight: '500',
                    fontSize: '20px',
                    lineHeight: '20px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                }}>
                    {getDateFromDateTime(product.purchase_datetime)}
                </Typography>
                <Typography variant="body2" sx={{
                    width: '17%',
                    textAlign: 'center',
                    color: '#7A7A7A',
                    fontWeight: '500',
                    fontSize: '20px',
                    lineHeight: '20px',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                }}>
                    {product.purchase_amount}
                </Typography>
                <Typography sx={{
                    width: '10%',
                    textAlign: 'center',
                    color: '#898989',
                    fontWeight: '400',
                    fontSize: '20px',
                    lineHeight: '20px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                }}>
                    {product.quantity}
                </Typography>
                <Typography sx={{
                    width: '15%',
                    textAlign: 'center',
                    color: '#898989',
                    fontWeight: '400',
                    fontSize: '20px',
                    lineHeight: '20px',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                }}>
                    {product.pickup_}
                </Typography>
                <Typography sx={{
                    width: '5%',
                    marginLeft:'2%',  
                    }}>
                    <Tooltip title={isEditDisabled() ? "Item has been returned" : ""}>
                        <span>
                            <Button
                                onClick={() => handleOpenDialog(product)}
                                variant="text"
                                sx={{
                                    backgroundColor: isEditDisabled() ? "#E0E0E0" : "#E79A2B",
                                    color: isEditDisabled() ? "#A0A0A0" : "#fff",
                                    padding: "8px 32px",
                                    borderRadius: "12px",
                                    cursor: isEditDisabled() ? "not-allowed" : "pointer",
                                    fontWeight: 600,
                                    textTransform: 'none',
                                    width: '100%',
                                }}
                                disabled={isEditDisabled()}
                            >
                                Edit
                            </Button>
                        </span>
                    </Tooltip>
                </Typography>
            </Card>
            {selectedProduct && (
                <EditPurchaseDialog
                    open={openDialog}
                    onClose={handleCloseDialog}
                    product={selectedProduct}
                />
            )}
        </>
    );
};

export default PurchasableListItem;

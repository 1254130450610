import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import {
    TextField,
    Button,
    MenuItem,
    Grid,
    Box,
    Typography,
    FormControl,
    Select,
    InputLabel,
    FormHelperText 
} from '@mui/material';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Auth_API from '../../../utilities/api/auth_api';
import TinkerLabListAPIs from '../../../utilities/api/TinkerLabListAPIs';

const typographyStyle = {
    color: "var(--Primary-Primary03, #5C5C5C)",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "1.2rem", // Reduced font size
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    letterSpacing: "-0.0165rem",
};

const boxStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: ".6rem",
    alignSelf: "stretch",
    marginBottom: "1rem",
};

const styledTextfield = {
    display: "flex",
    gap: "0.2rem",
    alignSelf: "stretch",
    borderRadius: "0.75rem",
};

const degree_options = ['B.Tech', 'M.Tech', 'M.Sc', 'PhD', 'MCA', 'MBA', 'Other'];
const department_options = [
    ['CSE', 'Computer Science and Engineering'],
    ['ECE', 'Electronics and Communication Engineering'],
    ['ME', 'Mechanical Engineering'],
    ['CE', 'Civil Engineering'],
    ['EE', 'Electrical Engineering'],
    ['CHE', 'Chemical Engineering'],
    ['MME', 'Metallurgical and Materials Engineering'],
    ['BT', 'Biotechnology'],
    ['CL', 'Chemistry'],
    ['MA', 'Mathematics'],
    ['PH', 'Physics'],
    ['HSS', 'Humanities and Social Sciences'],
    ['Other', 'Other']
];

const iitDomains = {
    "iitb.ac.in": "IIT Bombay",
    "iitgn.ac.in": "IIT Gandhinagar",
    "iitjammu.ac.in": "IIT Jammu",
    // "iitd.ac.in": "IIT Delhi",
    // "iitk.ac.in": "IIT Kanpur",
    // "iitm.ac.in": "IIT Madras",
    // "iitkgp.ac.in": "IIT Kharagpur",
    // "iitr.ac.in": "IIT Roorkee",
    // "iitg.ac.in": "IIT Guwahati",
    // "iitbhu.ac.in": "IIT BHU (Varanasi)",
    // "iith.ac.in": "IIT Hyderabad",
    // "iitrpr.ac.in": "IIT Ropar",
    // "iitj.ac.in": "IIT Jodhpur",
    // "iiti.ac.in": "IIT Indore",
    // "iitmandi.ac.in": "IIT Mandi",
    // "iitp.ac.in": "IIT Patna",
    // "iitbbs.ac.in": "IIT Bhubaneswar",
    // "iitgoa.ac.in": "IIT Goa",
    // "iitpkd.ac.in": "IIT Palakkad",
    // "iittp.ac.in": "IIT Tirupati",
    // "iitism.ac.in": "IIT Dhanbad (ISM Dhanbad)",
    // "iitdh.ac.in": "IIT Dharwad"
};

const checkIITEmail = (email) => {
    const domain = email.split('@')[1];
    return iitDomains[domain] || "";
};

const validationSchema = Yup.object().shape({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    phone_number: Yup.string().required('Phone number is required'),
    graduation_year: Yup.string().required('Graduation Year is required'),
    hostel_address: Yup.string(),
    department: Yup.string().required('Department is required'),
    degree: Yup.string().required('Degree is required'),
    current_year: Yup.string().required('Current Year is required'),
    TL: Yup.string().required("Tinkerers' Lab is required"),
});

const StudentRegistrationForm = () => {
    const navigate = useNavigate();
    const [iitDomains, setIitDomains] = useState({});
    const [allTLs, setAllTLs] = useState([]);
    const [TLs, setTLs] = useState([]);
    const [isTLDisabled, setIsTLDisabled] = useState(false);

    useEffect(() => {
        TinkerLabListAPIs.TinkerLabsListGet().then((data) => {
            const domains = {};
            const allTinkerLabs = [];

            data.forEach(tl => {
                allTinkerLabs.push(tl.name);
                tl.accepted_ldaps.forEach(domain => {
                    if (!domains[domain.replace('@', '')]) {
                        domains[domain.replace('@', '')] = [];
                    }
                    domains[domain.replace('@', '')].push(tl.name);
                });
            });

            setIitDomains(domains);
            setAllTLs(allTinkerLabs);
        }).catch((error) => {
            console.error('Error fetching TL data:', error);
        });
    }, []);

    const checkIITEmail = (email) => {
        const domain = email.split('@')[1];
        return iitDomains[domain] || [];
    };

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone_number: '',
            graduation_year: '',
            hostel: '',
            department: '',
            degree: '',
            current_year: '',
            TL: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            Auth_API.studentRegister(values).then((result) => {
                console.log(result);
                toast.success('You have registered successfully! You will receive login credentials via your registered email id.');
                navigate('/login');
            }).catch((error) => {
                console.log(error);
                toast.error(error.response.error || 'An unexpected error occurred. Please try again.');
            });
        }
    });

    useEffect(() => {
        const colleges = checkIITEmail(formik.values.email);
        if (colleges.length === 0) {
            formik.setFieldValue('TL', '');
        } else {
            formik.setFieldValue('TL', colleges[0] || "");
        }

        if (colleges.length > 1) {
            setIsTLDisabled(false);
        } else if (colleges.length === 1) {
            setIsTLDisabled(true);
        } else {
            setIsTLDisabled(false);
        }

        setTLs(colleges);
    }, [formik.values.email, iitDomains]);

    return (
        <Box
            sx={{
                width: '50rem',
                height: '30rem',
                mx: 'auto',
                p: 2,
                backgroundColor: 'transparent'
            }}
        >
            <form onSubmit={formik.handleSubmit}>
                <Grid container spacing={8}>
                    <Grid item xs={12} sm={6}>
                        <Box sx={boxStyle}>
                            <Typography sx={typographyStyle}>
                                Name
                            </Typography>
                            <TextField
                                id="name"
                                name="name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name}
                                sx={styledTextfield}
                                placeholder="Enter your name"
                            />
                        </Box>
                        <Box sx={boxStyle}>
                            <Typography sx={typographyStyle}>
                                Email Address
                            </Typography>
                            <TextField
                                id="email"
                                name="email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                                sx={styledTextfield}
                                placeholder="Enter email address"
                            />
                        </Box>
                        <Box sx={boxStyle}>
                            <Typography sx={typographyStyle}>
                                Phone Number
                            </Typography>
                            <TextField
                                id="phone_number"
                                name="phone_number"
                                value={formik.values.phone_number}
                                onChange={formik.handleChange}
                                error={formik.touched.phone_number && Boolean(formik.errors.phone_number)}
                                helperText={formik.touched.phone_number && formik.errors.phone_number}
                                sx={styledTextfield}
                                placeholder="Enter your phone_number"
                            />
                        </Box>
                        <Box sx={boxStyle}>
                            <Typography sx={typographyStyle}>
                                Graduation Year
                            </Typography>
                            <TextField
                                id="graduation_year"
                                name="graduation_year"
                                value={formik.values.graduation_year}
                                onChange={formik.handleChange}
                                error={formik.touched.graduation_year && Boolean(formik.errors.graduation_year)}
                                helperText={formik.touched.graduation_year && formik.errors.graduation_year}
                                sx={styledTextfield}
                                placeholder="Enter your expected graduation year"
                            />
                        </Box>
                        <Box sx={boxStyle}>
                            <Typography sx={typographyStyle}>
                                Hostel
                            </Typography>
                            <TextField
                                id="hostel_address"
                                name="hostel_address"
                                value={formik.values.hostel_address}
                                onChange={formik.handleChange}
                                error={formik.touched.hostel_address && Boolean(formik.errors.hostel_address)}
                                helperText={formik.touched.hostel_address && formik.errors.hostel_address}
                                sx={styledTextfield}
                                placeholder="Enter your current hostel"
                            />
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Box sx={boxStyle}>
                            <Typography sx={typographyStyle}>
                                TL Name
                            </Typography>
                            <FormControl sx={styledTextfield} error={formik.touched.TL && Boolean(formik.errors.TL)}>
                                <InputLabel id="TL-label">Select your TL</InputLabel>
                                <Select
                                    labelId="TL-label"
                                    id="TL"
                                    name="TL"
                                    value={formik.values.TL}
                                    onChange={formik.handleChange}
                                    label="Select your TL"
                                    disabled={isTLDisabled}
                                >
                                    {TLs.length > 0 ? (
                                        TLs.map((tl) => (
                                            <MenuItem key={tl} value={tl}>
                                                {tl}
                                            </MenuItem>
                                        ))
                                    ) : (
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                    )}
                                </Select>
                                {formik.touched.TL && formik.errors.TL && (
                                    <FormHelperText>{formik.errors.TL}</FormHelperText>
                                )}
                            </FormControl>
                        </Box>
                        <Box sx={boxStyle}>
                            <Typography sx={typographyStyle}>
                                department
                            </Typography>
                            <FormControl fullWidth>
                                <Select
                                    labelId="department-label"
                                    id="department"
                                    name="department"
                                    value={formik.values.department}
                                    label="department"
                                    placeholder="Enter your major department"
                                    onChange={formik.handleChange}
                                    error={formik.touched.department && Boolean(formik.errors.department)}
                                >
                                    {department_options.map((option, index) => (
                                        <MenuItem key={index} value={option[0]}>
                                            {option[1]}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={boxStyle}>
                            <Typography sx={typographyStyle}>
                                Degree
                            </Typography>
                            <FormControl fullWidth>
                                <Select
                                    labelId="degree-label"
                                    id="degree"
                                    name="degree"
                                    value={formik.values.degree}
                                    label="degree"
                                    placeholder="Enter your expected degree"
                                    onChange={formik.handleChange}
                                    error={formik.touched.degree && Boolean(formik.errors.degree)}
                                >
                                    {degree_options.map((option, index) => (
                                        <MenuItem key={index} value={option}>
                                            {option}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Box>
                        <Box sx={boxStyle}>
                            <Typography sx={typographyStyle}>
                                Year of study
                            </Typography>
                            <TextField
                                id="current_year"
                                name="current_year"
                                value={formik.values.current_year}
                                onChange={formik.handleChange}
                                error={formik.touched.current_year && Boolean(formik.errors.current_year)}
                                helperText={formik.touched.current_year && formik.errors.current_year}
                                sx={styledTextfield}
                                placeholder="Enter your current year of study"
                            />
                        </Box>
                    </Grid>
                </Grid>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', alignSelf: 'stretch', gap: '2rem', marginTop: '10px' }}>
                    <Button
                        variant="outlined"
                        onClick={() => {
                            formik.resetForm();
                            navigate("/");
                        }}
                        sx={{
                            display: 'flex',
                            width: '25rem',
                            padding: '1rem',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '0.5rem',
                            borderRadius: '0.75rem',
                            background: '1px solid var(--Primary-Primary03, #5C5C5C)',
                        }}
                    >
                        <Typography sx={typographyStyle && { color: 'black' }} >
                            Cancel
                        </Typography>
                    </Button>
                    <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        sx={{
                            display: 'flex',
                            width: '25rem',
                            padding: '1rem 1.5rem',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '0.5rem',
                            borderRadius: '0.75rem',
                            background: 'var(--Accent-Accent-00, #007BFF)'
                        }}>
                        <Typography sx={{
                            color: 'var(--Primary-white, #FFF)',
                            textAlign: 'center',
                            fontFamily: 'Inter',
                            fontSize: '1.2rem', // Reduced font size
                            fontStyle: 'normal',
                            fontWeight: 400,
                        }}>
                            Register</Typography>
                    </Button>
                </Box>
            </form>
        </Box>
    );
};

export default StudentRegistrationForm;

import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { Typography, Box, TextField, Button, DialogActions, MenuItem, IconButton, Dialog, DialogContent } from '@mui/material';
import { Assignment as AssignmentIcon, PersonAddAlt as PersonAddAltIcon, Storage as StorageIcon, Inventory as InventoryIcon, AttachMoney as AttachMoneyIcon, Add as AddIcon } from '@mui/icons-material';
import * as Yup from 'yup';
import PurchaseInventoryAPIs from 'utilities/api/StudentPurchaseInventory';
import ProjectAPIs from 'utilities/api/StudentProjectAPI';
import InventoryDetailAPI from 'utilities/api/StudentInventoryDetail';
import AddProjectForm from 'components/ExploreTL/machine/AddProjectForm';
import { toast } from 'react-toastify'; 
// import QRCodeDialog from './QRCodeDialog'; // Commented out the QR code dialog import

// Function to get current date and time in the required format
const getCurrentDateTime = () => {
  const date = new Date();
  return date.toISOString().slice(0, 19);
};

// Static validation schema
const validationSchema = Yup.object().shape({
  inventory: Yup.string().required('Machine ID is required'),
  purchased_by: Yup.string().required('Reserved By field is required'),
  quantity: Yup.number().required('Number of Quantity is required'),
  pickup: Yup.number().required('Pickup is required'),
  type_of_project: Yup.string().required('Type of project is required'),
  project: Yup.string().required('Project Title is required'),
  project_details: Yup.string().required('Project Details are required'),
});

const PurchaseInventory = ({ user, access, machineId, onClose }) => {
  const [bookingError, setBookingError] = useState(null);
  const [projects, setProjects] = useState([]);
  const [inventoryDetails, setInventoryDetails] = useState(null);
  const [isAddProjectDialogOpen, setAddProjectDialogOpen] = useState(false);
  // const [isQRCodeDialogOpen, setIsQRCodeDialogOpen] = useState(false); // Commented out QR code dialog state
  const [formValues, setFormValues] = useState(null);

  useEffect(() => {
    fetchProjects();
  }, []);

  const fetchProjects = async () => {
    try {
      const projectData = await ProjectAPIs.ProjectsGet();
      const filteredProjects = projectData.filter(
        (project) => project.student === user.id || project.teammates.includes(user.id)
      );
      setProjects(filteredProjects);
    } catch (error) {
      console.error('Error fetching projects:', error);
    }
  };

  useEffect(() => {
    fetchInventoryDetails();
  }, [machineId, access]);

  const fetchInventoryDetails = async () => {
    if (machineId) {
      try {
        const details = await InventoryDetailAPI.InventoryDetailGet(access, machineId);
        setInventoryDetails(details);
      } catch (error) {
        console.error('Error fetching inventory details:', error);
      }
    }
  };

  const formik = useFormik({
    initialValues: {
        purchased_by: user?.id || '',
        inventory: machineId || '',
        pickup: 1, // By default set as no
        quantity: '',
        type_of_project: '',
        project: '',
        project_details: '',
        purchase_datetime: getCurrentDateTime(),
    },
    validationSchema,
    onSubmit: async (values, { resetForm }) => {
        values.purchase_datetime = getCurrentDateTime();
        setFormValues(values);

        try {
            // Make the API call to purchase the inventory
            await PurchaseInventoryAPIs.PurchaseInventory(access, values);
            toast.success('Purchase submitted successfully!');
            console.log('Purchase submitted successfully');

            // Calculate the new stock available
            const newStockAvailable = inventoryDetails.stock_available - values.quantity;

            // Update the stock available
            await InventoryDetailAPI.InventoryUpdate(access, machineId, { stock_available: newStockAvailable });
            toast.success('Stock updated successfully!');
            console.log('Stock updated successfully');

            onClose();
            resetForm(); // Optionally reset the form here if needed
        } catch (error) {
            toast.error('Error submitting form. Please try again.');
            console.error('Error submitting form:', error.message);
        }
    },
  });

  // Set max validation for quantity based on available stock
  useEffect(() => {
    if (inventoryDetails) {
      formik.setFieldValue('quantity', formik.values.quantity || '');
      validationSchema.fields.quantity = Yup.number()
        .required('Number of Quantity is required')
        .max(inventoryDetails.stock_available, `Quantity cannot exceed available stock (${inventoryDetails.stock_available})`);
      formik.validateField('quantity');
    }
  }, [inventoryDetails, formik.values.quantity]);

  const handleProjectChange = (event) => {
    const selectedProject = projects.find((project) => project.id === event.target.value);
    if (selectedProject) {
      formik.setFieldValue('project', selectedProject.id); // Set the project ID
      formik.setFieldValue('type_of_project', selectedProject.type); // Auto-fill the project type
      formik.setFieldValue('project_details', selectedProject.description); // Auto-fill the project details
    }
  };

  const handleAddProject = async () => {
    await fetchProjects();
    setAddProjectDialogOpen(false);
  };

  // Commented out QR code dialog handling
  // const handleQRCodeDialogClose = async () => {
  //   setIsQRCodeDialogOpen(false);

  //   if (formValues) {
  //     try {
  //       await PurchaseInventoryAPIs.PurchaseInventory(access, formValues);
  //       console.log('Purchase submitted successfully');

  //       // Calculate the new stock available
  //       const newStockAvailable = inventoryDetails.stock_available - formValues.quantity;

  //       // Update the stock available
  //       await InventoryDetailAPI.InventoryUpdate(access, machineId, { stock_available: newStockAvailable });
  //       console.log('Stock updated successfully');

  //       onClose();
  //     } catch (error) {
  //       console.error('Error submitting form:', error.message);
  //       setBookingError('Error submitting form. Please try again.');
  //     }
  //   }
  // };

  const renderTextField = (name, label, type = 'text', multiline = false, select = false, options = [], handleChange = null, disabled = false) => (
    <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', mb: 2 }}>
      <AssignmentIcon sx={{ color: 'rgb(92,92,92)' }} />
      <TextField
        name={name}
        label={label}
        variant="outlined"
        type={type}
        multiline={multiline}
        select={select}
        fullWidth
        value={formik.values[name]}
        onChange={handleChange || formik.handleChange}
        onBlur={formik.handleBlur}
        error={formik.touched[name] && Boolean(formik.errors[name])}
        helperText={formik.touched[name] && formik.errors[name]}
        disabled={disabled}
      >
        {select &&
          options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
      </TextField>
      {name === 'project' && (
        <IconButton onClick={() => setAddProjectDialogOpen(true)} color="primary">
          <AddIcon />
        </IconButton>
      )}
    </Box>
  );

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', mb: 2 }}>
          <Typography variant="h6" sx={{ color: 'rgb(92,92,92)', fontFamily: 'Inter', fontSize: '16px', fontWeight: '600' }}>
            <PersonAddAltIcon sx={{ paddingRight: '10px', color: 'rgb(92,92,92)' }} />
            {user?.username || ''}
          </Typography>
        </Box>

        {inventoryDetails && (
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', mb: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <StorageIcon sx={{ mr: 1 }} />
                <Typography variant="body1" color="textSecondary">
                  Stock Available:
                </Typography>
              </Box>
              <Typography variant="body1" sx={{ ml: 2 }}>
                {inventoryDetails.stock_available} {inventoryDetails.stock_unit}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <InventoryIcon sx={{ mr: 1 }} />
                <Typography variant="body1" color="textSecondary">
                  Stock Total:
                </Typography>
              </Box>
              <Typography variant="body1" sx={{ ml: 2 }}>
                {inventoryDetails.stock_total} {inventoryDetails.stock_unit}
              </Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <AttachMoneyIcon sx={{ mr: 1 }} />
                <Typography variant="body1" color="textSecondary">
                  Purchase Cost:
                </Typography>
              </Box>
              <Typography variant="body1" sx={{ ml: 2 }}>
                {inventoryDetails.purchase_cost}
              </Typography>
            </Box>
          </Box>
        )}

        {renderTextField('quantity', 'Enter Quantity', 'number')}
        {renderTextField('project', 'Project Title', 'text', false, true, projects.map((project) => ({
          value: project.id,
          label: project.title,
        })), handleProjectChange)}
        {renderTextField('type_of_project', 'Type of Project', 'text', false, false, [], null, true)}
        {renderTextField('project_details', 'Project Details', 'text', true, false, [], null, true)}

        <DialogActions sx={{ marginTop: '15%' }}>
          <Button
            onClick={onClose}
            color="primary"
            sx={{
              width: '80px',
              height: '35px',
              color: 'black',
              border: '1px solid rgb(218,218,218)',
              borderRadius: '10px',
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            color="primary"
            sx={{
              width: '80px',
              height: '35px',
              color: 'white',
              backgroundColor: 'rgb(231,154,43)',
              border: '1px solid black',
              borderRadius: '10px',
            }}
          >
            Purchase
          </Button>
        </DialogActions>
        {bookingError && (
          <Typography color="error" sx={{ marginLeft: '35px', marginBottom: '10px' }}>
            {bookingError}
          </Typography>
        )}
      </form>

      <Dialog open={isAddProjectDialogOpen} onClose={() => setAddProjectDialogOpen(false)} fullWidth maxWidth="sm">
        <DialogContent>
          <AddProjectForm onClose={() => setAddProjectDialogOpen(false)} onAddProject={handleAddProject} />
        </DialogContent>
      </Dialog>

      {/* QR Code Dialog is commented out */}
      {/* <QRCodeDialog
        open={isQRCodeDialogOpen}
        onClose={handleQRCodeDialogClose}
        name={"MIS"}
        vpa={"9310483936@ptyes"}
        quantity={formik.values.quantity}
        inventory_name={inventoryDetails?.name}
        amount={(inventoryDetails?.purchase_cost * formik.values.quantity).toFixed(2)}
      /> */}
    </>
  );
};

export default PurchaseInventory;








// import React, { useState } from 'react';
// import { useFormik } from 'formik';
// import { Typography, Box, TextField, Button, DialogActions, MenuItem } from '@mui/material';
// import { DateRange as DateRangeIcon, AccessTime as AccessTimeIcon, Assignment as AssignmentIcon, CreateOutlined as CreateOutlinedIcon, EditNoteOutlined as EditNoteOutlinedIcon } from '@mui/icons-material';
// import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
// import * as Yup from 'yup';

// import PurchaseInventoryAPIs from 'utilities/api/StudentPurchaseInventory';

// // Validation schema for the form
// const validationSchema = Yup.object().shape({
//     inventory: Yup.string().required('Machine ID is required'),
//     purchased_by: Yup.string().required('Reserved By field is required'),
//     quantity: Yup.number().required('Number of Quantity is required'),
//     pickup: Yup.number().required('Pickup is required'),
//     type_of_project: Yup.string().required('Type of project is required'),
//     project: Yup.string().required('Project Title is required'),
//     project_details: Yup.string().required('Project Details is required'),
//     // purchase_amount: Yup.number().required('Purchase amount is required'),
// });

// const PurchaseInventory = ({ user, access, machineId, onClose }) => {
//     const [bookingError, setBookingError] = useState(null);

//     const formik = useFormik({
//         initialValues: {
//             purchased_by: user?.id || '',
//             inventory: machineId || '',
//             pickup: 0,
//             quantity: '',
//             type_of_project: '',
//             project: '',
//             project_details: '',
//             // purchase_amount: '',
//         },
//         validationSchema: validationSchema,
//         onSubmit: async (values, { resetForm }) => {
//             console.log('Submitting form with values:', values);
//             try {
//                 await PurchaseInventoryAPIs.PurchaseInventory(access, values);
//                 console.log('Form submitted successfully');
//                 onClose();
//             } catch (error) {
//                 console.error('Error submitting form:', error.message);
//                 setBookingError('Error submitting form. Please try again.');
//             }
//         },
//     });

//     return (
//         <form onSubmit={formik.handleSubmit}>
//             <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', mb: 2 }}>
//                 <Typography variant="h6" sx={{ color: 'rgb(92,92,92)', fontFamily: 'Inter', fontSize: '16px', fontWeight: '600' }}>
//                     <PersonAddAltIcon sx={{ paddingRight: '10px', color: 'rgb(92,92,92)' }} />
//                     {user?.username || ''}
//                 </Typography>
//             </Box>

//             <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', mb: 2 }}>
//                 <Typography variant="h6" sx={{ color: '#212121', fontFamily: 'Inter', fontSize: '16px', fontWeight: '600' }}>
//                     Unit - Specified by admin
//                 </Typography>
//             </Box>

//             <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', mb: 2 }}>
//                 <AssignmentIcon sx={{ color: 'rgb(92,92,92)' }} />
//                 <TextField
//                     type="number"
//                     name="quantity"
//                     label="Enter Quantity"
//                     variant="outlined"
//                     sx={{ width: '60%' }}
//                     value={formik.values.quantity}
//                     onChange={formik.handleChange}
//                     onBlur={formik.handleBlur}
//                 />
//             </Box>
//             {formik.errors.quantity && formik.touched.quantity && (
//                 <Typography color="error" sx={{ marginLeft: '35px', marginBottom: '10px' }}>{formik.errors.quantity}</Typography>
//             )}

//             {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', mb: 2 }}>
//                 <AccessTimeIcon sx={{ color: 'rgb(92,92,92)' }} />
//                 <TextField
//                     type="number"
//                     name="purchase_amount"
//                     label="Enter Purchase Amount"
//                     variant="outlined"
//                     sx={{ width: '60%' }}
//                     value={formik.values.purchase_amount}
//                     onChange={formik.handleChange}
//                     onBlur={formik.handleBlur}
//                 />
//             </Box>
//             {formik.errors.purchase_amount && formik.touched.purchase_amount && (
//                 <Typography color="error" sx={{ marginLeft: '35px', marginBottom: '10px' }}>{formik.errors.purchase_amount}</Typography>
//             )} */}

//             <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', mb: 2 }}>
//                 <AssignmentIcon sx={{ color: 'rgb(92,92,92)' }} />
//                 <TextField
//                     name="type_of_project"
//                     label="Type of Project"
//                     variant="outlined"
//                     select
//                     sx={{ width: '60%' }}
//                     value={formik.values.type_of_project}
//                     onChange={formik.handleChange}
//                     onBlur={formik.handleBlur}
//                 >
//                     <MenuItem value="">Select Type</MenuItem>
//                     <MenuItem value="CourseProject">Course Project</MenuItem>
//                     <MenuItem value="ClubProject">Club Project</MenuItem>
//                     <MenuItem value="SelfProject">Self Project</MenuItem>
//                     <MenuItem value="InterIIT">Inter IIT</MenuItem>
//                 </TextField>
//             </Box>
//             {formik.errors.type_of_project && formik.touched.type_of_project && (
//                 <Typography color="error" sx={{ marginLeft: '35px', marginBottom: '10px' }}>{formik.errors.type_of_project}</Typography>
//             )}

//             <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', mb: 2 }}>
//                 <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
//                     <CreateOutlinedIcon sx={{ color: 'rgb(92,92,92)' }} />
//                     <TextField
//                         type="text"
//                         name="project"
//                         label="Project Title"
//                         variant="outlined"
//                         fullWidth
//                         value={formik.values.project}
//                         onChange={formik.handleChange}
//                         onBlur={formik.handleBlur}
//                     />
//                 </Box>
//                 {formik.errors.project && formik.touched.project && (
//                     <Typography color="error" sx={{ marginLeft: '35px', marginBottom: '10px' }}>{formik.errors.project}</Typography>
//                 )}
//             </Box>

//             <Box sx={{ display: 'flex', flexDirection: 'column', gap: '10px', mb: 2 }}>
//                 <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
//                     <EditNoteOutlinedIcon sx={{ color: 'rgb(92,92,92)' }} />
//                     <TextField
//                         type="text"
//                         name="project_details"
//                         label="Project Details"
//                         variant="outlined"
//                         fullWidth
//                         multiline
//                         value={formik.values.project_details}
//                         onChange={formik.handleChange}
//                         onBlur={formik.handleBlur}
//                     />
//                 </Box>
//                 {formik.errors.project_details && formik.touched.project_details && (
//                     <Typography color="error" sx={{ marginLeft: '35px', marginBottom: '10px' }}>{formik.errors.project_details}</Typography>
//                 )}
//             </Box>

//             {/* Submit Button */}
//             <DialogActions sx={{ marginTop: '15%' }}>
//                 <Button
//                     onClick={onClose}
//                     color="primary"
//                     sx={{
//                         width: '80px',
//                         height: '35px',
//                         color: 'black',
//                         border: '1px solid rgb(218,218,218)',
//                         borderRadius: '10px',
//                     }}
//                 >
//                     Cancel
//                 </Button>
//                 <Button
//                     type="submit"
//                     color="primary"
//                     sx={{
//                         width: '80px',
//                         height: '35px',
//                         color: 'white',
//                         backgroundColor: 'rgb(231,154,43)',
//                         border: '1px solid black',
//                         borderRadius: '10px',
//                     }}
//                 >
//                     Purchase
//                 </Button>
//             </DialogActions>
//         </form>
//     );
// };

// export default PurchaseInventory;

import React, { useState, useEffect } from 'react';
import { Box, Tab, Tabs, FormControl, InputLabel, Select, MenuItem, TextField, IconButton, Button, useTheme, useMediaQuery, Typography } from '@mui/material';
import { Search as SearchIcon } from '@mui/icons-material';
import { useSelector } from 'react-redux';
import ProjectAPIs from 'utilities/api/StudentProjectAPI';
import StudentGetUser from 'utilities/api/StudentAllUserAPIs';
import ProjectDrawer from './ProjectDrawer';
import ProjectCard from './ProjectCard';
import ProjectViewDrawer from './ProjectViewDrawer';
import nodatafoundImage from '../../utilities/dummy_assets/nodatafound.jpg'; // Import the No Data Found image
import { toast } from 'react-toastify';

const Projectscont = () => {
  const { user, access } = useSelector((state) => state.user);
  const [value, setValue] = useState(0);
  const [type, setType] = useState('');
  const [status, setStatus] = useState('');
  const [drawerWidth, setDrawerWidth] = useState(400);
  const [isDragging, setIsDragging] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [viewDrawerOpen, setViewDrawerOpen] = useState(false);
  const [isSearchBoxVisible, setIsSearchBoxVisible] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [projects, setProjects] = useState([]);
  const [exploreProjects, setExploreProjects] = useState([]);
  const [currentProject, setCurrentProject] = useState(null);
  const [allUsers, setAllUsers] = useState([]);  // State for storing all users

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const handleMouseEvents = (e) => {
      if (isDragging) {
        if (e.type === 'mousemove') setDrawerWidth(window.innerWidth - e.clientX);
        if (e.type === 'mouseup') setIsDragging(false);
      }
    };

    if (isDragging) {
      window.addEventListener('mousemove', handleMouseEvents);
      window.addEventListener('mouseup', handleMouseEvents);
    } else {
      window.removeEventListener('mousemove', handleMouseEvents);
      window.removeEventListener('mouseup', handleMouseEvents);
    }
    return () => {
      window.removeEventListener('mousemove', handleMouseEvents);
      window.removeEventListener('mouseup', handleMouseEvents);
    };
  }, [isDragging]);

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const data = await ProjectAPIs.ProjectsGet();
        setProjects(data);
      } catch (error) {
        console.error('Failed to fetch projects:', error);
      }
    };

    const fetchAllUsers = async () => {
      try {
        const users = await StudentGetUser.AllUserListGet();  // Fetch all users
        setAllUsers(users);
      } catch (error) {
        console.error('Failed to fetch users:', error);
      }
    };

    fetchProjects();
    fetchAllUsers();  // Fetch all users when component mounts
  }, [user]);

  const toggleDrawer = (open, project = null) => {
    setDrawerOpen(open);
    setCurrentProject(open ? project : null);
  };

  const toggleViewDrawer = (open, project = null) => {
    setViewDrawerOpen(open);
    setCurrentProject(open ? project : null);
  };

  const handleTabChange = (event, newValue) => setValue(newValue);

  const handleSubmit = async (values) => {
    try {
        const updatedValues = { ...values, teammates: values.teammates };

        if (currentProject) {
            await ProjectAPIs.ProjectUpdate(currentProject.id, updatedValues);
            toast.success('Project updated successfully!');
        } else {
            await ProjectAPIs.ProjectCreate(access, updatedValues);
            toast.success('Project created successfully!');
        }

        setDrawerOpen(false);
        const data = await ProjectAPIs.ProjectsGet();
        setProjects(data);
    } catch (error) {
        toast.error('Error submitting project. Please try again.');
        console.error('Error submitting project:', error);
    }
  };

  const handleDelete = async () => {
    if (!currentProject) return;

    try {
        await ProjectAPIs.ProjectDelete(currentProject.id);
        setProjects(projects.filter((project) => project.id !== currentProject.id));
        setDrawerOpen(false);
        toast.success('Project deleted successfully!');
    } catch (error) {
        toast.error('Failed to delete project. Please try again.');
        console.error('Failed to delete project:', error);
    }
  };

  const filterProjects = (projects) => {
    return projects.filter((project) => {
      const typeMatch = type ? project.type === type : true;
      const statusMatch = status ? project.progress_status === status : true;
      const searchMatch = searchQuery
        ? project.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
          project.description.toLowerCase().includes(searchQuery.toLowerCase())
        : true;
      return typeMatch && statusMatch && searchMatch;
    });
  };

  const filterMyProjects = (projects) => {
    return projects.filter((project) => project.student === user.id || project.teammates.includes(user.id));
  };

  const renderComponent = (project) => {
    return (
      <ProjectCard
        key={project.id}
        project={project}
        onEdit={(project) => toggleDrawer(true, project)}
        onView={(project) => toggleViewDrawer(true, project)}
        user={user}
        showVisibility={value === 1} // Show visibility icon for "Explore Projects"
        allUsers={allUsers}  // Pass all users as a prop to ProjectCard
      />
    );
  };

  const isCardLayout = true;
  const gridTemplateColumns = 'repeat(4, 1fr)';

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: '2rem' }}>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 2 }}>
        <Tabs
          value={value}
          onChange={handleTabChange}
          indicatorColor="none"
          textColor="rgb(39,63,119)"
          sx={{
            padding: '5px 0 5px 5px',
            height: 'auto',
            background: 'rgb(242,243,252)',
            border: '2px solid rgba(39,63,119,0.6)',
            borderRadius: '12px',
          }}
        >
          <Tab
            label="My Projects"
            sx={{
              padding: '8px 32px',
              height: '44px',
              typography: 'body1',
              fontWeight: '600',
              border: '2px solid rgba(39,63,119,0.6)',
              borderRadius: '8px',
              '&.Mui-selected': { background: 'rgb(39,63,119)', color: 'white' },
            }}
          />
          <Tab
            label="Explore Projects"
            sx={{
              marginInline: '5px',
              padding: '8px 32px',
              height: '44px',
              typography: 'body1',
              fontWeight: '600',
              border: '2px solid rgba(39,63,119,0.6)',
              borderRadius: '8px',
              '&.Mui-selected': { background: 'rgb(39,63,119)', color: 'white' },
            }}
          />
        </Tabs>
        <FormControl variant="outlined" sx={{ minWidth: 200 }}>
          <InputLabel htmlFor="type-select">Select Project Type</InputLabel>
          <Select
            value={type}
            onChange={(e) => setType(e.target.value)}
            label="Select Project Type"
            sx={{ borderRadius: '8px' }}
            inputProps={{ name: 'type', id: 'type-select' }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value="1">Academic</MenuItem>
            <MenuItem value="2">Research</MenuItem>
            <MenuItem value="3">Personal</MenuItem>
            <MenuItem value="4">Other</MenuItem>
          </Select>
        </FormControl>
        <FormControl variant="outlined" sx={{ minWidth: 200 }}>
          <InputLabel htmlFor="status-select">Select Status</InputLabel>
          <Select
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            label="Select Status"
            sx={{ borderRadius: '8px' }}
            inputProps={{ name: 'status', id: 'status-select' }}
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            <MenuItem value="1">Archived</MenuItem>
            <MenuItem value="2">In Progress</MenuItem>
            <MenuItem value="3">Completed</MenuItem>
          </Select>
        </FormControl>
        <Box
          sx={{
            border: '1px solid #A6A6A6',
            borderRadius: '8px',
            display: 'flex',
            alignItems: 'center',
            width: isSearchBoxVisible || searchQuery ? '300px' : '40px',
            transition: 'width 0.3s ease-in-out',
            overflow: 'hidden',
          }}
        >
          <IconButton
            onClick={() => setIsSearchBoxVisible(true)}
            sx={{ display: isSearchBoxVisible || searchQuery ? 'none' : 'block' }}
          >
            <SearchIcon />
          </IconButton>
          {(isSearchBoxVisible || searchQuery) && (
            <TextField
              autoFocus
              onBlur={() => !searchQuery && setIsSearchBoxVisible(false)}
              sx={{ flexGrow: 1, marginLeft: '8px' }}
              placeholder="Search..."
              variant="standard"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              InputProps={{ disableUnderline: true }}
            />
          )}
        </Box>
        <Button
          variant="contained"
          color="warning"
          sx={{
            textTransform: 'none',
            backgroundColor: '#E79A2B',
            borderRadius: '8px',
            border: '2px solid #212121',
            fontWeight: 600,
          }}
          onClick={() => toggleDrawer(true)}
        >
          + New Project
        </Button>
      </Box>
      <Box sx={{ paddingTop: 2 }}>
        {projects.length > 0 ? (
          <Box
            sx={{
              display: isCardLayout ? 'grid' : 'block',
              gridTemplateColumns: isCardLayout ? gridTemplateColumns : 'none',
              gap: isCardLayout ? '20px' : 'none',
            }}
          >
            {value === 0
              ? filterProjects(filterMyProjects(projects)).map((project) => renderComponent(project))
              : filterProjects(projects).map((project) => renderComponent(project))}
          </Box>
        ) : (
          <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" flexGrow={1} padding="40px">
            <img src={nodatafoundImage} alt="No Data Found" style={{ width: '300px', marginBottom: '16px' }} />
            <Typography variant="h6" color="textSecondary">
              No data available
            </Typography>
          </Box>
        )}
      </Box>
      <ProjectDrawer
        drawerWidth={drawerWidth}
        drawerOpen={drawerOpen}
        toggleDrawer={toggleDrawer}
        handleSubmit={handleSubmit}
        handleDelete={handleDelete}
        currentProject={currentProject}
      />
      <ProjectViewDrawer
        drawerWidth={drawerWidth}
        drawerOpen={viewDrawerOpen}
        toggleDrawer={toggleViewDrawer}
        currentProject={currentProject}
        allUsers={allUsers} // Pass allUsers to ProjectViewDrawer
      />
    </Box>
  );
};

export default Projectscont;

import React, { useState, useEffect } from 'react';
import { Box, Typography, List, ListItem, Divider, Drawer, Avatar, Chip, Button } from '@mui/material';
import { Close as CloseIcon, NoteAdd as NoteAddIcon, Pending as PendingIcon, People as PeopleIcon, Link as LinkIcon, Create as CreateIcon, Description as DescriptionIcon } from '@mui/icons-material';
import StudentGetUser from 'utilities/api/StudentAllUserAPIs';
import ImageBaseURL from 'utilities/api/axios';

const progress = {
  1: 'Archived',
  2: 'In Progress',
  3: 'Completed'
};

const type = {
  1: 'Academic',
  2: 'Research',
  3: 'Personal',
  4: 'Other'
};

const ProjectViewDrawer = ({ drawerWidth, drawerOpen, toggleDrawer, currentProject }) => {
  const [currentDrawerWidth, setCurrentDrawerWidth] = useState(drawerWidth);
  const [isResizing, setIsResizing] = useState(false);
  const [creator, setCreator] = useState(null);
  const [teammates, setTeammates] = useState([]);

  const handleMouseDown = () => {
    setIsResizing(true);
  };

  const handleMouseMove = (e) => {
    if (isResizing) {
      const newWidth = window.innerWidth - e.clientX;
      if (newWidth > 300) { // Minimum width constraint
        setCurrentDrawerWidth(newWidth);
      }
    }
  };

  const handleMouseUp = () => {
    setIsResizing(false);
  };

  useEffect(() => {
    if (isResizing) {
      document.addEventListener('mousemove', handleMouseMove);
      document.addEventListener('mouseup', handleMouseUp);
    } else {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    }
    return () => {
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isResizing]);

  useEffect(() => {
    const fetchUserData = async (username) => {
      try {
        const userData = await StudentGetUser.UserDetailsGet(username);
        return userData[0]; // Assuming the API returns an array and we take the first item
      } catch (error) {
        console.error(`Error fetching data for ${username}:`, error);
        return null;
      }
    };

    const fetchProjectUsers = async () => {
      if (currentProject) {
        // Fetch creator data
        if (currentProject.student) {
          const creatorData = await fetchUserData(currentProject.student);
          setCreator(creatorData);
        }

        // Fetch teammates data
        if (currentProject.teammates_names && currentProject.teammates_names.length > 0) {
          const teammateData = await Promise.all(
            currentProject.teammates_names.map(async (username) => {
              const data = await fetchUserData(username);
              return data;
            })
          );
          setTeammates(teammateData.filter(Boolean)); // Filter out any null values
        }
      }
    };

    fetchProjectUsers();
  }, [currentProject]);

  return (
    <Drawer anchor="right" open={drawerOpen} onClose={() => toggleDrawer(false)} sx={{ zIndex: 101 }}>
      <Box sx={{ height: '100vh', width: currentDrawerWidth, padding: 2, display: 'flex', flexDirection: 'column', position: 'relative' }} role="presentation">
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '16px 24px', backgroundColor: '#2c387e', color: 'white' }}>
          <Typography variant="h6">Project Details</Typography>
          <Button onClick={() => toggleDrawer(false)} sx={{ color: 'white' }}>
            <CloseIcon />
          </Button>
        </Box>
        <Box sx={{ padding: 2 }}>
          {currentProject && (
            <List>
              <ListItem>
                <NoteAddIcon />
                <Typography variant="body2" sx={{ marginLeft: 2 }}>{`Type of Project: ${type[currentProject.type]}`}</Typography>
              </ListItem>
              <ListItem>
                <PendingIcon />
                <Typography variant="body2" sx={{ marginLeft: 2 }}>{`Progress Status: ${progress[currentProject.progress_status]}`}</Typography>
              </ListItem>
              <ListItem>
                <LinkIcon />
                <Typography variant="body2" sx={{ marginLeft: 2 }}>{`Links/Documents: ${currentProject.links || 'N/A'}`}</Typography>
              </ListItem>
              <ListItem>
                <CreateIcon />
                <Typography variant="body2" sx={{ marginLeft: 2 }}>{`Title: ${currentProject.title}`}</Typography>
              </ListItem>
              <ListItem>
                <DescriptionIcon />
                <Typography variant="body2" sx={{ marginLeft: 2 }}>{`Description: ${currentProject.description}`}</Typography>
              </ListItem>
              {creator && (
                <ListItem>
                  <PeopleIcon />
                  <Typography variant="body2" sx={{ marginLeft: 2 }}>{`Creator:`}</Typography>
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginLeft: 2 }}>
                    <Chip
                      key={creator.id}
                      avatar={<Avatar src={creator.profile_pic ? `${ImageBaseURL.defaults.baseURL}${creator.profile_pic}` : ''} />}
                      label={`${creator.name || creator.username} (${creator.username})`}
                    />
                  </Box>
                </ListItem>
              )}
              <ListItem>
                <PeopleIcon />
                <Typography variant="body2" sx={{ marginLeft: 2 }}>{`Teammates:`}</Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginLeft: 2 }}>
                  {teammates.map(teammate => (
                    <Chip
                      key={teammate.id}
                      avatar={<Avatar src={teammate.profile_pic ? `${ImageBaseURL.defaults.baseURL}${teammate.profile_pic}` : ''} />}
                      label={`${teammate.name || teammate.username} (${teammate.username})`}
                    />
                  ))}
                </Box>
              </ListItem>
            </List>
          )}
        </Box>
        <Box
          sx={{
            width: '5px',
            backgroundColor: '#ddd',
            cursor: 'ew-resize',
            height: '100%',
            position: 'absolute',
            left: 0,
            top: 0,
            zIndex: 102,
          }}
          onMouseDown={handleMouseDown}
        />
      </Box>
    </Drawer>
  );
};

export default ProjectViewDrawer;

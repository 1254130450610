import React, { useState } from 'react';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  Checkbox,
  Select,
  MenuItem,
  TableSortLabel
} from '@mui/material';

const TableListHeader = () => {
  const [category, setCategory] = useState('');

  const handleChange = (event) => {
    setCategory(event.target.value);
  };

  return (
    <Table sx={{ width: '100%' }}>
      <TableHead>
        <TableRow
          sx={{
            display: 'flex',
            alignItems: 'center',
            bgcolor: '#F7F7F7',
            borderBottom: 'light #F2F2F2 1px ',
            // textAlign: 'center',
          }}
        >
          {/* <TableCell padding="checkbox" sx={{ width: '5%', padding: 'none', borderBottom: 'none' }}>
            <Checkbox />
          </TableCell> */}
          <TableCell sx={{  width: '25%', padding: '0.875rem 0.75rem', borderBottom: '1px solid #F2F2F2', display: 'flex', alignItems: 'center' }}>
            <TableSortLabel hideSortIcon sx={{ color: '#5C5C5C', font: 'Roboto', fontWeight: '500', fontSize: '20px', lineHeight: '20px' }}>
              Item Details
            </TableSortLabel>
          </TableCell>
          <TableCell sx={{ width: '25%', padding: '0.875rem 0.75rem', borderBottom: '1px solid #F2F2F2' }}>
            <TableSortLabel hideSortIcon sx={{ color: '#5C5C5C', fontWeight: '500', fontSize: '20px', lineHeight: '20px' }}>
              Category
            </TableSortLabel>
          </TableCell>
          <TableCell sx={{ width: '30%', padding: '0.875rem 0.75rem', borderBottom: '1px solid #F2F2F2' }}>
            <TableSortLabel hideSortIcon sx={{ color: '#5C5C5C', fontWeight: '500', fontSize: '20px', lineHeight: '20px' }}>
              Description
            </TableSortLabel>
          </TableCell>
          <TableCell sx={{ width: '10%', padding: '0.875rem 0.75rem', borderBottom: '1px solid #F2F2F2' }}>
            <TableSortLabel hideSortIcon sx={{ color: '#5C5C5C', fontWeight: '500', fontSize: '20px', lineHeight: '20px' }}>
              Stock
            </TableSortLabel>
          </TableCell>
          <TableCell sx={{ width: '10%', padding: '0.875rem 0.75rem', borderBottom: '1px solid #F2F2F2' }}>
            <TableSortLabel hideSortIcon sx={{ color: '#5C5C5C', fontWeight: '500', fontSize: '20px', lineHeight: '20px' }}>
              Action
            </TableSortLabel>
          </TableCell>
        </TableRow>
      </TableHead>
    </Table>
  );
};

export default TableListHeader;


// import React, { useState } from 'react';
// import {
//   Table,
//   TableHead,
//   TableRow,
//   TableCell,
//   Checkbox,
//   Select,
//   MenuItem,
//   TableSortLabel
// } from '@mui/material';

// const TableListHeader = () => {
//   const [category, setCategory] = useState('');

//   const handleChange = (event) => {
//     setCategory(event.target.value);
//   };

//   return (
//     <Table sx={{ width: '100%', height: '5rem' }}> {/* Table should take full width of its parent */}
//       <TableHead>
//         <TableRow sx={{
//           display: 'flex',
//           alignItems: 'center',
//           bgcolor: '#F7F7F7',
//           borderBottom : 'light #F2F2F2 1px '
//         }}>
//           <TableCell padding="checkbox" sx={{ width: '5%', padding: 'none', borderBottom: 'none' }}>
//             <Checkbox />
//           </TableCell>
//           <TableCell sx={{ width: '25%', padding: '0.875rem 0.75rem', borderBottom: '1px solid #F2F2F2' }}>
//           <TableSortLabel hideSortIcon sx={{ color: '#5C5C5C', font: 'Roboto', fontWeight: '500', fontSize: '20px', lineHeight: '20px' }}>
//               Item Details
//             </TableSortLabel>
//           </TableCell>
//           {/* <TableCell sx={{ width: '8%', padding: '0.875rem 0.75rem', borderBottom: '1px solid #F2F2F2' }}> */}
//           {/* <TableSortLabel hideSortIcon sx={{ color: '#5C5C5C', font: 'Roboto', fontWeight: '500', fontSize: '20px', lineHeight: '20px' }}>
//               Group
//             </TableSortLabel> */}
//           {/* </TableCell> */}
//           {/* <TableCell sx={{ width: '12%', padding: '0.875rem 0.75rem', borderBottom: '1px solid #F2F2F2' }}>
//             <Select
//               value={category}
//               onChange={handleChange}
//               displayEmpty
//               bo
//               sx={{ 
//                 marginLeft:"-2vw",
//                 fontSize: '20px',
//                 fontWeight: '500',
//                 letterSpacing: '0em',
//                 textAlign: 'left',
//                 color: '#5C5C5C',
//                 alignItems:'center',
//                 border:'none',
//                 width: '100%', '.MuiSelect-select': { py: 0, pl: 1, pr: '24px'},
//                 width: '100%',
//     '.MuiOutlinedInput-notchedOutline': {
//       border: 'none', // Removes the border
//     },
//     '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
//       border: 'none', // Removes the border on focus
//     },
//               }}
//             >
//               <MenuItem value="" disabled >Category</MenuItem>
//               <MenuItem value={'Electronics'}>Electronics</MenuItem>
//               <MenuItem value={'Furniture'}>Furniture</MenuItem>
//               <MenuItem value={'Clothing'}>Clothing</MenuItem>
//               {/* Add more `MenuItem` components as needed */}
//             {/* </Select> */}
//           {/* </TableCell>  */}
//           <TableCell sx={{ width: '52%', padding: '0.875rem 0.75rem', borderBottom: '1px solid #F2F2F2' }}>
//           <TableSortLabel hideSortIcon sx={{ color: '#5C5C5C', fontWeight: '500', fontSize: '20px', lineHeight: '20px' }}>
//                 Category
//             </TableSortLabel>
//           </TableCell>
//           <TableCell sx={{ width: '52%', padding: '0.875rem 0.75rem', borderBottom: '1px solid #F2F2F2' }}>
//           <TableSortLabel hideSortIcon sx={{ color: '#5C5C5C', fontWeight: '500', fontSize: '20px', lineHeight: '20px' }}>
//                 Description
//             </TableSortLabel>
//           </TableCell>
//           <TableCell sx={{ width: '18%', padding: '0.875rem 0.75rem', borderBottom: '1px solid #F2F2F2' }}>
//           <TableSortLabel hideSortIcon sx={{ color: '#5C5C5C', fontWeight: '500', fontSize: '20px', lineHeight: '20px' }}>
//                 Stock
//             </TableSortLabel>
//           </TableCell>
          
//           {/* <TableCell sx={{ width: '12%', padding: '0.875rem 0.75rem', borderBottom: '1px solid #F2F2F2' }}>
//           <TableSortLabel hideSortIcon sx={{ color: '#5C5C5C', fontWeight: '500', fontSize: '20px', lineHeight: '20px' }}>
//                 Date
//             </TableSortLabel>
//           </TableCell> */}
//           <TableCell sx={{ width: '14%', padding: '0.875rem 0.75rem', borderBottom: '1px solid #F2F2F2' }}>
//           <TableSortLabel hideSortIcon sx={{ color: '#5C5C5C', fontWeight: '500', fontSize: '20px', lineHeight: '20px' }}>
//                 Action
//             </TableSortLabel>
//           </TableCell>
//         </TableRow>
//       </TableHead>
//     </Table>
//   );
// };

// export default TableListHeader;

import React, { useEffect, useState } from 'react';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Button } from '@mui/material';
import BookMachineAPIs from 'utilities/api/StudentBookMachine';
import IssueInventoryAPIs from 'utilities/api/StudentIssueInventory';
import PurchaseInventoryAPIs from 'utilities/api/StudentPurchaseInventory';
import { useNavigate } from 'react-router-dom';
import nodatafoundImage from '../../../utilities/dummy_assets/nodatafound.jpg'; // Adjust the path accordingly

const formatDate = (dateString) => {
    const date = new Date(dateString);
    const formattedDate = date.toISOString().split('T')[0];
    const formattedTime = date.toTimeString().split(' ')[0];
    return `${formattedDate}, ${formattedTime}`;
};

const HistoryTables = ({ user }) => {
    const [tableData1, setTableData1] = useState([]);
    const [tableData2, setTableData2] = useState([]);
    const [tableData3, setTableData3] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const machinesData = await BookMachineAPIs.ReservedMachineGet();
                const filteredMachines = machinesData.filter(machine => machine.reserved_by === user.id);
    
                const issuesData = await IssueInventoryAPIs.IssuedInventoryGet();
                const filteredIssues = issuesData.filter(issue => issue.issued_by === user.id);
    
                const purchasesData = await PurchaseInventoryAPIs.PurchasedInventoryGet();
                const filteredPurchases = purchasesData.filter(purchase => purchase.purchased_by === user.id);
    
                setTableData1(filteredMachines);
                setTableData2(filteredIssues);
                setTableData3(filteredPurchases);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
    
        fetchData();
    }, [user]);
    
    const renderNoData = (message) => (
        <TableRow>
            <TableCell colSpan={4} style={{ textAlign: 'center', padding: '30px', border:'0px ' }}>
                <Box display="flex" justifyContent="center" alignItems="center">
                    <img src={nodatafoundImage} alt="No Data Found" style={{ width: '160px', marginRight: '16px' }} />
                    <Typography variant="body1" color="textSecondary">
                        {message}
                    </Typography>
                </Box>
            </TableCell>
        </TableRow>
    );

    return (
        <Box style={{ marginTop: '24px', backgroundColor: '#F9FAFE', border: '1px solid #d3d3d3', borderRadius: '24px', padding: '12px 16px', alignItems: 'center', flex: 1 }}>
            <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '16px' }}>
                <Box style={{ flex: 1, display: 'flex', justifyContent: 'flex-start' }}>
                    <Button onClick={() => navigate("/records")} variant="contained" style={{ backgroundColor: '#FFA726', color: 'white', maxWidth: '200px' }}>
                        Explore Now
                    </Button>
                </Box>
                <Typography variant="h6" style={{ fontSize: '24px', fontWeight: '700', color: '#000', textAlign: 'center', flex: 1 }}>
                    Your History
                </Typography>
                <Box style={{ flex: 1, display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Box style={{ display: 'flex', alignItems: 'center', marginRight: '16px' }}>
                        <Box style={{ width: '20px', height: '20px', backgroundColor: '#FFECB3', marginRight: '8px' }}></Box>
                        <Typography variant="subtitle2" style={{ color: '#000' }}>Bookings</Typography>
                    </Box>
                    <Box style={{ display: 'flex', alignItems: 'center', marginRight: '16px' }}>
                        <Box style={{ width: '20px', height: '20px', backgroundColor: '#E3F2FD', marginRight: '8px' }}></Box>
                        <Typography variant="subtitle2" style={{ color: '#000' }}>Issuables</Typography>
                    </Box>
                    <Box style={{ display: 'flex', alignItems: 'center' }}>
                        <Box style={{ width: '20px', height: '20px', backgroundColor: '#FCE4EC', marginRight: '8px' }}></Box>
                        <Typography variant="subtitle2" style={{ color: '#000' }}>Purchases</Typography>
                    </Box>
                </Box>
            </Box>
            <Box style={{ display: 'flex', justifyContent: 'space-between', padding: '8px', width: '100%' }}>
                <TableContainer component={Paper} style={{ width: '30%', border: '1px solid #FFE082', borderRadius: '12px', minHeight: '300px', maxHeight: '350px' }}>
                    <Table>
                        <TableHead style={{ backgroundColor: '#FFECB3' }}>
                            <TableRow>
                                <TableCell style={{ borderBottom: '1px solid #5a5a5a', textAlign: 'center' }}>Item Name</TableCell>
                                <TableCell style={{ borderBottom: '1px solid #5a5a5a', textAlign: 'center' }}>Status</TableCell>
                                <TableCell style={{ borderBottom: '1px solid #5a5a5a', textAlign: 'center' }}>Due Date</TableCell>
                                <TableCell style={{ borderBottom: '1px solid #5a5a5a', textAlign: 'center' }}>Time</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableData1.length > 0 ? (
                                tableData1.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell style={{ padding: '15px', textDecoration: 'underline', color: '#151515', fontWeight: '500', textAlign: 'center' }}>{row.name}</TableCell>
                                        <TableCell style={{ padding: '15px', textDecoration: 'underline', color: '#151515', fontWeight: '500', textAlign: 'center' }}>{row.approved}</TableCell>
                                        <TableCell style={{ padding: '15px', textAlign: 'center', color: '#151515', fontWeight: '500', textAlign: 'center' }}>{row.reserved_date}</TableCell>
                                        <TableCell style={{ padding: '15px', textAlign: 'center', color: '#151515', fontWeight: '500', textAlign: 'center' }}>{new Date(row.start_time).toLocaleTimeString()}</TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                renderNoData('No bookings found')
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TableContainer component={Paper} style={{ width: '36%', border: '1px solid #BBDEFB', borderRadius: '12px', minHeight: '300px', maxHeight: '350px' }}>
                    <Table>
                        <TableHead style={{ backgroundColor: '#E3F2FD' }}>
                            <TableRow>
                                <TableCell style={{ borderBottom: '1px solid #5a5a5a', textAlign: 'center' }}>Item Name</TableCell>
                                <TableCell style={{ borderBottom: '1px solid #5a5a5a', textAlign: 'center' }}>Picked</TableCell>
                                <TableCell style={{ borderBottom: '1px solid #5a5a5a', textAlign: 'center' }}>Returned</TableCell>
                                <TableCell style={{ borderBottom: '1px solid #5a5a5a', textAlign: 'center' }}>Due Date</TableCell>
                                <TableCell style={{ borderBottom: '1px solid #5a5a5a', textAlign: 'center' }}>Category</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableData2.length > 0 ? (
                                tableData2.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell style={{ padding: '15px', textDecoration: 'underline', color: '#151515', fontWeight: '500', textAlign: 'center' }}>{row.name}</TableCell>
                                        <TableCell style={{ padding: '15px', textDecoration: 'underline', color: '#151515', fontWeight: '500', textAlign: 'center' }}>{row.pickup_}</TableCell>
                                        <TableCell style={{ padding: '15px', textDecoration: 'underline', color: '#151515', fontWeight: '500', textAlign: 'center' }}>{row.returned_}</TableCell>
                                        <TableCell style={{ padding: '15px', textAlign: 'center', color: '#151515', fontWeight: '500' }}>{row.issued_from}</TableCell>
                                        <TableCell style={{ padding: '15px', textAlign: 'center', color: '#151515', fontWeight: '500' }}>{row.project_name}</TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                renderNoData('No issues found')
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TableContainer component={Paper} style={{ width: '30%', border: '1px solid #F8BBD0', borderRadius: '12px', minHeight: '300px', maxHeight: '350px' }}>
                    <Table>
                        <TableHead style={{ backgroundColor: '#FCE4EC' }}>
                            <TableRow>
                                <TableCell style={{ borderBottom: '1px solid #5a5a5a', textAlign: 'center' }}>Item Name</TableCell>
                                <TableCell style={{ borderBottom: '1px solid #5a5a5a', textAlign: 'center' }}>Picked</TableCell>
                                <TableCell style={{ borderBottom: '1px solid #5a5a5a', textAlign: 'center' }}>Date of Purchase</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableData3.length > 0 ? (
                                tableData3.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell style={{ padding: '15px', textDecoration: 'underline', color: '#151515', fontWeight: '500', textAlign: 'center' }}>{row.name}</TableCell>
                                        <TableCell style={{ padding: '15px', textAlign: 'center', color: '#151515', fontWeight: '500', textAlign: 'center' }}>{row.pickup_}</TableCell>
                                        <TableCell style={{ padding: '15px', textAlign: 'center', color: '#151515', fontWeight: '500', textAlign: 'center' }}>{formatDate(row.purchase_datetime)}</TableCell>
                                    </TableRow>
                                ))
                            ) : (
                                renderNoData('No purchases found')
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
};

export default HistoryTables;

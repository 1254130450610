import React, { useState } from "react";
import { Box, Button, Icon, TextField, Typography, Snackbar, Alert } from "@mui/material";
import sideimage from "../../utilities/dummy_assets/commonlogin.png";
import mapitLoginLogo from "../../utilities/dummy_assets/mapitLoginLogo.png";
import YourLogo from "../../utilities/dummy_assets/MBF_Logo.png";
import smallLogo from "../../utilities/dummy_assets/MBF_Logo_2020 1.png";
import rectanleImg from "../../utilities/dummy_assets/Rectangle 4165.png";
import { useNavigate } from "react-router-dom";
import { ListItem, ListItemText, List } from "@mui/material";
import expandleft from "../../utilities/dummy_assets/Expand_left.png";
import googleIcon from "../../utilities/dummy_assets/logos_google-icon.png";
import SideBarComponent from "../../components/auth/login/SideBar";
import * as Yup from "yup";
import { useFormik } from "formik";
import Auth_API from "utilities/api/auth_api";
import { useDispatch } from "react-redux";
import { getUserSuccess } from "../../redux/slices/userSlice";

const typographyStyle = {
    color: "var(--Primary-Primary03, #5C5C5C)",
    textAlign: "center",
    fontFamily: "Inter",
    fontSize: "1.5rem",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    letterSpacing: "-0.0165rem",
};
const boxStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    gap: ".6rem",
    alignSelf: "stretch",
};
const styledTextfield = {
    display: "flex",
    gap: "0.2rem",
    alignSelf: "stretch",
    borderRadius: "0.75rem",
};

const validation_schema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Required"),
    password: Yup.string().required("Required"),
});

const StudentLoginPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [openSnackbar, setOpenSnackbar] = useState(false); // State to control Snackbar visibility
    const [snackbarMessage, setSnackbarMessage] = useState(""); // State to control Snackbar message

    const handleSnackbarClose = () => {
        setOpenSnackbar(false);
    };

    const formik = useFormik({
        initialValues: {
            email: "",
            password: "",
        },
        validationSchema: validation_schema,
        onSubmit: (values) => {
            console.log(JSON.stringify(values, null, 2));
            Auth_API.studentLogin(values)
                .then((res) => {
                    console.log("StudentLoginPage:", res);
                    dispatch(getUserSuccess(res.data));
                    navigate("/dashboard");
                })
                .catch((error) => {
                    console.log(error);
                    setSnackbarMessage("Wrong credentials. Please try again.");
                    setOpenSnackbar(true); // Show Snackbar on error
                });
        },
    });

    return (
        <Box sx={{ display: "flex" }}>
            <SideBarComponent />
            <Box
                sx={{
                    width: "100%",
                    backgroundImage: `url(${sideimage})`,
                    backgroundSize: "cover", // Set background size to cover
                    backgroundRepeat: "no-repeat",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        padding: "1rem",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: "0.75rem",
                        borderRadius: "2rem",
                        background: "#FFF",
                        boxShadow: "4px 4px 20px 0px rgba(0, 0, 0, 0.10)",
                        margin: "auto",
                        height: "auto",
                        width: "26rem",
                        marginTop: "10rem",
                    }}
                >
                    <Typography
                        sx={{
                            color: "var(--Primary-Primary01, #212121)",
                            textAlign: "center",
                            fontFamily: "Inter",
                            fontSize: "1.75rem",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "normal",
                            letterSpacing: " -0.02475rem",
                        }}
                    >
                        Get Started
                    </Typography>
                    <Box
                        component="form"
                        onSubmit={formik.handleSubmit}
                        sx={{
                            display: "flex",
                            padding: "1.5rem",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "1.5rem",
                            borderRadius: "1rem",
                            background: "#FFF",
                        }}
                    >
                        <Box
                            sx={{
                                display: "flex",
                                height: "2rem",
                                padding: "1rem",
                                justifyContent: "center",
                                alignItems: "center",
                                gap: "1rem",
                                alignSelf: "stretch",
                                borderRadius: "0.75rem",
                                border: "1px solid var(--Primary-Primary01, #212121)",
                                background: "var(--Primary-white, #FFF)",
                                boxShadow:
                                    "4px 4px 16px 0px rgba(0, 0, 0, 0.10)",
                            }}
                        >
                            <img src={googleIcon} />
                            <Typography
                                sx={{
                                    color: "var(--Primary-Primary01, #212121)",
                                    textAlign: "center",
                                    fontFamily: "Inter",
                                    fontSize: "1.25rem",
                                    fontStyle: "normal",
                                    fontWeight: 600,
                                    lineHeight: "normal",
                                    letterSpacing: "-0.01375rem",
                                }}
                            >
                                Continue with Google
                            </Typography>
                        </Box>
                        <Box
                            sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                gap: "1.2rem",
                                alignSelf: "stretch",
                            }}
                        >
                            <Box sx={boxStyle}>
                                <Typography sx={typographyStyle}>
                                    Email Address
                                </Typography>
                                <TextField
                                    id="email"
                                    name="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.email &&
                                        Boolean(formik.errors.email)
                                    }
                                    helperText={
                                        formik.touched.email &&
                                        formik.errors.email
                                    }
                                    sx={styledTextfield}
                                    placeholder="enter email address"
                                />
                            </Box>
                            <Box sx={boxStyle}>
                                <Typography sx={typographyStyle}>
                                    Password
                                </Typography>
                                <TextField
                                    id="password"
                                    name="password"
                                    type="password"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    error={
                                        formik.touched.password &&
                                        Boolean(formik.errors.password)
                                    }
                                    helperText={
                                        formik.touched.password &&
                                        formik.errors.password
                                    }
                                    sx={styledTextfield}
                                    placeholder="enter password"
                                />
                            </Box>
                            <Typography
                                sx={
                                    typographyStyle && {
                                        fontSize: "1rem",
                                        letterSpacing: "-0.01375rem",
                                        alignSelf:'end'
                                    }
                                }
                            >
                                <span
                                    onClick={() => navigate("/forgot_password")}
                                    style={{
                                        color: "var(--Accent-Accent-01, #0062CC)",
                                        cursor: "pointer",
                                    }}
                                >
                                    Forgot Password?
                                </span>
                            </Typography>
                            <Button
                                type="submit"
                                sx={{
                                    display: "flex",
                                    padding: "1rem",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "0.5rem",
                                    alignSelf: "stretch",
                                    borderRadius: "0.75rem",
                                    border: "1px solid var(--Primary-Primary01, #212121)",
                                    background:
                                        "var(--Accent-Accent-00, #007BFF)",
                                }}
                            >
                                <Typography
                                    sx={{
                                        color: "var(--Primary-white, #FFF)",
                                        textAlign: "center",
                                        fontFamily: "Inter",
                                        fontSize: "1.25rem",
                                        fontStyle: "normal",
                                        fontWeight: 600,
                                        lineHeight: "normal",
                                        letterSpacing: "-0.01375rem",
                                    }}
                                >
                                    Login as Student
                                </Typography>
                            </Button>
                        </Box>

                        <Box
                            sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                alignSelf: "stretch",
                            }}
                        >
                            <img
                                src={expandleft}
                                onClick={() => navigate("/login")}
                                style={{
                                    cursor: "pointer",
                                }}
                            />
                            <Typography
                                onClick={() => navigate("/login")}
                                sx={
                                    typographyStyle && {
                                        fontSize: "1rem",
                                        letterSpacing: "-0.01375rem",
                                        color: "var(--Accent-Accent-00, #007BFF)",
                                        marginRight: "50px",
                                        cursor: "pointer",
                                    }
                                }
                            >
                                Back
                            </Typography>
                            <Typography
                                sx={
                                    typographyStyle && {
                                        fontSize: "1rem",
                                        letterSpacing: "-0.01375rem",
                                    }
                                }
                            >
                                Don’t have an account?{" "}
                                <span
                                    onClick={() => navigate("/register")}
                                    style={{
                                        color: "var(--Accent-Accent-01, #0062CC)",
                                        cursor: "pointer",
                                    }}
                                >
                                    Register here
                                </span>
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Snackbar
                open={openSnackbar}
                autoHideDuration={6000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert onClose={handleSnackbarClose} severity="error" sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>

        </Box>
    );
};
export default StudentLoginPage;

import { axiosApiService } from "./axios";

const PurchaseInventoryAPIs = {
  PurchaseInventory: async function(accessToken, values) {
    if (!accessToken) {
      throw new Error('Access token is missing.');
    }

    try {
      const response = await axiosApiService.post(`/inventory_purchase_list/`, values, {
        // headers: {
        //   'Authorization': `Bearer ${accessToken}`,
        //   'Content-Type': 'application/json'
        // } 
      });

      console.log('Response:', response.data);
      return response.data; // Assuming the server returns the data
    } catch (error) {
      console.error('Error purchasing inventory:', error);
      throw error;
    }
  },
  PurchasedInventoryGet: async function() {
    try {
      const response = await axiosApiService.get(`/inventory_purchase_list/`);
      console.log(response);
      return response.data; 
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  },
  PurchasedInventoryUpdate: async function(id, updateData) {
    try {
      const response = await axiosApiService.patch(`/inventory_purchase_detail/${id}/`, updateData);
      return response.data
    } catch (error) {
      throw error;
    }
  },

  PurchasedInventoryDelete: async function(id) {
    try {
      const response = await axiosApiService.delete(`/inventory_purchase_detail/${id}/`);
      return response.data; 
    } catch (error) {
      throw error;
    }
  }
};

export default PurchaseInventoryAPIs;







// GET REQUEST
// import { axiosApiService } from "./axios";

// const PurchaseInventoryAPIs = {
//     PurchaseInventory: async function(accessToken, values) {
//         if (!accessToken) {
//             throw new Error('Access token is missing.');
//         }

//         try {
//             console.log(values);
//             const response = await axiosApiService.get(`/inventory_purchase_list/`, {
//               headers: {
//                 Authorization: `Bearer ${accessToken}`
//               }
//             });
//             console.log(response);
//             return response.data; // Assuming the server returns the data
//           } catch (error) {
//             console.error('Error fetching machine data:', error);
//             throw error;
//           }
//         },
// };

// export default PurchaseInventoryAPIs;
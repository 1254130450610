import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
    name: "user",
    initialState: {
        access: "",
        refresh: "",
        user: {},
        error: "",
    },
    reducers: {
        getUserSuccess: (state, action) => {
            console.log(action.payload);
            Object.assign(state, action.payload);
            if (state.user && state.user.TL_name === 'Org-Admin') {
                state.user.is_org_admin = true;
            }
        },
        getUserFail: (state, action) => {
            state.error = action.payload;
        },
        updateUser: (state, action) => {
            // state.user = action.payload;
            if (state.user.is_org_admin) {
                state.user = action.payload;
                state.user.is_org_admin = true;
            }
            else{
                state.user = action.payload;
            }
        },
        logoutUser: (state, action) => {
            Object.assign(state, {
                access: "",
                refresh: "",
                user: {},
                error: "",
            });
        },
        updateTL: (state, action) => {
            if (state.user) {
                state.user.TL_name = action.payload.TL;
            }
        },
        updateAccessToken: (state, action) => {
            state.access = action.payload;
        },
    },
});

export const { getUserSuccess, getUserFail, updateUser, logoutUser, updateTL, updateAccessToken } = userSlice.actions;
export default userSlice;




// import { createSlice } from "@reduxjs/toolkit";

// const userSlice = createSlice({
//     name: "user",
//     initialState: {
//         access: "",
//         refresh: "",
//         user: {},
//         error: "",
//     },
//     reducers: {
//         getUserSuccess: (state, action) => {
//             console.log(action.payload);
//             // The following syntax is necessary for a proper redux state tree. If not assigned via object assignment, then the
//             // entire payload is put within the state.user object instead. Which is not the intended logical heirarchy of the
//             // state tree.
//             Object.assign(state, action.payload);
//         },
//         getUserFail: (state, action) => {
//             state.error = action.payload;
//         },
//         updateUser: (state, action) => {
//             state.user = action.payload;
//         },
//         logoutUser: (state, action) => {
//             Object.assign(state, {
//                 access: "",
//                 refresh: "",
//                 user: {},
//                 error: "",
//             });
//         },
//     },
// });

// export const { getUserSuccess, getUserFail, updateUser, logoutUser } = userSlice.actions;
// export default userSlice;

import React from 'react';
import { Dialog, Typography, Box, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Height } from '@mui/icons-material';
import ImageBaseURL from 'utilities/api/axios';

const EquipmentDetails = ({ open, onClose, machineDetails }) => {
  const dialogStyles = {
    '& .MuiDialog-container': {
      alignItems: 'center',
    },
    '& .MuiPaper-root': {
      display: 'flex',
      flexDirection: 'row',
      borderRadius: '30px',
      border: '1px solid #A6A6A6',
      background: '#F2F3FC',
      boxShadow: '10px 10px 24px rgba(0, 0, 0, 0.1)',
      overflow: 'hidden',
      maxWidth: '600px',
      position: 'relative', // Added relative position for the dialog itself
    },
  };

  const containerStyles = {
    display: 'flex',
    flexDirection: 'column',
    padding: '15px',
    width: '100%',
  };

  const leftSectionStyles = {
    alignSelf:'center',
    padding: '8px',
    display: 'flex',
    flexDirection: 'column',
    flex: '0 0 33.33%',
  };

  const rightSectionStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignContent: 'end',
  };

  const imageStyles = {
    objectFit: 'fill',
    maxWidth:'300px',
    minHeight:'250px',
    height:'auto',
    borderRadius: '10px',
    border: '1px solid rgb(39,63,119)'
  };

  const detailBoxStyles = {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '10px',
    gap: '4px',
    marginTop: '10px',
  };

  const machineNameStyles = {
    // color: '#3233fbad',
    color: 'rgb(92,109,170)',
    marginBottom: '5px',
    fontFamily: 'Inter',
    fontSize: '2rem',
    fontWeight: '600',
  };

  const machineDetailStyles = {
    fontFamily: 'Inter',
    color: '#5e5e5e',
    fontSize: '0.9rem',
    fontWeight: '400',
  };

  const boldTextStyles = {
    fontWeight: 'bold',
  };

  const backgroundBoxStyles = {
    display: 'flex',
    flexDirection: 'row',
    gap: '15px',
    // backgroundColor: 'rgba(5, 103, 175, 0.09)',
    backgroundColor: 'rgba(221,223,243)',
    borderRadius: '20px',
    border: '1px solid #1a2dff61',
    width: '100%',
    maxHeight: '350px',
  };
  
  // const baseURL = 'https://staging.misadmin.mapit.ai/';

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      sx={dialogStyles}
    >
      {/* Close Button */}
      <IconButton
        aria-label="close"
        sx={{
          position: 'absolute',
          //   right: '10px',
          right: '4%',
          top: '8%',
          color: '#757575',
          background: 'rgb(244,244,244)',
          border: '1px solid rgb(39,63,119)'
        }}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>

      <Box sx={containerStyles}>
        <Box sx={backgroundBoxStyles}>
          {/* Left Section - Image */}
          <Box sx={leftSectionStyles}>
            <Box
              component="img"
              sx={imageStyles}
              src={machineDetails?.image ? `${ImageBaseURL.defaults.baseURL}${machineDetails.image}` : "./machine.png"}
              alt={machineDetails?.name}
            />
          </Box>

          {/* Right Section - Parameters */}
          <Box sx={rightSectionStyles}>
            <Box sx={detailBoxStyles}>
              <Typography sx={machineNameStyles}>
                {machineDetails?.name}
              </Typography>
              <Typography variant="body1" sx={machineDetailStyles}>
                <span style={boldTextStyles}>Category</span>: {machineDetails?.category || 'N/A'}
              </Typography>
              <Typography variant="body1" sx={machineDetailStyles}>
                <span style={boldTextStyles}>Location</span>: {machineDetails?.location || 'N/A'}
              </Typography>
              <Typography variant="body1" sx={machineDetailStyles}>
                <span style={boldTextStyles}>UPC</span>: {machineDetails?.upc || 'N/A'}
              </Typography>
              <Typography variant="body1" sx={machineDetailStyles}>
                <span style={boldTextStyles}>Manufacturer</span>: {machineDetails?.manufacturer || 'N/A'}
              </Typography>
              <Typography variant="body1" sx={machineDetailStyles}>
                <span style={boldTextStyles}>Instances</span>: {machineDetails?.instances || 'N/A'}
              </Typography>
              <Typography variant="body1" sx={machineDetailStyles}>
                <span style={boldTextStyles}>Description</span>: {machineDetails?.description || 'N/A'}
              </Typography>
              <Typography variant="body1" sx={machineDetailStyles}>
                <span style={boldTextStyles}>Availability</span>: {machineDetails?.availability || 'N/A'}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};

export default EquipmentDetails;








// import React from 'react';
// import { Dialog, Typography, Box, IconButton } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';

// const EquipmentDetails = ({ open, onClose, machineDetails }) => {
//   return (
//     <Dialog
//       open={open}
//       onClose={onClose}
//       fullWidth={true}
//       maxWidth="md"
//       sx={{
//         '& .MuiDialog-container': {
//         //   alignItems: 'flex-start',
//             alignItems: 'center',
//         },
//         '& .MuiPaper-root': {
//           display: 'flex',
//           flexDirection: 'row',
//           borderRadius: '40px',
//           border: '1px solid #A6A6A6',
//           background: '#F2F3FC',
//           boxShadow: '10px 10px 24px rgba(0, 0, 0, 0.1)',
//           overflow: 'hidden',
//           maxWidth: '800px',
//         },
//       }}
//     >
//       <Box
//         sx={{
//           display: 'flex',
//           flexDirection: 'column',
//           gap: '24px',
//           padding: '24px',
//           width: '100%',
//         }}
//       >
//         <Box
//           sx={{
//             display: 'flex',
//             flexDirection: 'row',
//             gap: '24px',
//             // backgroundColor: 'rgba(13, 153, 255, .25)',
//             backgroundColor: 'rgba(5, 103, 175, 0.09)',
//             borderRadius: '10px',
//             border: '1px solid #1a2dff61',
//             // padding: '24px',
//             width: '100%',
//             maxHeight:'350px',
//           }}
//         >
//           {/* Left Section - Image */}
//           <Box
//             sx={{
//               display: 'flex',
//               flexDirection: 'column',
//               flex: '0 0 33.33%',
//             }}
//           >
//             <Box
//               component="img"
//               sx={{
//                 width: '100%',
//                 height: 'auto',
//                 objectFit: 'cover',
//                 borderRadius: '10px',
//               }}
//               src="popup.png" // Adjust the image source as per your project
//               alt="Machine"
//             />
//           </Box>

//           {/* Right Section - Parameters */}
//           <Box
//             sx={{
//               display: 'flex',
//               flexDirection: 'column',
//             //   flex: '0 0 66.67%',
//             alignContent:'start',
//             }}
//           >
//             {/* Close Button */}
//             <IconButton
//               aria-label="close"
//               sx={{
//                 position: 'absolute',
//                 right: '10px',
//                 top: '10px',
//                 color: '#757575',
//               }}
//               onClick={onClose}
//             >
//               <CloseIcon />
//             </IconButton>

//             {/* Machine Details */}
//             {/* Machine Details */}
//             <Box
//               sx={{
//                 display: 'flex',
//                 flexDirection: 'column',
//                 borderRadius: '10px',
//                 gap: '4px',
//                 marginTop: '10px',
//               }}
//             >
//               <Typography
//                 // variant="h6"
//                 sx={{
//                   color: '#3a77a3',
//                   marginBottom: '5px',
//                   fontFamily: 'Inter',
//                   fontSize: '24px',
//                   fontWeight: '600',
//                 //   textAlign: 'center',
//                 }}
//               >
//                 {machineDetails?.name}
//               </Typography>
//               <Typography
//                 variant="body1"
//                 sx={{
//                   fontFamily: 'Inter',
//                   fontSize: '12px',
//                   fontWeight: '400',
//                 }}
//               >
//                 <b>Category</b>: {machineDetails?.category || 'N/A'}
//               </Typography>
//               <Typography
//                 variant="body1"
//                 sx={{
//                   fontFamily: 'Inter',
//                   fontSize: '12px',
//                   fontWeight: '400',
//                 }}
//               >
//                 <b>Location</b>: {machineDetails?.location || 'N/A'}
//               </Typography>
//               <Typography
//                 variant="body1"
//                 sx={{
//                   fontFamily: 'Inter',
//                   fontSize: '12px',
//                   fontWeight: '400',
//                 }}
//               >
//                 <b>UPC</b>: {machineDetails?.upc || 'N/A'}
//               </Typography>
//               <Typography
//                 variant="body1"
//                 sx={{
//                   fontFamily: 'Inter',
//                   fontSize: '12px',
//                   fontWeight: '400',
//                 }}
//               >
//                 <b>Manufacturer</b>: {machineDetails?.manufacturer || 'N/A'}
//               </Typography>
//               <Typography
//                 variant="body1"
//                 sx={{
//                   fontFamily: 'Inter',
//                   fontSize: '12px',
//                   fontWeight: '400',
//                 }}
//               >
//                 <b>Instances</b>: {machineDetails?.instances || 'N/A'}
//               </Typography>
//               <Typography
//                 variant="body1"
//                 sx={{
//                   fontFamily: 'Inter',
//                   fontSize: '12px',
//                   fontWeight: '400',
//                 }}
//               >
//                 <b>Description</b>: {machineDetails?.description || 'N/A'}
//               </Typography>
//               <Typography
//                 variant="body1"
//                 sx={{
//                   fontFamily: 'Inter',
//                   fontSize: '12px',
//                   fontWeight: '400',
//                 }}
//               >
//                 <b>Availability</b>: {machineDetails?.availability || 'N/A'}
//               </Typography>
//             </Box>
//           </Box>
//         </Box>
//       </Box>
//     </Dialog>
//   );
// };

// export default EquipmentDetails;
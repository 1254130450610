import React, { useState, useEffect } from 'react';
import { Paper, TableContainer, Box } from '@mui/material';
import TableListHeader from './TableListHeader';
import TableListItem from './TableListItem';
import CustomPagination from '../../Pagination';
import InventoryDetailAPI from 'utilities/api/StudentInventoryDetail';
import { useSelector } from 'react-redux';
import FilterToolbar from './FilterToolbar';

const InventoryTableView = ({ data, setOpenDialog, searchQuery }) => {
  const { access } = useSelector((state) => state.user);

  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage, setProductsPerPage] = useState(10); // Default to 10 per page
  const [inventoryDetails, setInventoryDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedTags, setSelectedTags] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  // Fetch inventory details
  const fetchInventoryDetails = async () => {
    setLoading(true);
    try {
      const detailsPromises = data.map(product =>
        InventoryDetailAPI.InventoryDetailGet(access, product.id).then(details => ({
          [product.id]: details
        }))
      );
      const detailsArray = await Promise.all(detailsPromises);
      const detailsObject = Object.assign({}, ...detailsArray);
      setInventoryDetails(detailsObject);
    } catch (error) {
      setError('Failed to fetch inventory details');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchInventoryDetails();
  }, [access, data]);

  // Calculate total number of pages
  const totalItems = data.length;
  const totalPages = Math.ceil(totalItems / productsPerPage);

  // Get current products
  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;

  // Extract unique tags and categories for the filters
  const uniqueTags = [
    ...new Set(
      Object.values(inventoryDetails)
        .flatMap(details => [details.tag1, details.tag2, details.tag3, details.tag4])
        .filter(tag => tag)
    )
  ];

  const uniqueCategories = [...new Set(Object.values(inventoryDetails).map(details => details.category))];

  // Filter products based on selected tags and categories
  const filteredData = data.filter(product => {
    const details = inventoryDetails[product.id];
    if (!details) return false;

    const tagMatch = selectedTags.length ? selectedTags.some(tag => [details.tag1, details.tag2, details.tag3, details.tag4].includes(tag)) : true;
    const categoryMatch = selectedCategories.length ? selectedCategories.includes(details.category) : true;

    return tagMatch && categoryMatch;
  });

  const currentProducts = filteredData.slice(indexOfFirstProduct, indexOfLastProduct);

  // Change page
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Handle tag change
  const handleTagChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedTags(typeof value === 'string' ? value.split(',') : value);
  };

  // Handle category change
  const handleCategoryChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedCategories(typeof value === 'string' ? value.split(',') : value);
  };

  return (
    <>
      <FilterToolbar
        uniqueTags={uniqueTags}
        uniqueCategories={uniqueCategories}
        selectedTags={selectedTags}
        selectedCategories={selectedCategories}
        handleTagChange={handleTagChange}
        handleCategoryChange={handleCategoryChange}
      />

      <TableContainer
        component={Paper}
        elevation={0}
        sx={{
          border: '0.5px solid rgba(181, 181, 181, 1)',
          background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #B5B5B5, #B5B5B5)',
          boxShadow: 'none',
          ml: '1rem',
          width: '95%',
          borderRadius: '8px',
          mr: '1rem'
        }}
      >
        <TableListHeader />
        {currentProducts.map((product) => (
          <TableListItem
            key={product.id}
            product={product}
            data={data}
            setOpenDialog={setOpenDialog}
            searchQuery={searchQuery}
            inventoryDetails={inventoryDetails[product.id]}
            refreshInventoryDetails={fetchInventoryDetails} // Pass the refresh function as a prop
          />
        ))}
        <CustomPagination count={totalPages} page={currentPage} onChange={handlePageChange} productsPerPage={productsPerPage} setProductsPerPage={setProductsPerPage} />
      </TableContainer>
    </>
  );
};

export default InventoryTableView;




// import React, { useState } from 'react';
// import { Paper, TableContainer } from '@mui/material';
// import TableListHeader from './TableListHeader';
// import TableListItem from './TableListItem';
// // import MachineData from '../../../utilities/dummy_data/machine_list';
// import CustomPagination from '../../Pagination';

// const InventoryTableView = ({data, setOpenDialog, searchQuery}) => {

//   const MachineData = data;

//   console.log('MachineData 123', data);

//   const [currentPage, setCurrentPage] = useState(1);
//   const productsPerPage = 6;

  

//   // Calculate total number of pages
//   const totalItems = MachineData.length;
//   const totalPages = Math.ceil(totalItems / productsPerPage);

//   // Get current products
//   const indexOfLastProduct = currentPage * productsPerPage;
//   const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
//   const currentProducts = MachineData.slice(indexOfFirstProduct, indexOfLastProduct);

//   // Change page
//   const handlePageChange = (event, value) => {
//     setCurrentPage(value);
//   };

//   return (
//     <TableContainer
//       component={Paper}
//       elevation={0}
//       sx={{
//         border: '0.5px solid rgba(181, 181, 181, 1)',
//         background: 'linear-gradient(0deg, #FFFFFF, #FFFFFF), linear-gradient(0deg, #B5B5B5, #B5B5B5)',
//         boxShadow: 'none',
//         ml: '1rem',
//         width: '95%',
//         borderRadius: '8px',
//         mr: '1rem'
//       }}
//     >
//       <TableListHeader/>
//       {currentProducts.map((product) => (
//         <TableListItem key={product.id} product={product} data={data} setOpenDialog={setOpenDialog} searchQuery={searchQuery}/>
//       ))}
//       <CustomPagination count={totalPages} page={currentPage} onChange={handlePageChange} />
//     </TableContainer>
//   );
// };

// export default InventoryTableView;














// import React from "react"
// import { Box } from "@mui/material"
// import EditIcon from '@mui/icons-material/Edit';
// import DeleteIcon from '@mui/icons-material/Delete';
// import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
// import IconButton from '@mui/material/IconButton';
// import {Typography, Button} from "@mui/material";
// import { useState } from "react";
// import { DataGrid } from '@mui/x-data-grid';




// const InventoryTableView = ({data, setOpenDialog}) => {
//     const [page, setPage] = useState(0);
//     console.log('data', data);
//     const rows = data.map((item, index) => ({ ...item, id: index }));
//     console.log('56', rows);
//     //const rows = 0;

//     const handlePageChange = (newPage) => {
//         setPage(newPage);
//     };
//     const columns = [
//       { 
//         field: 'product_name', 
//         headerName: 'Item Details', 
//         width: 275,
//         padding:'5px',
//         renderCell: (params) => (
//           <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
//             <img src='./machine.png' alt=''style={{ width: '50px', height: '50px', borderRadius: '50%' }} />
//             <Box>
//               <Typography variant="subtitle2">{params.row.product_name}</Typography>
//             </Box>
//           </Box>
//         ),
//       },
//       { field: 'stock_available', headerName: 'stock', width: 275,padding:'5px', },
//       { field: 'tag', headerName: 'tag', width: 275, padding:'5px', },
//       {
//         field: 'action',
//         headerName: 'Action',
//         sortable: false,
//         padding:'5px',
//         renderCell: (params) => {
//           return (
//             <React.Fragment>
//               {/* <IconButton aria-label="edit">
//                 <EditIcon />
//               </IconButton>
//               <IconButton aria-label="delete">
//                 <DeleteIcon />
//               </IconButton>
//               <IconButton aria-label="move up">
//                 <ArrowUpwardIcon />
//               </IconButton> */}
//               <Button onClick={()=>setOpenDialog(true)}>
//                 Book
//               </Button>
//             </React.Fragment>
//           );
//         },
//         width: 150,
//       },
//     ];
//     return(
//         <Box sx={{margin:'2%'}}>
//             <DataGrid
//                 rows={rows}
//                 columns={columns}
//                 pageSize={7}
//                 page={page}
//                 onPageChange={handlePageChange}
//                 rowsPerPageOptions={[3]}
//                 pagination
//                 paginationMode="server" // Use this if you're handling pagination server-side
//                 checkboxSelection
//                 disableSelectionOnClick
//             />
//         </Box>
//     )

// }
// export default InventoryTableView
import React, { useState, useEffect } from 'react';
import { Box, Paper, Typography, Avatar } from '@mui/material';
import ChatIcon from '@mui/icons-material/Chat';
import StudentGetUser from 'utilities/api/StudentAllUserAPIs';

const AdminCard = ({ name, designation, email }) => {
    return (
        <Paper
            elevation={3}
            style={{
                padding: '16px',
                margin:'10px',
                borderRadius: '20px',
                position: 'relative',
                width: '200px',
                height: '150px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                backgroundColor: '#f9fafe',
                border: '1px solid #e9e9e9',
                textAlign: 'left',
                marginRight: '16px', // Add margin between cards
            }}
        >
            <Box>
                <Typography variant="caption" color="textSecondary" style={{ color: '#212121', textTransform: 'capitalize', fontWeight: '500' }}>
                    {designation}
                </Typography>
                <Typography variant="h6" style={{ marginTop: '8px', color: '#212121', fontWeight: '500' }}>
                    {name}
                </Typography>
                <Typography variant="body2" color="textSecondary" style={{ marginTop: '28px', color: '#212121', textTransform: 'capitalize', fontWeight: '500' }}>
                    {email}
                </Typography>
            </Box>
            <Avatar
                style={{
                    backgroundColor: '#3395ff',
                    width: 40,
                    height: 40,
                    position: 'absolute',
                    bottom: 16,
                    right: 16,
                    zIndex: 2
                }}
            >
                <ChatIcon />
            </Avatar>
        </Paper>
    );
};

const AdminCardList = ({user}) => {
    const [admins, setAdmins] = useState([]);

    useEffect(() => {
        const fetchAdmins = async () => {
            try {
                const data = await StudentGetUser.AllUserListGet();
                setAdmins(data);
            } catch (error) {
                console.error('Error fetching admin data:', error);
            }
        };

        fetchAdmins();
    }, [user]);

    const filteredAdmins = admins.filter(admin => admin.TL_name === user.TL_name && admin.is_admin);

    return (
        <Box style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '24px', width: '100%', overflowX: 'auto' }}>
            <Box style={{ display: 'flex', flexDirection: 'row', width: 'max-content' }}>
                {filteredAdmins.map((admin, index) => (
                    <AdminCard
                        key={index}
                        name={admin.name || admin.username} // Use username if name is not available
                        designation={admin.department || 'N/A'} // Provide a default value if designation is not available
                        email={admin.email || 'N/A'} // Provide a default value if phone is not available
                    />
                ))}
            </Box>
        </Box>
    );
};

export default AdminCardList;


// import React from 'react';
// import { Box, Paper, Typography, Avatar } from '@mui/material';
// import ChatIcon from '@mui/icons-material/Chat';

// const AdminCard = ({ name, designation, phone }) => {
//     return (
//         <Paper 
//             elevation={3} 
//             style={{ 
//                 padding: '16px', 
//                 borderRadius: '20px', 
//                 position: 'relative', 
//                 width: '200px', 
//                 height: '150px',
//                 display: 'flex', 
//                 flexDirection: 'column', 
//                 justifyContent: 'space-between',
//                 backgroundColor: '#f9fafe',
//                 border: '1px solid #e9e9e9',
//                 textAlign: 'left'
//             }}
//         >
//             <Box>
//                 <Typography variant="caption" color="textSecondary" style={{ color: '#212121', textTransform: 'capitalize', fontWeight: '500' }}>
//                     {designation}
//                 </Typography>
//                 <Typography variant="h6" style={{ marginTop: '8px', color: '#212121', fontWeight: '500' }}>
//                     {name}
//                 </Typography>
//                 <Typography variant="body2" color="textSecondary" style={{ marginTop: '28px', color: '#212121', textTransform: 'capitalize', fontWeight: '500' }}>
//                     {phone}
//                 </Typography>
//             </Box>
//             <Avatar 
//                 style={{ 
//                     backgroundColor: '#3395ff', 
//                     width: 40, 
//                     height: 40, 
//                     position: 'absolute', 
//                     bottom: 16, 
//                     right: 16, 
//                     zIndex: 2 
//                 }}
//             >
//                 <ChatIcon />
//             </Avatar>
//         </Paper>
//     );
// };

// const AdminCardList = () => {
//     const admins = [
//         { name: 'Admin Name', designation: 'Designation', phone: 'Phone No.' },
//         { name: 'Admin Name', designation: 'Designation', phone: 'Phone No.' },
//         { name: 'Admin Name', designation: 'Designation', phone: 'Phone No.' },
//         { name: 'Admin Name', designation: 'Designation', phone: 'Phone No.' },
//         { name: 'Admin Name', designation: 'Designation', phone: 'Phone No.' }
//     ];

//     return (
//         <Box style={{ display: 'flex', justifyContent: 'space-between', marginTop: '24px', width: '100%' }}>
//             {admins.map((admin, index) => (
//                 <AdminCard 
//                     key={index} 
//                     name={admin.name} 
//                     designation={admin.designation} 
//                     phone={admin.phone} 
//                 />
//             ))}
//         </Box>
//     );
// };

// export default AdminCardList;

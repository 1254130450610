import React from "react";
import { Tabs, Tab, Box, TextField, useMediaQuery, useTheme, InputAdornment } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';

const CustomTab = ({ icon }) => (
  <Box sx={{ margin: '5px 0px' }}>
    {icon}
  </Box>
);

const TopBarComponent = ({ activeCategory, setActiveCategory, setSearch, activeView, setActiveView }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (event, newValue) => {
    setActiveCategory(newValue);
  };

  const handleViewChange = (event, newValue) => {
    setActiveView(newValue);
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: isMobile ? 1 : 0,
      width: '100%',
      ...(activeCategory === 2 && { width:'97%' }), // Conditional for apparant right margin for Inventory tab
      padding: theme.spacing(1),
      marginBlock: '1rem',
      boxSizing: 'border-box', // Ensure padding does not cause overflow
    }}>
      <Tabs
        value={activeCategory}
        onChange={handleChange}
        indicatorColor="none"
        // textColor='primary'
        textColor="rgb(39,63,119)"
        sx={{
          padding: '5px',
          marginBottom: isMobile ? 1 : 0,
          // marginRight: '17.5%',
          height: 'auto',
          background: 'rgb(242,243,252)',
          border: '2px solid rgba(39,63,119,0.6)', // rgb(39,63,119) border around the entire tab bar
          borderRadius: '12px',
        }}
      >
        <Tab 
          label="Machines" 
          sx={{ 
            padding: '8px 32px',
            height: '44px',
            typography: 'body1',
            fontWeight: '600',
            border: '2px solid rgba(39,63,119,0.6)', // rgb(39,63,119) border around each tab
            borderRadius: '8px',
            '&.Mui-selected': {
              background: 'rgb(39,63,119)',
              color: 'white',
            },
          }} 
        />
        <Tab 
          label="Equipment" 
          sx={{ 
            marginInline: '0.5rem',
            padding: '8px 32px',
            height: '44px',
            typography: 'body1',
            fontWeight: '600',
            border: '2px solid rgba(39,63,119,0.6)', // rgb(39,63,119) border around each tab
            borderRadius: '8px',
            '&.Mui-selected': {
              background: 'rgb(39,63,119)',
              color: 'white',
            },
          }} 
        />
        <Tab 
          label="Inventory" 
          sx={{ 
            padding: '8px 32px',
            height: '44px',
            typography: 'body1',
            fontWeight: '600',
            border: '2px solid rgba(39,63,119,0.6)', // rgb(39,63,119) border around each tab
            borderRadius: '8px',
            '&.Mui-selected': {
              background: 'rgb(39,63,119)',
              color: 'white',
            },
          }} 
        />
      </Tabs>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          fullWidth
          className='searchBar'
          placeholder="Search Inventory.."
          id="outlined-start-adornment"
          sx={{ 
            // m: 1, 
            // ...(activeCategory === 2 && { marginRight:'50%' }), // Conditional right margin for Inventory tab
            width: '25ch', 
            border: '1px solid rgb(39,63,119)', 
            color:'rgb(39,63,119)', 
            borderRadius:'10px'
          }}
          onChange={(e) => setSearch(e.target.value)}
          InputProps={{
            startAdornment: <InputAdornment position="start" sx={{ border: 'none' }}><SearchIcon /></InputAdornment>,
          }}
        />
        {activeCategory !== 2 && (
          <Tabs
            indicatorColor="none"
            orientation="horizontal"
            value={activeView}
            onChange={handleViewChange}
            sx={{
              '& .MuiTab-root': {
                padding:'0px',
                marginInline:'2px',
                alignItems: 'flex-start',
                justifyContent: 'flex-start',
                textTransform: 'none',
                minWidth: '0px',
                fontSize: '2rem',
              },
              '& .MuiTabs-flexContainer': {
                flexDirection: 'row',
              },
            }}
          >
            <Tab
              label={
                <CustomTab
                  icon={
                    <GridViewOutlinedIcon
                      sx={{
                        marginLeft:'20px',
                        color: activeView === 0 ? 'white' : 'rgb(39,63,119)',
                        background: activeView === 0 ? 'rgb(39,63,119)' : 'rgba(122,122,122,0.4)',
                        padding: '3px',
                        borderRadius: '8px',
                      }}
                    />
                  }
                />
              }
              value={0}
            />
            <Tab
              label={
                <CustomTab
                  icon={
                    <ViewListOutlinedIcon
                      sx={{
                        color: activeView === 0 ? 'rgb(39,63,119)' : 'white',
                        background: activeView === 0 ? 'rgba(122,122,122,0.4)' : 'rgb(39,63,119)',
                        padding: '3px',
                        borderRadius: '8px',
                      }}
                    />
                  }
                />
              }
              value={1}
            />
          </Tabs>
        
          // <Tabs
          //   indicatorColor="none"
          //   orientation="horizontal"
          //   value={activeView}
          //   onChange={handleViewChange}
          //   sx={{
          //     '& .MuiTab-root': {
          //       alignItems: 'flex-start',
          //       justifyContent: 'flex-start',
          //       textTransform: 'none',
          //       minWidth: '0px',
          //       fontSize: '2rem',
          //     },
          //     '& .MuiTabs-flexContainer': {
          //       flexDirection: 'row',
          //     },
          //   }}
          // >
          //   <Tab label={<CustomTab icon={<GridViewOutlinedIcon sx ={{color:'white', background:'rgb(39,63,119)', padding:'3px', borderRadius:'8px'}}/>} />} />
          //   <Tab label={<CustomTab icon={<ViewListOutlinedIcon sx ={{color:'white', background:'rgb(39,63,119)', padding:'3px', borderRadius:'8px'}} />} />} />
          // </Tabs>
        )}
      </Box>
    </Box>
  );
};

export default TopBarComponent;















// import React from "react";
// import { useState } from "react";
// import { Tabs, Tab } from "@mui/material";
// import {Box, Typography} from "@mui/material";
// import {TextField, useMediaQuery, useTheme, InputAdornment} from "@mui/material";
// import SearchIcon from '@mui/icons-material/Search';
// import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
// import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';

// const TopBarComponent = ({activeCategory, setActiveCategory, setSearch, activeView, setActiveView}) => {

//     const theme = useTheme();
//     const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

//     const handleChange = (event, newValue) => {
//         setActiveCategory(newValue);
//     }
//     const handleViewChange = (event, newValue) => {
//         //console.log('20', newValue);
//         setActiveView(newValue);
//     }
//     const CustomTab = ({icon}) => (
//         <Box sx={{margin:'5px 0px'}}>
//             {icon}
//         </Box>
//     );

//     return (
//     <Box sx={{ 
      
//         display: 'flex', 
//         flexDirection: isMobile ? 'column' : 'row', 
//         justifyContent: 'space-between', 
//         alignItems: 'center', 
//         gap: isMobile ? 1 : 0, 
//         width: '100%', 
//         padding: theme.spacing(1),
//         margin: 'none',
//         marginBlock:'1rem',
//         boxSizing: 'border-box', // Ensure padding does not cause overflow
//       }}> 
//       <Tabs value={activeCategory} onChange={handleChange} indicatorColor="none"  textColor='true' sx={{padding:'5px', width: 'auto', marginBottom: isMobile ? 1 : 0 , marginRight:'17.5%' , height: 'auto', background: '#F4F4F4', borderRadius: '12px', '& .Mui-selected': { background: '#E9E9E9', border: '1px solid #B5B5B5', borderRadius: '8px' } }}>
//           <Tab label="Machines" sx={{marginInline: '1rem' , padding: '8px 32px', width: 'auto', height: '44px', typography: 'body1', fontWeight:'600' }} />
//           <Tab label="Equipment" sx={{marginInline: '1rem' , padding: '8px 32px', width: 'auto', height: '44px', typography: 'body1', fontWeight:'600'  }} />
//           <Tab label="Inventory" sx={{marginInline: '1rem' , padding: '8px 32px', width: 'auto', height: '44px', typography: 'body1', fontWeight:'600'  }} />
//       </Tabs>
//         <Box sx={{display:'flex'}}>
//         <TextField
//           fullWidth
//           className='searchBar'
//           placeholder="Search Inventory.."
//           id="outlined-start-adornment"
//           sx={{ m: 1, width: '25ch'}}
//           onChange={(e) => setSearch(e.target.value)}
//           InputProps={{
//             startAdornment: <InputAdornment position="start" sx={{border:'none'}}><SearchIcon/></InputAdornment>,
//           }}
//         />
//         <Tabs
//         indicatorColor="none" 
//         orientation="horizontal"
//         value={activeView}
//         onChange={handleViewChange}
//         sx={{
//           '& .MuiTab-root': {
//             alignItems: 'flex-start',
//             justifyContent: 'flex-start',
//             textTransform: 'none',
//             minWidth:'0px',
//           },
//           '& .MuiTabs-flexContainer': {
//             flexDirection: 'row',
//           },
//         }}
//       >
//         <Tab label={<CustomTab icon={<GridViewOutlinedIcon />} />}/>
//         <Tab label={<CustomTab icon={<ViewListOutlinedIcon />} />} />
//       </Tabs>
//         </Box>
//         </Box>
//     )
// }
// export default TopBarComponent
import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogContent,
  Box,
  Typography,
  Tabs,
  Tab,
} from '@mui/material';
import { useSelector } from 'react-redux';
import IssueInventory from './IssueInventory';
import PurchaseInventory from './PurchaseInventory';
import InventoryDetailAPI from 'utilities/api/StudentInventoryDetail';
import ImageBaseURL from 'utilities/api/axios';

const dialogStyles = {
  '& .MuiDialog-container': { alignItems: 'flex-start' },
  '& .MuiPaper-root': {
    display: 'flex',
    flexDirection: 'row',
    borderRadius: '40px',
    border: '1px solid #A6A6A6',
    boxShadow: '10px 10px 24px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
  },
};

const imageBoxStyles = {
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: 'rgb(242,243,252)',
  borderRadius: '10px',
  border: '1px solid rgba(39,63,119,0.6)',
  padding: '24px',
  height: '100%',
};

const textStyles = {
  color: '#212121',
  fontFamily: 'Inter',
  fontSize: '12px',
  fontWeight: '400',
  lineHeight: '2',
};

const InventoryDialog = ({ open, onClose, machineId, allowedTabs, tagStyles }) => {
  const { access, user } = useSelector((state) => state.user);
  const [inventoryDetail, setInventoryDetail] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [filteredTabs, setFilteredTabs] = useState([]);

  useEffect(() => {
    if (open && machineId && access) {
      const fetchInventoryDetail = async () => {
        try {
          const detail = await InventoryDetailAPI.InventoryDetailGet(access, machineId);
          setInventoryDetail(detail);

          // Determine which tabs to show based on tags and allowedTabs
          const tags = [detail.tag1, detail.tag2, detail.tag3, detail.tag4];
          const validTabs = ['Free', 'Issue', 'Purchase'];
          const newFilteredTabs = allowedTabs.filter(tab => validTabs.includes(tab) && tags.includes(tab));
          setFilteredTabs(newFilteredTabs);

          // Set initial tab value to the first valid tab
          if (newFilteredTabs.length > 0) {
            setTabValue(0);
          }
        } catch (error) {
          console.error('Failed to fetch machine details:', error);
        }
      };
      fetchInventoryDetail();
    }
  }, [open, machineId, access, allowedTabs]);

  const handleChangeTab = (event, newValue) => setTabValue(newValue);

  // const baseURL = 'https://staging.misadmin.mapit.ai/';

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" sx={dialogStyles}>
      <Box sx={{ display: 'flex', flexDirection: 'column', padding: '24px', width: '40%', gap: '24px' }}>
        <Box sx={imageBoxStyles}>
          <Box component="img" 
            sx={{
              objectFit: 'fill',
              maxWidth: '250px',
              minHeight: '200px',
              border: '1.5px solid rgba(39,63,119,0.8)', 
              borderRadius: '10px'
            }}
            src={inventoryDetail?.image ? `${ImageBaseURL.defaults.baseURL}${inventoryDetail.image}` : "./machine.png"}
            alt={inventoryDetail?.name}
          />
          <Typography variant="h6" sx={{ marginBottom: '10px', color: 'rgb(92,109,170)', fontFamily: 'Inter', fontSize: '24px', fontWeight: '600', textAlign: 'center' }}>
            {inventoryDetail?.name}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'flex-start', flex: '1 0 0', flexDirection: 'column' }}>
            {['Category', 'Location', 'UPC', 'Manufacturer', 'Instances', 'Description', 'Availability'].map((key) => (
              <Typography key={key} variant="body1" sx={textStyles}>
                <span style={{ color: 'rgb(0,38,55)', fontWeight: '550' }}>{key}</span>: <span style={{ color: 'rgb(0,38,55)', fontWeight: '400' }}>{inventoryDetail?.[key.toLowerCase()] || 'N/A'}</span>
              </Typography>
            ))}
            <Typography variant="body1" sx={textStyles}>
              <span style={{ color: 'rgb(0,38,55)', fontWeight: '550' }}>Unit</span>: {inventoryDetail?.stock_unit}
            </Typography>
            <Box sx={{ display: 'flex', gap: '8px', marginTop: '12px' }}>
              {(filteredTabs.includes('Issue') || filteredTabs.includes('Purchase')) && (
                <Typography sx={{ color: 'rgb(0,38,55)', fontWeight: '300' }}>
                  Tags:
                </Typography>
              )}
              {filteredTabs.map((tabLabel, index) => (
                (tabLabel === 'Issue' || tabLabel === 'Purchase') && (
                  <Typography key={index} variant="body1" sx={tagStyles[tabLabel]}>
                    {tabLabel}
                  </Typography>
                )
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
      <DialogContent sx={{ display: 'flex', flexDirection: 'column', width: '66.6667%' }}>
        {filteredTabs.length > 0 && (
          <Tabs
            value={tabValue}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            variant="fullWidth"
            sx={{
              '& .MuiTab-root': {
                color: '#0000a9',
                borderRadius: '10px',
                flex: 1,
                marginRight: '8px',
                fontWeight: 'bold',
                fontSize: '1rem',
                '&:last-child': { marginRight: 0 },
                '&.Mui-selected': {
                  border: '2px solid',
                  color: '#0000a9',
                  borderColor: '#0000a9',
                  background: 'rgb(242,243,252)',
                },
                '&.MuiTab-textColorInherit': {
                  color: '#0000a9',
                },
              },
              '& .MuiTabs-indicator': {
                display: 'none',
              },
              '& .MuiTab-label': {
                padding: 0,
              },
            }}
          >
            {filteredTabs.map((tabLabel, index) => (
              <Tab key={index} label={tabLabel} />
            ))}
          </Tabs>
        )}
        {filteredTabs.map((tabLabel, index) => (
          tabValue === index && (
            <ContentForTab key={index} label={tabLabel} user={user} machineId={machineId} onClose={onClose} />
          )
        ))}
      </DialogContent>
    </Dialog>
  );
};

const ContentForTab = ({ label, user, machineId, onClose }) => {
  const { access } = useSelector((state) => state.user);

  switch (label) {
    case 'Issue':
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', padding: '24px 0px 0px 24px', width: '85%', gap: '16px' }}>
          <IssueInventory user={user} access={access} machineId={machineId} onClose={onClose} />
        </Box>
      );
    case 'Purchase':
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', padding: '24px 0px 0px 24px', width: '85%', gap: '16px' }}>
          <PurchaseInventory user={user} access={access} machineId={machineId} onClose={onClose} />
        </Box>
      );
    case 'Free':
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', padding: '24px 0px 0px 24px', width: '85%', gap: '16px' }}>
          {/* Render FreeInventory component or content here if it exists */}
        </Box>
      );
    default:
      return null;
  }
};

export default InventoryDialog;


// OLDER VERSION

// import React, { useState, useEffect } from 'react';
// import {
//   Dialog,
//   DialogContent,
//   Box,
//   Typography,
//   Tabs,
//   Tab,
// } from '@mui/material';
// import { useSelector } from 'react-redux';
// import IssueInventory from './IssueInventory';
// import PurchaseInventory from './PurchaseInventory';
// import InventoryDetailAPI from 'utilities/api/StudentInventoryDetail';

// const dialogStyles = {
//   '& .MuiDialog-container': { alignItems: 'flex-start' },
//   '& .MuiPaper-root': {
//     display: 'flex',
//     flexDirection: 'row',
//     borderRadius: '40px',
//     border: '1px solid #A6A6A6',
//     // background: '#F2F3FC',
//     boxShadow: '10px 10px 24px rgba(0, 0, 0, 0.1)',
//     overflow: 'hidden',
//   },
// };

// const imageBoxStyles = {
//   display: 'flex',
//   flexDirection: 'column',
//   backgroundColor: 'rgb(242,243,252)',
//   borderRadius: '10px',
//   border: '1px solid rgba(39,63,119,0.6)',
//   padding: '24px',
//   height: '100%',
// };

// const textStyles = {
//   color: '#212121',
//   fontFamily: 'Inter',
//   fontSize: '12px',
//   fontWeight: '400',
//   lineHeight: '2',
// };

// const InventoryDialog = ({ open, onClose, machineId, allowedTabs, tagStyles }) => {
//   const { access, user } = useSelector((state) => state.user);
//   const [inventoryDetail, setInventoryDetail] = useState(null);
//   const [tabValue, setTabValue] = useState(0);

//   useEffect(() => {
//     if (open && machineId && access) {
//       const fetchInventoryDetail = async () => {
//         try {
//           const detail = await InventoryDetailAPI.InventoryDetailGet(access, machineId);
//           setInventoryDetail(detail);
//         } catch (error) {
//           console.error('Failed to fetch machine details:', error);
//         }
//       };
//       fetchInventoryDetail();
//     }
//   }, [open, machineId, access]);

//   const handleChangeTab = (event, newValue) => setTabValue(newValue);

//   return (
//     <Dialog open={open} onClose={onClose} fullWidth maxWidth="md" sx={dialogStyles}>
//       <Box sx={{ display: 'flex', flexDirection: 'column', padding: '24px', width: '40%', gap: '24px' }}>
//         <Box sx={imageBoxStyles}>
//           <Box component="img" sx={{ width: '100%', height: 'auto', objectFit: 'cover', border: '1.5px solid rgba(39,63,119,0.8)', borderRadius: '10px' }} src="popup.png" alt="Laser Cutter" />
//           <Typography variant="h6" sx={{ marginBottom: '10px', color: 'rgb(92,109,170)', fontFamily: 'Inter', fontSize: '24px', fontWeight: '600', textAlign: 'center' }}>
//             {inventoryDetail?.name}
//           </Typography>
//           <Box sx={{ display: 'flex', alignItems: 'flex-start', flex: '1 0 0', flexDirection: 'column' }}>
//             {['Category', 'Location', 'UPC', 'Manufacturer', 'Instances', 'Description', 'Availability'].map((key) => (
//               <Typography key={key} variant="body1" sx={textStyles}>
//                 <span style={{ color: 'rgb(0,38,55)', fontWeight: '550' }}>{key}</span>: <span style={{ color: 'rgb(0,38,55)', fontWeight: '400' }}>{inventoryDetail?.[key.toLowerCase()] || 'N/A'}</span>
//               </Typography>
//             ))}
//             <Typography variant="body1" sx={textStyles}>
//               <span style={{ color: 'rgb(0,38,55)', fontWeight: '550' }}>Unit</span>: {inventoryDetail?.stock_unit}
//             </Typography>
//             <Box sx={{ display: 'flex', gap: '8px', marginTop: '12px' }}>
//               <Typography sx={{ color: 'rgb(0,38,55)', fontWeight: '300' }}>
//                 Tags:
//               </Typography>
//               {allowedTabs.map((tabLabel, index) => (
//                 <Typography key={index} variant="body1" sx={tagStyles[tabLabel]}>
//                   {tabLabel}
//                 </Typography>
//               ))}
//             </Box>
//           </Box>
//         </Box>
//       </Box>
//       <DialogContent sx={{ display: 'flex', flexDirection: 'column', width: '66.6667%' }}>
//         <Tabs
//           value={tabValue}
//           onChange={handleChangeTab}
//           indicatorColor="primary"
//           textColor="primary"
//           variant="fullWidth"
//           sx={{
//             '& .MuiTab-root': {
//               color: '#0000a9',
//               borderRadius: '10px',
//               flex: 1,
//               marginRight: '8px',
//               fontWeight: 'bold',
//               fontSize: '1rem',
//               '&:last-child': { marginRight: 0 },
//               '&.Mui-selected': {
//                 border: '2px solid',
//                 color: '#0000a9',
//                 borderColor: '#0000a9',
//                 background: 'rgb(242,243,252)',
//               },
//               '&.MuiTab-textColorInherit': {
//                 color: '#0000a9',
//               },
//             },
//             '& .MuiTabs-indicator': {
//               display: 'none',
//             },
//             '& .MuiTab-label': {
//               padding: 0,
//             },
//           }}
//         >
//           {allowedTabs.map((tabLabel, index) => (
//             <Tab key={index} label={tabLabel} />
//           ))}
//         </Tabs>
//         {allowedTabs.map((tabLabel, index) => (
//           tabValue === index && (
//             <ContentForTab key={index} label={tabLabel} user={user} machineId={machineId} onClose={onClose} />
//           )
//         ))}
//       </DialogContent>
//     </Dialog>
//   );
// };

// const ContentForTab = ({ label, user, machineId, onClose }) => {
//   const { access } = useSelector((state) => state.user);

//   switch (label) {
//     case 'Issue':
//       return (
//         <Box sx={{ display: 'flex', flexDirection: 'column', padding: '24px 0px 0px 24px', width: '85%', gap: '16px' }}>
//           <IssueInventory user={user} access={access} machineId={machineId} onClose={onClose} />
//         </Box>
//       );
//     case 'Purchase':
//       return (
//         <Box sx={{ display: 'flex', flexDirection: 'column', padding: '24px 0px 0px 24px', width: '85%', gap: '16px' }}>
//           <PurchaseInventory user={user} access={access} machineId={machineId} onClose={onClose} />
//         </Box>
//       );
//     default:
//       return null;
//   }
// };

// export default InventoryDialog;


// workshopSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isDetailedWorkshop: false,
    workshopData: [],
    workshopCategory: '',
};

const workshopSlice = createSlice({
    name: "workshop",
    initialState,
    reducers: {
        toggleDetails: (state) => {
            state.isDetailedWorkshop = !state.isDetailedWorkshop;
            console.log("toggled isDetailedWorkshop");
        },
        setWorkshopData: (state, action) => {            
            state.workshopData = action.payload;
            console.log('setWorkshopData', state.workshopData);
        },
        setWorkshopCategory: (state, action) => {            
            state.workshopCategory = action.payload;
            console.log('workshopCategory', state.workshopCategory);
        }
    },
});

export const { toggleDetails, setWorkshopData, setWorkshopCategory } = workshopSlice.actions;

export default workshopSlice;

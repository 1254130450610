import React, { useEffect, useMemo, useState } from "react";
import { Grid, Box, Typography } from "@mui/material";
import TimeAxis from "./TimeAxis";
import BookingCard from "./cardCalender";
import DatePickerDialog from "./popups/datePicker";
import {
    getDate,
    getYear,
    getMonth,
    format,
    getMinutes
} from "date-fns";

const times = [
    "00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", 
    "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"
];

function DaysData({ selectedDate, reservationList, handleMachineSelect, handleProjectSelect }) {
    const [selected, setSelectedDate] = useState(selectedDate);
    const dayDate = getDate(selected);
    const dayYear = getYear(selected);
    const dayMonth = getMonth(selected);

    const machineNames = useMemo(() => {
        let names = [];
        reservationList.forEach((data) => {
            const dataDate = getDate(new Date(data.start_time));
            const dataMonth = getMonth(new Date(data.start_time));
            const dataYear = getYear(new Date(data.start_time));
            if (dayDate === dataDate && dayMonth === dataMonth && dayYear === dataYear) {
                names.push(data.name);
            }
        });
        return [...new Set(names)];
    }, [selected, reservationList]);

    useEffect(() => {
        handleMachineSelect(machineNames);
    }, [machineNames]);

    const projectNames = useMemo(() => {
        let projectName = [];
        reservationList.forEach((data) => {
            const dataDate = getDate(new Date(data.start_time));
            const dataMonth = getMonth(new Date(data.start_time));
            const dataYear = getYear(new Date(data.start_time));
            if (dayDate === dataDate && dayMonth === dataMonth && dayYear === dataYear) {
                projectName.push(data.project_name);
            }
        });
        return [...new Set(projectName)];
    }, [selected, reservationList]);

    useEffect(() => {
        handleProjectSelect(projectNames);
    }, [projectNames]);

    const handleDateChange = (newDate) => {
        // Update the full date, including the month and year
        setSelectedDate(newDate);
    };

    return (
        <Grid container>
            <Grid item>
                <TimeAxis />
            </Grid>
            <Grid item xs>
                <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <Box
                        key={selected.toISOString()}
                        sx={{ border: "1px solid #D3D3D3", flexGrow: 1 }}
                    >
                        <Box
                            sx={{ height: "3.8125rem", background: "#E9E9E9" }}
                        >
                            <Typography
                                variant="subtitle2"
                                sx={{ fontFamily: "Segoe UI", fontSize: "2rem", fontWeight: 700, padding: "4px 8px", color: "black" }}
                            >
                                {format(selected, 'LLLL') + " " + dayDate + ", " + dayYear}
                            </Typography>
                            <Box sx={{ display: "flex" }}>
                                <Typography
                                    variant="subtitle2"
                                    sx={{ fontFamily: "Segoe UI", fontSize: "0.85rem", fontWeight: 600, padding: "3px 8px", color: "black" }}
                                >
                                    {format(selected, "EEEE")}
                                </Typography>
                            </Box>
                        </Box>
                        {times.map((time, index) => (
                            <Box
                                key={index}
                                sx={{ height: "5.5rem", padding: "0.5rem", borderTop: "1px solid #D3D3D3", flexGrow: 1 }}
                            >
                                {reservationList.map((data, idx) => {
                                    const dataDate = getDate(new Date(data.start_time));
                                    const dataMonth = getMonth(new Date(data.start_time));
                                    const dataYear = getYear(new Date(data.start_time));
                                    const dataTime = format(new Date(data.start_time), 'HH');
                                    if (dayDate === dataDate && dayMonth === dataMonth && dayYear === dataYear && dataTime === time) {
                                        return (
                                            <BookingCard
                                                key={data.id}
                                                bookingName={data.name}
                                                timeDuration={((new Date(data.end_time) - new Date(data.start_time)) / (60000 * 60)).toFixed(2)}
                                                id={data.id}
                                                reservationList={reservationList.filter(r => getDate(new Date(r.start_time)) === dayDate && getMonth(new Date(r.start_time)) === dayMonth && getYear(new Date(r.start_time)) === dayYear && format(new Date(r.start_time), 'HH') === time)}
                                                marginStart={getMinutes(new Date(data.start_time)) / 10}
                                            />
                                        );
                                    }
                                    return null;
                                })}
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Grid>
            <Grid item>
                <DatePickerDialog 
                    open={true}
                    selectedDate={selected}
                    handleDateChange={handleDateChange}
                />
            </Grid>
        </Grid>
    );
}

export default DaysData;
